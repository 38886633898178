import React from 'react';

import { montaURL } from '../../utils/tools';

function RelatoriosComponent() {
	return (
		<div>
			<div className="titulo-pagina">
				<h1>Relatórios</h1>
			</div>
			<ul className="list">
				<li
					className={'list-items even'}
					onClick={() => window.open(`${montaURL('e-cmdua-api', true)}/api/relatorios/chapas`)}
				>
					Relação de Chapas inscritas
				</li>
				<li
					className={'list-items odd'}
					onClick={() => window.open(`${montaURL('e-cmdua-api', true)}/api/relatorios/chapas?situacao=confirmada`)}
				>
					Relação de Chapas confirmadas
				</li>
				<li
					className={'list-items even'}
					onClick={() => window.open(`${montaURL('e-cmdua-api', true)}/api/relatorios/chapas?situacao=impugnada`)}
				>
					Relação de Chapas impugnadas
				</li>
				<li
					className={'list-items odd'}
					onClick={() => window.open(`${montaURL('e-cmdua-api', true)}/api/relatorios/delegados`)}
				>
					Relação de Delegados inscritos
				</li>
				<li
					className={'list-items even'}
					onClick={() => window.open(`${montaURL('e-cmdua-api', true)}/api/relatorios/entidades`)}
				>
					Relação de Entidades inscritas
				</li>
			</ul>
		</div>
	);
}
RelatoriosComponent.displayName = 'RelatoriosComponent';
RelatoriosComponent.propTypes = {};

export default RelatoriosComponent;
