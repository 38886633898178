import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';

import { GRAPHQL_URL } from '../environments';
import { ROTAS_PUBLICAS, keycloakObject } from '../index';
import { isDebug } from './tools';

const debug = isDebug;

// eslint-disable-next-line no-unused-vars
const DEFAULT_APOLLO_CLIENT_OPTIONS = {
	watchQuery: {
		// fetchPolicy: 'cache-and-network'
		errorPolicy: 'all'
	},
	query: {
		fetchPolicy: 'network-only',
		errorPolicy: 'all'
	},
	mutate: {
		errorPolicy: 'all'
	}
};

const customFetch = (uri, options) => {
	if (debug) {
		console.debug('GRAPHQL', '\n^^^^^^^\ncustomFetch', uri, options);
	}
	if (options.body) {
		const body = JSON.parse(options.body);
		if (body.variables) {
			body.variables = stripTypenames(body.variables, ['__typename', '__proto__']);
		}
		options.body = JSON.stringify(body);
		if (debug) {
			console.debug(
				'GRAPHQL',
				`${body.operationName === 'mutation' ? 'MUTATION' : 'QUERY'} GRAPHQL:`,
				body.operationName,
				'\n--------------------------------\nVARIÁVEIS:',
				body.variables,
				'\n--------------------------------\nQUERY:',
				body.query.replace(/(.*{.*\(.*\) {)(.*)(}.*})/gms, '$1...$3'),
				'\n--------------------------------'
			);
		}
	}
	if (isDebug) {
		console.debug('GRAPHQL', 'keycloakObject.token: ', keycloakObject.token ? 'tem token' : 'não tem token');
	}

	const PUBLIC_PATH = ROTAS_PUBLICAS.reduce((acc, url) => acc || window.location.pathname === url, false);
	console.debug('GRAPHQL', PUBLIC_PATH ? 'PUBLIC_PATH: ' : 'PRIVATE_PATH', window.location.pathname);

	if (PUBLIC_PATH) {
		return new Promise((resolve, reject) => {
			try {
				const optionsWithoutToken = {
					...options,
					method: 'post',
					headers: {
						...options.headers
					}
				};
				fetch(GRAPHQL_URL, optionsWithoutToken)
					.then(response => {
						resolve(response);
					})
					.catch(error => {
						debug && console.debug('GRAPHQL', 'ERRO NA QUERY GRAPHQL: ', error);
						reject(error);
					});
			} catch (error) {
				debug && console.debug('GRAPHQL', 'ERRO NA QUERY GRAPHQL: ', error);
				reject(error);
			}
		});
	} else {
		return keycloakObject
			? new Promise((resolve, reject) => {
					keycloakObject
						.updateToken(5)
						.then(async () => {
							const optionsWithToken = {
								...options,
								method: 'post',
								headers: {
									...options.headers,
									Authorization: `Bearer ${keycloakObject.token}`
								}
							};
							let result = null;
							try {
								result = await fetch(GRAPHQL_URL, optionsWithToken);
								resolve(result);
							} catch (error) {
								debug && console.debug('GRAPHQL', 'ERRO NA QUERY GRAPHQL: ', error);
								reject(error);
							}
						})
						.catch(function() {
							alert('Falha ao atualizar token... Você está logado?');
						});
			  })
			: Promise.reject('Sem token ainda');
	}
};
/*
	Se for necessário, podemos colocar um parser para a identificação dos objetos em cache
	(pode-se ver isso com a extensão do Apollo no Chrome), como o exemplo abaixo:

	import md5 from 'md5';

	cache: new InMemoryCache({
		dataIdFromObject: object => {
			switch (object.__typename) {
				case 'ExpedientesProjetoEdificacaoBloco':
					return `${object.__typename}: ${md5(JSON.stringify(object))}`; // use `md5` as the primary key
				default:
					return `${object.__typename}: ${object.id || object._id}`; // fall back to `id` and `_id` for all other types
			}
		}
	})

*/
const client = new ApolloClient({
	link: new HttpLink({ fetch: customFetch }),
	cache: new InMemoryCache()
	// defaultOptions: DEFAULT_APOLLO_CLIENT_OPTIONS
});

export default client;

const stripTypenames = (obj, propsToDelete) => {
	for (const property in obj) {
		if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
			delete obj.property;
			const newData = stripTypenames(obj[property], propsToDelete);
			obj[property] = newData;
		} else {
			if (propsToDelete.indexOf(property) > -1) {
				delete obj[property];
			}
		}
	}
	return obj;
};
