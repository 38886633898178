import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { List } from 'immutable';
import { size, isArray, get } from 'lodash';
import mustache from 'mustache';
import uuid from 'uuid/v1';

import ErrorMessages from './ErrorMessages';
import ListItem from './ListItem';

const Selecao = ({
	className,
	readOnly,
	multiple,
	selected,
	label,
	detailInnerClassName,
	detailCodigo,
	detailDescricao,
	templateDescricao,
	maxDescricaoLength = 26,
	detailData,
	detailIcon,
	detailModifier,
	searchTerm,
	searchList,
	errorList,
	warning,
	required,
	searchTermRef,
	onUnselect,
	onChangeSearchTerm,
	onBlurSearchTerm,
	onSelectItem,
	searchTermMinLength = 2,
	autoShowList,
	noResetList = false,
	loading = false,
	placeholder,
	showHyphen = true,
	orderBy = null,
	...otherProps
}) => {
	const [showList, setShowList] = useState(false);
	const [timer, setTimer] = useState(null);

	const selectedJS = List.isList(selected) ? selected.toJS() : selected;

	const selectedList = selectedJS ? (isArray(selectedJS) ? selectedJS : [selectedJS]) : selectedJS;

	const hasSelected = size(selectedList) > 0;

	let searchSelected = -1;
	let prev;

	const handleKeyDown = event => {
		// let sl = Map.isMap(searchList) ? searchList.toJS() : searchList;
		let sl = searchList;

		if (!noResetList && !showList && event.key === 'ArrowDown') {
			//empty search
			setShowList(true);
			onChangeSearchTerm(event);
		}

		if (sl && sl.length > 0) {
			if (event.key === 'Enter') {
				searchSelected = searchSelected === -1 ? 0 : searchSelected;
				//select
				document.querySelectorAll('.results-item')[searchSelected].click();
			} else if (event.key === 'ArrowUp') {
				//focus previous
				prev = searchSelected;
				searchSelected = searchSelected === 0 ? sl.length - 1 : searchSelected - 1;
				fakeFocus(searchSelected, prev);
			} else if (event.key === 'ArrowDown') {
				//focus next
				prev = searchSelected;
				searchSelected = searchSelected === sl.length - 1 ? 0 : searchSelected + 1;
				fakeFocus(searchSelected, prev);
			}
		}

		if (otherProps.onKeyDown) {
			otherProps.onKeyDown(event);
		}
	};
	const fakeFocus = (searchSelected, prev) => {
		const list = document.querySelectorAll('.results-item');

		if (list && prev > -1 && list[prev]) {
			list[prev].classList.remove('fake-focus');
		}
		if (list && searchSelected > -1 && list[searchSelected]) {
			list[searchSelected].classList.add('fake-focus');
			list[searchSelected].scrollIntoView(false);
		}
	};

	const handleOnChange = event => {
		onChangeSearchTerm(event);
		const mostra = autoShowList || size(event.target.value) >= searchTermMinLength;
		setShowList(mostra);
	};

	const handleOnBlur = dispatcher => {
		if (timer) {
			clearTimeout(timer);
		}
		setTimer(
			setTimeout(() => {
				setShowList(false);
				dispatcher();
			}, 300)
		);
	};

	const handleOnFocus = event => {
		if (size(searchTerm) >= searchTermMinLength || autoShowList) {
			setShowList(true);
			onChangeSearchTerm(event);
		}
		if (!autoShowList) {
			onBlurSearchTerm();
		}
	};

	const handleArrowBtn = event => {
		event.target.parentNode.getElementsByTagName('input')[0].focus();
		setShowList(true);
		onChangeSearchTerm({ target: { value: '' } });
		clearTimeout(timer);
	};

	const propDescricao = useCallback(
		obj =>
			(typeof detailDescricao === 'string'
				? detailDescricao
				: Array.isArray(detailDescricao)
				? detailDescricao.reduce((acc, prop) => acc || (get(obj, prop) ? prop : null), null)
				: null) || 'descricao',
		[detailDescricao]
	);

	const descricao = useMemo(
		() =>
			templateDescricao
				? selected
					? htmlDecode(mustache.render(templateDescricao, selected))
					: null
				: get(selected, propDescricao(selected)),
		[propDescricao, selected, templateDescricao]
	);
	return (
		<>
			<div className="input-group-lic">
				{hasSelected && (
					<>
						{multiple ? (
							selectedList.map(selected => {
								const descSelected = get(selected, propDescricao(selected));
								const descricao =
									size(descSelected) > maxDescricaoLength
										? `${descSelected.substring(0, maxDescricaoLength - 3)}...`
										: descSelected;
								return (
									<div className={`title-with-button${readOnly ? ' read-only' : ''}`} key={selected?.id || uuid()}>
										<ListItem
											innerClassName={detailInnerClassName}
											codigo={get(selected, detailCodigo)}
											descricao={descricao}
											title={descSelected}
											data={detailData ? get(selected, detailData) : null}
											icon={detailIcon}
											modifier={detailModifier}
											showHyphen={showHyphen}
										/>
										{!readOnly && (
											<button
												type="button"
												onClick={onUnselect(selected)}
												aria-label={multiple ? 'excluir item' : 'modificar item'}
											>
												<i className={`fa fa-${multiple ? 'trash' : 'exchange'}`} aria-hidden="true" />
											</button>
										)}
									</div>
								);
							})
						) : (
							<div className="input-with-icon">
								<input
									type="text"
									readOnly={true}
									className={detailInnerClassName}
									required={required}
									title={descricao}
									value={
										size(descricao) > maxDescricaoLength
											? `${descricao.substring(0, maxDescricaoLength - 3)}...`
											: descricao || ''
									}
								/>
								{!readOnly && (
									<i
										aria-label={multiple ? 'excluir item' : 'modificar item'}
										onClick={onUnselect(selected)}
										className={`fa fa-${multiple ? 'trash' : 'exchange'}`}
										aria-hidden="true"
										role="button"
									/>
								)}{' '}
							</div>
						)}
						{!multiple && <ErrorMessages errorList={errorList} />}
					</>
				)}
				{(multiple || !hasSelected) && (
					<>
						<div className="floating-list-wrapper">
							<div className="input-with-icon">
								<input
									ref={searchTermRef}
									className={className}
									label={label}
									required={required}
									readOnly={!!readOnly}
									type="text"
									value={searchTerm || ''}
									onKeyDown={handleKeyDown}
									// onChange={onChangeSearchTerm}
									onChange={readOnly ? () => undefined : event => handleOnChange(event)}
									onBlur={readOnly ? () => undefined : () => handleOnBlur(onBlurSearchTerm)}
									onFocus={readOnly ? () => undefined : event => handleOnFocus(event)}
									// onFocus={onBlurSearchTerm}
									placeholder={placeholder}
								/>
								{!noResetList && !readOnly && !loading && (
									<i className="fa fa-angle-down" onClick={event => handleArrowBtn(event)} />
								)}
								{loading && <i className="fa fa-refresh fa-spin" />}
							</div>
							{!readOnly && showList && (
								<div className="input-floating-list">
									<p className="results-count">
										{size(searchList) === 0
											? loading
												? 'Pesquisando...'
												: 'Nenhum item encontrado'
											: size(searchList) === 1
											? 'Apenas um item na lista'
											: `Selecione um dos ${size(searchList)} itens`}
									</p>
									{size(searchList) > 0 && (
										<ul className="results-list">
											{(searchList && size(orderBy) > 0
												? searchList.sort((a, b) => {
														let ax = htmlDecode(mustache.render(templateDescricao, a));
														let bx = htmlDecode(mustache.render(templateDescricao, b));
														return orderBy === 'asc' ? ax.localeCompare(bx) : bx.localeCompare(ax);
												  })
												: searchList
											).map((item, index) => (
												<li key={index} className="results-item" onClick={onSelectItem(item)}>
													<ListItem
														innerClassName={detailInnerClassName}
														codigo={get(item, detailCodigo)}
														descricao={
															templateDescricao
																? htmlDecode(mustache.render(templateDescricao, item))
																: get(item, propDescricao(item))
														}
														data={detailData ? get(item, detailData) : null}
														icon={detailIcon}
														modifier={detailModifier}
														showHyphen={showHyphen}
													/>
												</li>
											))}
										</ul>
									)}
								</div>
							)}
						</div>
						<ErrorMessages errorList={errorList} />
						{warning && <ErrorMessages errorList={['Nenhum item foi selecionado']} warning />}
					</>
				)}
			</div>
		</>
	);
};
Selecao.displayName = 'Selecao';

Selecao.propTypes = {
	className: PropTypes.string,
	readOnly: PropTypes.bool,
	multiple: PropTypes.bool,
	selected: PropTypes.any,
	label: PropTypes.string,
	required: PropTypes.bool,
	detailInnerClassName: PropTypes.string,
	detailCodigo: PropTypes.string,
	detailDescricao: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	templateDescricao: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	maxDescricaoLength: PropTypes.number,
	detailData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	detailIcon: PropTypes.string,
	detailModifier: PropTypes.func,
	searchTerm: PropTypes.string,
	searchList: PropTypes.arrayOf(PropTypes.object),
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	warning: PropTypes.bool,
	searchTermRef: PropTypes.any,
	onUnselect: PropTypes.func,
	onChangeSearchTerm: PropTypes.func,
	onBlurSearchTerm: PropTypes.func,
	onSelectItem: PropTypes.func,
	searchTermMinLength: PropTypes.number,
	autoShowList: PropTypes.bool,
	noResetList: PropTypes.bool,
	loading: PropTypes.bool,
	placeholder: PropTypes.string,
	showHyphen: PropTypes.bool,
	orderBy: PropTypes.string
};

export default Selecao;

function htmlDecode(html) {
	var span = document.createElement('SPAN');
	span.innerHTML = html;
	return span.innerText;
}
