import React from 'react';

import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Keycloak from 'keycloak-js';

import AppRouter from './AppRouter';
import LayoutContextWrapper from './components/LayoutContext';
import { SSO_URL } from './environments';
import initializeStore from './Redux/store';
import * as serviceWorker from './serviceWorker';
import apolloClient from './utils/graphql';
import { configureLibraries, isDebug, isExternal } from './utils/tools';
import 'font-awesome/css/font-awesome.min.css';

import './style.scss';

export const ROTAS_PUBLICAS = ['/', '/publica'];

let layoutReference;
export const getLayoutInstance = () => layoutReference || {};

export const keycloakObject = new Keycloak({
	url: SSO_URL,
	realm: isExternal ? 'acessopoa' : 'pmpa',
	clientId: isExternal ? 'eleicoes-cmdua' : 'e-cmdua'
});

export const store = initializeStore({});
store.kc = keycloakObject;

const App = () => (
	<Provider store={store}>
		<LayoutContextWrapper ref={r => (layoutReference = r)}>
			<ApolloProvider client={apolloClient}>
				<AppRouter />
			</ApolloProvider>
		</LayoutContextWrapper>
	</Provider>
);
App.displayName = 'KeycloakInjection';

keycloakObject.init({ checkLoginIframe: false, promiseType: 'native' }).then(authenticated => {
	if (isDebug) {
		console.debug('START', 'authenticaded: ', authenticated);
		console.debug('START', 'token: ', keycloakObject.token ? 'tem token' : 'não tem token');
	}
	const PUBLIC_PATH = ROTAS_PUBLICAS.reduce((acc, url) => acc || window.location.pathname === url, false);
	if ((!authenticated || !keycloakObject.token) && !PUBLIC_PATH) {
		keycloakObject.login();
	} else {
		ReactDOM.render(<App />, document.getElementById('app'));
	}
});

serviceWorker.unregister();

// Hot Module Replacement
if (module.hot) {
	module.hot.accept();
}

configureLibraries();
