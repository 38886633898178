import { createSlice } from '@reduxjs/toolkit';

export const delegadosSlice = createSlice({
	name: 'delegados',
	initialState: {
		page: 0,
		count: null,
		searchTerm: ''
	},
	reducers: {
		setPage: (state, action) => {
			state.page = action.payload;
		},
		setCount: (state, action) => {
			state.count = action.payload;
		},
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
		}
	}
});

const { actions, reducer: delegadosReducer } = delegadosSlice;

export const { setPage, setCount, setSearchTerm } = actions;

export default delegadosReducer;
