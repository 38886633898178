import React from 'react';

import useUserProfile from '../customHooks/useUserProfile';
import { SERVER_URL } from '../environments';
import { keycloakObject } from '../index';
import { history } from '../Redux/store';
import { isExternal } from '../utils/tools';
import SideMenu from './SideMenu';

const Header = () => {
	/* CUSTOM HOOKS */
	const { userProfile, authenticated } = useUserProfile();

	return (
		<header className={`${isExternal ? '' : 'header-admin '}${SERVER_URL.indexOf('-hom.') > -1 ? 'is-hom' : ''}`}>
			<div className="container">
				<div className="logo">
					<a href="https://prefeitura.poa.br">
						<img
							alt="Prefeitura de Porto Alegre"
							className="img-responsive"
							src="https://prefeitura.poa.br/themes/pmpa/images/brasao.svg"
						/>
					</a>
				</div>

				<div className="titulo-sistema">Eleições CMDUA{!isExternal && <small> Admin</small>}</div>

				<div className="user">
					{authenticated && <div className="name">{userProfile?.name}</div>}
					<div className="user-menu">
						{isExternal && <SideMenu />}
						{authenticated && (
							<div className="logoff">
								<button
									type="button"
									className="btn-link"
									onClick={() => {
										history.push('/');
										keycloakObject.logout();
									}}
								>
									Sair <i className="fa fa-sign-out" aria-hidden="true"></i>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};

Header.displayName = 'Header';

export default Header;
