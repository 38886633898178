import React from 'react';

const ServerUnavailablePage = () => (
	<div className="error-screen">
		<h1 className="error-header">
			503 <span className="error-label">Servidor indisponível</span>
		</h1>
		<p>O servidor está momentaneamente indisponível.</p>
		<p>
			<a href="/">Tente novamente mais tarde.</a>
		</p>
	</div>
);
ServerUnavailablePage.displayName = 'ServerUnavailablePage';
export default ServerUnavailablePage;
