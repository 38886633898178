import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { size, trim, omit, cloneDeep } from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v4';

import ErrorMessages from '../../components/ErrorMessages';
import Loader from '../../components/Loader';
import ModalMessage from '../../components/ModalMessage';
import { ABA_CHAPAS } from '../../components/Navigation';
import Navigation from '../../components/Navigation';
import RadioField from '../../components/RadioField';
import Selecao from '../../components/Selecao';
import useCalendario from '../../customHooks/useCalendario';
import useMessages from '../../customHooks/useMessages';
import { limpaStore, removeChapa, saveChapa, setCurrent, setMessages } from '../../Redux/chapa/chapaSlice';
import { setAba } from '../../Redux/Home/homeSlice';
import { history } from '../../Redux/store';
import { getIdade, isDebug, isEmail, isExternal, permiteInput } from '../../utils/tools';

const chapaQuery = loader('./chapaQuery.gql');

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[CHAPA ${i++}]:`, ...args);

function Chapa() {
	/* REDUX */
	const dispatch = useDispatch();
	const chapa = useSelector(state => state.chapa.current);
	const updating = useSelector(state => state.chapa.updating);
	const messages = useSelector(state => state.chapa.messages);
	const validationMessages = useSelector(state => state.chapa.validationMessages);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */
	const { createMessage } = useMessages();
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const { id } = useParams();
	const [errors, setErrors] = useState({});
	const [showPopupRemove, setShowPopupRemove] = useState(false);
	const [showPopupMessage, setShowPopupMessage] = useState(false);
	const [regioes, setRegioes] = useState([]);
	const [regiaoSearchTerm, setRegiaoSearchTerm] = useState('');
	const [participantes, setParticipantes] = useState([]);
	const [titularSearchTerm, setTitularSearchTerm] = useState('');
	const [suplente1SearchTerm, setSuplente1SearchTerm] = useState('');
	const [suplente2SearchTerm, setSuplente2SearchTerm] = useState('');

	const { data: dataChapa, loading: loadingChapa, errors: errorChapa } = useQuery(chapaQuery, {
		variables: { id },
		fetchPolicy: 'network-only'
	});

	const { data: dataRegiao, loading: loadingRegiao, errors: errorRegiao } = useQuery(
		gql`
			query ListaRegioes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaRegiaoList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					numero
				}
				count: ECmduaRegiaoCount
			}
		`,
		{
			variables: {},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const {
		data: dataParticipantes,
		loading: loadingParticipantes,
		errors: errorParticipantes,
		refetch: refetchParticipantes
	} = useQuery(
		gql`
			query ListaParticipantes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaParticipanteList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					email
					dataNascimento
					regiao {
						id
						nome
					}
				}
			}
		`,
		{
			variables: {
				skip: 0,
				limit: 2000,
				term: isExternal ? 'NINGUÉM TEM' : undefined
			},
			fetchPolicy: 'network-only'
			// nextFetchPolicy: 'cache-first'
		}
	);

	const onNavigateHandler = useCallback(() => {
		dispatch(limpaStore());
	}, [dispatch]);

	useEffect(() => {
		if (size(messages) > 0) {
			debugLog('[messages]: ', messages);
			if (size(messages.messages) > 0) {
				setShowPopupMessage(messages);
			} else {
				for (const message of messages) {
					createMessage(message, 10);
				}
			}
			dispatch(setMessages(null));
		}
	}, [createMessage, dispatch, messages]);

	useEffect(() => {
		if (id === 'new' && !chapa) {
			debugLog('[chapa, id]: ', chapa, id);
			dispatch(setCurrent({ id: uuid() }));
		}
	}, [chapa, dispatch, id]);

	useEffect(() => {
		if (isExternal && userProfile) {
			debugLog('[userProfile]: ', userProfile);
			const variables = {
				skip: 0,
				limit: 2000,
				term: JSON.stringify({
					usuarioCriador: userProfile.email
				})
			};

			refetchParticipantes(variables);
		}
	}, [userProfile, refetchParticipantes]);

	useEffect(() => {
		if (dataChapa && id !== 'new') {
			debugLog('[dataChapa, id]: ', dataChapa, id);
			const chapaAux = cloneDeep(dataChapa.item);
			if (chapaAux.titular?.dataNascimento) {
				chapaAux.titular.idade = getIdade(chapaAux.titular?.dataNascimento);
			}
			if (chapaAux.suplente1?.dataNascimento) {
				chapaAux.suplente1.idade = getIdade(chapaAux.suplente1?.dataNascimento);
			}
			if (chapaAux.suplente2?.dataNascimento) {
				chapaAux.suplente2.idade = getIdade(chapaAux.suplente2?.dataNascimento);
			}
			dispatch(setCurrent(chapaAux));
		}
	}, [dispatch, id, dataChapa]);

	useEffect(() => {
		if (dataParticipantes) {
			debugLog('[dataParticipantes, participantes]: ', dataParticipantes.list, participantes);
			if (size(dataParticipantes.list) !== size(participantes)) {
				setParticipantes(
					dataParticipantes.list
						.sort((a, b) => (a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1))
						.map(p => ({ ...p, idade: getIdade(p.dataNascimento) }))
				);
			}
		}
	}, [dataParticipantes, participantes]);

	useEffect(() => {
		if (dataRegiao) {
			debugLog('[dataRegiao]: ', dataRegiao);
			setRegioes(dataRegiao.list);
		}
	}, [dataRegiao]);

	const changeHandler = useCallback(
		e => {
			const target = Array.isArray(e.target) ? e.target : [e.target];
			let errorsToRemove = [];
			let update = { ...chapa };
			target.forEach(t => {
				const { name, value } = t;
				let allow = true;
				if (name === 'email') {
					allow = permiteInput(value, 'email', 0, 100);
				}
				if (allow) {
					errorsToRemove.push(name);
					update = { ...update, [name]: value };
				}
			});
			setErrors(old => omit(old, errorsToRemove) || {});
			dispatch(setCurrent({ ...update }));
		},
		[dispatch, chapa]
	);

	const valida = useCallback(async () => {
		let errors = {};
		if (size(trim(chapa.regiao)) === 0) {
			errors.regiao = ['Selecione a região'];
		}
		if (size(trim(chapa.email)) === 0) {
			if (isExternal) {
				errors.email = ['Informe o e-mail da chapa'];
			}
		} else if (!isEmail(chapa.email)) {
			errors.email = ['E-mail inválido'];
		}

		if (size(chapa.titular) === 0) {
			errors.titular = ['Selecione o titular'];
		} else {
			let messages = [];
			if (chapa.regiao && chapa.titular.regiao.id !== chapa.regiao.id) {
				messages.push('O titular deve pertencer à mesma região da chapa');
			}
			if (chapa.titular.idade < 18) {
				messages.push('O titular deve ter pelo menos 18 anos');
			}
			if (size(messages) > 0) {
				errors.titular = messages;
			}
		}
		if (size(chapa.suplente1) === 0) {
			errors.suplente1 = ['Selecione o primeiro suplente'];
		} else {
			let messages = [];
			if (chapa.regiao && chapa.suplente1.regiao.id !== chapa.regiao.id) {
				messages.push('O primeiro suplente deve pertencer à mesma região da chapa');
			}
			if (chapa.suplente1.idade < 18) {
				messages.push('O primeiro suplente deve ter pelo menos 18 anos');
			}
			if (size(messages) > 0) {
				errors.suplente1 = messages;
			}
		}
		if (size(chapa.suplente2) === 0) {
			errors.suplente2 = ['Selecione o segundo suplente'];
		} else {
			let messages = [];
			if (chapa.regiao && chapa.suplente2.regiao.id !== chapa.regiao.id) {
				messages.push('O segundo suplente deve pertencer à mesma região da chapa');
			}
			if (chapa.suplente2.idade < 18) {
				messages.push('O segundo suplente deve ter pelo menos 18 anos');
			}
			if (size(messages) > 0) {
				errors.suplente2 = messages;
			}
		}
		if (size(chapa.representanteComissaoEleitoral) === 0) {
			errors.representanteComissaoEleitoral = ['Selecione o fiscal da comissão eleitoral'];
		}
		if (!chapa.declaracaoDesimpedimento) {
			errors.declaracaoDesimpedimento = ['É necessário fazer esta declaração para inscreve uma chapa'];
		}
		if (!calendario || isAfter('fimInscricoesChapasDelegados')) {
			errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
		}
		return errors;
	}, [chapa, calendario, isAfter]);

	const submeteForm = useCallback(async () => {
		const errors = await valida();
		let origem = 'edicao';
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			let chapaAux = { ...chapa };
			if (isExternal && !chapaAux.usuarioCriador) {
				chapaAux.usuarioCriador = userProfile.email;
			}

			if (!chapaAux.calendario) {
				chapaAux.calendario = calendario.calendario;
			}

			if (id === 'new') {
				// apenas para transporte... não será gravado no banco
				origem = 'cadastro';
				chapaAux.enviarEmail = true;
			}
			// apenas para transporte
			chapaAux.origem = origem;

			dispatch(saveChapa(chapaAux));
		}
	}, [valida, chapa, id, dispatch, userProfile, calendario]);

	const cancelar = useCallback(() => {
		dispatch(limpaStore());
		history.go(-1);
	}, [dispatch]);

	const remover = useCallback(() => {
		let errors = {};
		if (calendario && !isAfter('fimInscricoesChapasDelegados')) {
			dispatch(removeChapa(chapa.id));
		} else {
			errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
			setErrors(errors);
		}
		setShowPopupRemove(false);
	}, [calendario, isAfter, dispatch, chapa]);

	// eslint-disable-next-line
	const hidePopupMessage = useCallback(() => {
		if (showPopupMessage.type === 'success') {
			setShowPopupMessage(false);
			dispatch(limpaStore());
			dispatch(setAba(ABA_CHAPAS));
			if (window.location.pathname !== '/home') {
				history.push('/home');
			}
		} else {
			setShowPopupMessage(false);
		}
	});

	const representanteOptions = useMemo(
		() => [
			{ codigo: chapa?.titular?.id || 'titular', descricao: chapa?.titular?.nome || 'Titular' },
			{ codigo: chapa?.suplente1?.id || 'suplente1', descricao: chapa?.suplente1?.nome || 'Primeiro suplente' },
			{ codigo: chapa?.suplente2?.id || 'suplente2', descricao: chapa?.suplente2?.nome || 'Segundo suplente' }
		],
		[chapa]
	);

	const isForaPeriodoInscricao = useMemo(
		() =>
			!isDebug &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimInscricoesChapasDelegados')),
		[calendario, isAfter]
	);

	const readOnly = useMemo(() => {
		const usandoAdminCriadoPeloCidadao = !isExternal && !!chapa?.usuarioCriador;
		return usandoAdminCriadoPeloCidadao || isForaPeriodoInscricao;
	}, [chapa, isForaPeriodoInscricao]);

	return loadingChapa || loadingParticipantes || loadingCalendario ? (
		<Loader msg="Carregando dados da chapa..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendário']} />
	) : errorChapa ? (
		<ErrorMessages errorList={['Problemas ao carregar chapa']} />
	) : errorParticipantes ? (
		<ErrorMessages errorList={['Problemas ao carregar participantes']} />
	) : errorRegiao ? (
		<ErrorMessages errorList={['Problemas ao carregar regiões']} />
	) : (
		<>
			{updating && <Loader msg="Salvando chapa..." />}
			<div className="container">
				<Navigation onNavigate={onNavigateHandler} />
				<h1>{id === 'new' ? 'Nova' : readOnly ? 'Consulta de' : 'Edição de'} Chapa</h1>
				{readOnly && (
					<ErrorMessages
						errorList={[
							{
								msg: ` edição desabilitada ${
									isForaPeriodoInscricao
										? 'pois o período de inscrições está encerrado'
										: isExternal && chapa?.situacao
										? `já foi ${chapa.situacao}`
										: `foi criada no portal externo por ${chapa?.usuarioCriador}`
								}`,
								type: 'warning'
							}
						]}
					/>
				)}
				{chapa && (
					<form autoComplete="off">
						<fieldset>
							<legend>Identificação da Chapa</legend>
							<div className="row">
								<div className="form-group col-lg-8">
									<label className="control-label required">Região</label>
									<Selecao
										className={`form-control${errors.regiao ? ' form-control-error' : ''}`}
										selected={chapa.regiao}
										label={'região'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										detailDescricao={'nome'}
										detailModifier={text => text}
										autoShowList={false}
										searchTerm={regiaoSearchTerm}
										searchList={regioes.filter(r => r.nome.toLowerCase().includes(regiaoSearchTerm.toLowerCase()))}
										searchTermMinLength={0}
										errorList={errors.regiao}
										onChangeSearchTerm={e => {
											setRegiaoSearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'regiao', value: item } })}
										onUnselect={() => () => {
											setParticipantes([]);
											changeHandler({ target: { name: 'regiao', value: null } });
										}}
										noResetList={true}
										loading={loadingRegiao}
										placeholder={'Selecione uma região'}
										maxDescricaoLength={35}
										readOnly={readOnly}
									/>
								</div>
								<div className="form-group col-lg-4">
									<label className={`${isExternal ? 'required' : ''}`}>E-mail</label>
									<input
										type="text"
										className={`form-control${errors.email ? ' form-control-error' : ''}`}
										name="email"
										value={chapa.email || ''}
										onChange={changeHandler}
										onKeyDown={e => e.key === 'Enter' && e.submeteForm()}
										autoComplete="off"
										disabled={readOnly}
									/>
									{errors.email && <ErrorMessages errorList={errors.email} />}
								</div>{' '}
							</div>
						</fieldset>
						<fieldset>
							<div className="titulo-pagina">
								<legend>Integrantes</legend>
								{calendario && !isAfter('fimInscricoesChapasDelegados') && !readOnly && (
									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={() => history.push('/participante/new')}
									>
										<i className="fa fa-plus" aria-hidden="true"></i>Novo Participante
									</button>
								)}
							</div>

							<div className="row">
								<div className="form-group col-lg-12">
									<label className="required" htmlFor="">
										Titular
									</label>
									<Selecao
										className={`form-control${errors.titular ? ' form-control-error' : ''}`}
										selected={chapa.titular}
										label={'titular'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										templateDescricao={
											chapa?.titular?.dataNascimento
												? '{{nome}} de {{idade}} anos, morador da região {{regiao.nome}}'
												: '{{nome}}, morador da região {{regiao.nome}}'
										}
										detailModifier={text => text}
										maxDescricaoLength={100}
										autoShowList={false}
										searchTerm={titularSearchTerm}
										searchList={participantes
											.filter(p => ![chapa.suplente1?.id, chapa.suplente2?.id].includes(p.id))
											.filter(p => p.nome.toLowerCase().indexOf(titularSearchTerm.toLowerCase()) > -1)}
										searchTermMinLength={0}
										errorList={errors.titular}
										onChangeSearchTerm={e => {
											setTitularSearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'titular', value: item } })}
										onUnselect={() => () => {
											const evento = { target: [{ name: 'titular', value: null }] };
											if (chapa?.representanteComissaoEleitoral?.id === chapa.titular.id) {
												evento.target.push({ name: 'representanteComissaoEleitoral', value: undefined });
											}
											changeHandler(evento);
										}}
										noResetList={true}
										loading={loadingRegiao}
										placeholder={'Selecione o titular'}
										readOnly={readOnly}
									/>
								</div>

								<div className="form-group col-lg-6">
									<label className="required" htmlFor="">
										Primeiro Suplente
									</label>
									<Selecao
										className={`form-control${errors.suplente1 ? ' form-control-error' : ''}`}
										selected={chapa.suplente1}
										label={'suplente1'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										templateDescricao={
											chapa?.suplente1?.dataNascimento
												? '{{nome}} de {{idade}} anos, morador da região {{regiao.nome}}'
												: '{{nome}}, morador da região {{regiao.nome}}'
										}
										detailModifier={text => text}
										maxDescricaoLength={100}
										autoShowList={false}
										searchTerm={suplente1SearchTerm}
										searchList={participantes
											.filter(p => ![chapa.titular?.id, chapa.suplente2?.id].includes(p.id))
											.filter(p => p.nome.toLowerCase().indexOf(suplente1SearchTerm.toLowerCase()) > -1)}
										searchTermMinLength={0}
										errorList={errors.suplente1}
										onChangeSearchTerm={e => {
											setSuplente1SearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'suplente1', value: item } })}
										onUnselect={() => () => {
											const evento = { target: [{ name: 'suplente1', value: null }] };
											if (chapa?.representanteComissaoEleitoral?.id === chapa.suplente1.id) {
												evento.target.push({ name: 'representanteComissaoEleitoral', value: undefined });
											}
											changeHandler(evento);
										}}
										noResetList={true}
										loading={loadingRegiao}
										placeholder={'Selecione um primeiro suplente'}
										readOnly={readOnly}
									/>
								</div>

								<div className="form-group col-lg-6">
									<label className="required" htmlFor="">
										Segundo Suplente
									</label>
									<Selecao
										className={`form-control${errors.suplente2 ? ' form-control-error' : ''}`}
										selected={chapa.suplente2}
										label={'suplente2'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										templateDescricao={
											chapa?.suplente1?.dataNascimento
												? '{{nome}} de {{idade}} anos, morador da região {{regiao.nome}}'
												: '{{nome}}, morador da região {{regiao.nome}}'
										}
										detailModifier={text => text}
										maxDescricaoLength={100}
										autoShowList={false}
										searchTerm={suplente2SearchTerm}
										searchList={participantes
											.filter(p => ![chapa.suplente1?.id, chapa.titular?.id].includes(p.id))
											.filter(p => p.nome.toLowerCase().indexOf(suplente2SearchTerm.toLowerCase()) > -1)}
										searchTermMinLength={0}
										errorList={errors.suplente2}
										onChangeSearchTerm={e => {
											setSuplente2SearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'suplente2', value: item } })}
										onUnselect={() => () => {
											const evento = { target: [{ name: 'suplente2', value: null }] };
											if (chapa?.representanteComissaoEleitoral?.id === chapa.suplente2.id) {
												evento.target.push({ name: 'representanteComissaoEleitoral', value: undefined });
											}
											changeHandler(evento);
										}}
										noResetList={true}
										loading={loadingRegiao}
										placeholder={'Selecione o segundo suplente'}
										readOnly={readOnly}
									/>
								</div>
							</div>

							<div className="form-group">
								<label className="required" htmlFor="">
									Fiscal eleitoral
								</label>
								<RadioField
									required={false}
									name="representanteComissaoEleitoral"
									value={chapa?.representanteComissaoEleitoral?.id || ''}
									onChange={e => {
										const partAux = [chapa.titular, chapa.suplente1, chapa.suplente2].find(
											p => p.id === e.target.value
										);
										changeHandler({ target: { name: 'representanteComissaoEleitoral', value: partAux } });
									}}
									errorList={(errors || {}).representanteComissaoEleitoral}
									options={representanteOptions}
									radioStack={true}
									disabled={readOnly || !chapa?.titular || !chapa?.suplente1 || !chapa?.suplente2}
								/>
								{errors.representanteComissaoEleitoral && (
									<ErrorMessages errorList={errors.representanteComissaoEleitoral} />
								)}
								{(!chapa?.titular || !chapa?.suplente1 || !chapa?.suplente2) && (
									<small>{'Não é possível selecionar o fiscal porque falta algum dos integrantes da chapa'}</small>
								)}
							</div>
							<div>
								<div className="checkbox-input">
									<input
										type="checkbox"
										id="declaracaoDesimpedimento"
										name="declaracaoDesimpedimento"
										value={chapa.declaracaoDesimpedimento || false}
										checked={chapa.declaracaoDesimpedimento || false}
										onChange={() => null}
										onClick={() =>
											changeHandler({
												target: { name: 'declaracaoDesimpedimento', value: !chapa.declaracaoDesimpedimento }
											})
										}
										disabled={id !== 'new' || readOnly}
									/>
									<label
										className="required"
										onClick={() =>
											id !== 'new' || readOnly
												? null
												: changeHandler({
														target: { name: 'declaracaoDesimpedimento', value: !chapa.declaracaoDesimpedimento }
												  })
										}
									>
										Declaro de que os membros inscritos nessa chapa não exercem cargo em comissão na Prefeitura
										Municipal de Porto Alegre, cargo eletivo municipal, ou de representação em outro Conselho Municipal.
									</label>
								</div>
								{errors.declaracaoDesimpedimento && <ErrorMessages errorList={errors.declaracaoDesimpedimento} />}
							</div>
						</fieldset>
					</form>
				)}
				{size(validationMessages) > 0 && <ErrorMessages errorList={validationMessages} />}
				{errors.prazo && <ErrorMessages errorList={errors.prazo} />}
				<div className="buttons">
					<button type="button" className="btn btn-outline-danger" onClick={cancelar}>
						<i className="fa fa-times" aria-hidden="true"></i>Cancelar
					</button>
					{!readOnly && (
						<>
							{id !== 'new' && (
								<button type="button" className="btn btn-danger" onClick={() => setShowPopupRemove(true)}>
									<i className="fa fa-trash-o" aria-hidden="true"></i>Remover
								</button>
							)}
							<button type="button" className="btn btn-primary" onClick={submeteForm}>
								<i className="fa fa-save" aria-hidden="true"></i>Salvar
							</button>
						</>
					)}
				</div>
				{showPopupRemove && (
					<div
						className="modal"
						style={{
							backgroundColor: 'rgba(0,0,0,.5)',
							display: 'flex',
							flexFlow: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						role="dialog"
					>
						<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
							<div className="modal-content">
								<div className="modal-header">
									<h1 className="h3">Remover Chapa</h1>
								</div>
								<div className="modal-body">
									<span>Remover a chapa e manter participantes cadastrados?</span>
								</div>
								<div className="modal-footer">
									<div className="buttons">
										<button type="button" className="btn btn-outline-danger" onClick={() => setShowPopupRemove(false)}>
											<i className="fa fa-times" aria-hidden="true"></i>Cancelar
										</button>
										<button type="button" className="btn btn-danger" onClick={remover}>
											Confirmar Remoção
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{size(showPopupMessage) > 0 && (
					<ModalMessage
						showPopupMessage={showPopupMessage}
						hidePopupMessage={hidePopupMessage}
						validationMessages={validationMessages}
					/>
				)}
			</div>
		</>
	);
}
Chapa.displayName = 'Chapa';
Chapa.propTypes = {};

export default Chapa;
