import React from 'react';

import PropTypes from 'prop-types';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import useMessages from '../customHooks/useMessages';
import { format } from '../utils/tools';

const CopyButton = ({ texto, formato, title }) => {
	const { createMessage } = useMessages();
	return (
		<CopyToClipboard
			text={format(texto, formato)}
			onCopy={(text, result) => createMessage(`${title} ${text}${result ? ' copiado' : 'não copiado'}.`, 2)}
		>
			<span className="icone-copiar" onClick={e => e.stopPropagation()}>
				<i className="fa fa-copy" aria-hidden="true" title={title} />
			</span>
		</CopyToClipboard>
	);
};
CopyButton.displayName = 'CopyButton';

CopyButton.propTypes = {
	texto: PropTypes.string,
	formato: PropTypes.string,
	title: PropTypes.string,
	createMessage: PropTypes.any
};

export default CopyButton;
