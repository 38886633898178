import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	current: null,
	updating: false,
	messages: [],
	validationMessages: [],
	situacaoAnalise: null
};

export const chapaSlice = createSlice({
	name: 'chapa',
	initialState: INITIAL_STATE,
	reducers: {
		// saga actions
		saveChapa: () => {},
		removeChapa: () => {},
		// reducer actions
		setCurrent: (state, action) => {
			state.current = action.payload;
		},
		setUpdating: (state, action) => {
			state.updating = action.payload;
		},
		setMessage: (state, action) => {
			state.messages = [action.payload];
		},
		setMessages: (state, action) => {
			state.messages = action.payload;
		},
		setValidationMessages: (state, action) => {
			state.validationMessages = action.payload;
		},
		setSituacaoAnalise: (state, action) => {
			state.situacaoAnalise = action.payload;
		},
		limpaStore: state => {
			state = INITIAL_STATE;
			return state;
		}
	}
});

const { actions, reducer: chapaReducer } = chapaSlice;

export const {
	saveChapa,
	removeChapa,
	setCurrent,
	setUpdating,
	setMessage,
	setMessages,
	setValidationMessages,
	setSituacaoAnalise,
	limpaStore
} = actions;

export default chapaReducer;
