import { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { get, size } from 'lodash';

import { keycloakObject as kc } from '../index';
import { setUserProfile as setUserProfileStore } from '../Redux/keycloak/keycloakSlice';

const useUserProfile = () => {
	const dispatch = useDispatch();
	const userProfileStore = useSelector(state => state.keycloak.userProfile);

	const [userProfile, setUserProfile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [hasRole, setHasRole] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	const [usuarioInterno, setUsuarioInterno] = useState(false);

	const assembleUserProfile = useCallback(async () => {
		await kc.loadUserInfo();
		await kc.loadUserProfile();
		const userInfo = Object.assign({}, kc.userInfo, kc.profile);
		setUserProfile(userInfo);
		setLoading(false);
		return userInfo;
	}, []);

	useEffect(() => {
		if (kc.authenticated) {
			if (size(userProfile) === 0) {
				if (size(userProfileStore) > 0) {
					setUserProfile(userProfileStore);
					setLoading(false);
				} else {
					assembleUserProfile().then(up => dispatch(setUserProfileStore(up)));
				}
			}
		}
	}, [assembleUserProfile, dispatch, userProfile, userProfileStore]);

	useEffect(() => {
		if (userProfile) {
			setUsuarioInterno(!!get(userProfile, 'attributes.LDAP_ID'));
			setAuthenticated(!!get(kc, 'authenticated'));
		}
	}, [userProfile]);

	useEffect(() => {
		if (authenticated) {
			const hasRoleFn = get(kc, 'hasResourceRole');
			// to set functions in states, you need to pass it as the return of an argument-less function.
			// otherwise, React will interpret your parameter as the lazy initilization function it accepts besides the value;
			// that is because pass an argument-less function is the way React lazily initialize the state.
			// FUNCTION IMPLEMENTATION:
			// const hasRoleFn = (role, resource) => {
			// 	const access = kc.resourceAccess[resource || kc.clientId];
			// 	const roles = access.roles;
			// 	return !!access && roles.indexOf(role) >= 0;
			// };
			setHasRole(() => hasRoleFn);
		}
	}, [authenticated]);

	return { userProfile, authenticated, usuarioInterno, hasRole, loading };
};
useUserProfile.displayName = 'useUserProfile';

export default useUserProfile;
