import styled from 'styled-components';

import placeholder from '../../assets/placeholder.jpeg';

export const FileUploadContainer = styled.section`
	position: relative;
	margin: 0.75rem 0 0.5rem;
	border: 2px dotted #c5c5c9;
	padding: 2rem 1.25rem 1.25rem;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
`;

export const FormField = styled.input`
	font-size: 18px;
	display: block;
	width: 100%;
	border: none;
	text-transform: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;

	&:focus {
		outline: none;
	}
`;

export const InputLabel = styled.label`
	top: -21px;
	font-size: 13px;
	color: black;
	left: 0;
	position: absolute;
`;

export const DragDropText = styled.p`
	font-weight: bold;
	margin-top: 0;
	text-align: center;

	small {
		display: block;
		color: #7d7f80;
	}
`;

export const FilePreviewContainer = styled.article`
	margin-bottom: 1rem;

	span {
		font-size: 14px;
	}
`;

export const PreviewList = styled.section`
	display: flex;
	flex-wrap: wrap;

	@media only screen and (max-width: 400px) {
		flex-direction: column;
	}
`;

export const FileMetaData = styled.div`
	display: ${props => (props.isImageFile ? 'none' : 'flex')};
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px;
	border-radius: 6px;
	color: white;
	font-weight: bold;
	background-color: rgba(5, 5, 5, 0.55);

	span {
		max-height: 67px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	aside {
		margin-top: auto;
		display: flex;
		justify-content: space-between;
	}
`;

export const RemoveFileIcon = styled.i`
	cursor: pointer;

	&:hover {
		transform: scale(1.3);
	}
`;

export const PreviewContainer = styled.section`
	width: ${props => (props.isMultiple ? '20%' : 'auto')};
	min-width: ${props => (props.isImageFile ? '0' : '160px')};
	height: 120px;
	border-radius: 6px;
	box-sizing: border-box;

	&:hover {
		opacity: 0.8;

		${FileMetaData} {
			display: flex;
		}
	}

	& > div:first-of-type {
		height: 100%;
		position: relative;
	}

	@media only screen and (max-width: 750px) {
		width: ${props => (props.isMultiple ? '25%' : 'auto')};
	}

	@media only screen and (max-width: 500px) {
		width: ${props => (props.isMultiple ? '50%' : 'auto')};
	}

	@media only screen and (max-width: 400px) {
		width: ${props => (props.isMultiple ? '100%' : 'auto')};
		padding: 0 0 0.4em;
	}
`;

export const ImagePreview = styled.img`
	border-radius: 6px;
	width: 100%;
	height: 100%;
`;

export const ImageContainer = styled.div`
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(${placeholder});
	overflow: hidden;

	height: 120px;
	width: auto;
`;
