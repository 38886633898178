import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ReactReduxContext } from 'react-redux';

import uuid from 'uuid/v1';

import MessageDock from './MessageDock';

export const LayoutContext = React.createContext({
	layout: 'XS',
	width: window.innerWidth,
	height: window.innerHeight
});

class LayoutContextWrapper extends Component {
	static contextType = ReactReduxContext;

	/**
	 * Create toast message
	 * <param name="text" value="Message text"/>
	 * <param name="timeout" value="time in serconds" optional="true"/>
	 */
	createMessage = (msg, timeout) => {
		let text = msg;
		let type = 'info';
		if (typeof msg === 'object') {
			text = msg.message;
			timeout = msg.timeout || timeout;
			type = msg.type;
		}

		let messageList = this.state.messageList;
		const id = uuid();
		messageList.push({ text, id, type, dismiss: this.removeMessage });
		this.setState({ messageList });
		if (timeout) {
			setTimeout(() => this.removeMessage(id), timeout * 1000);
		}
		return id;
	};

	/**
	 * Remove toast message
	 * <param name="id" value="Message id to remove"/>
	 */
	removeMessage = id => {
		let messageList = this.state.messageList;
		const index = messageList.findIndex(item => item.id === id);
		if (index > -1) {
			messageList.splice(index, 1);
			this.setState({ messageList });
		}
	};

	state = {
		layout: 'XS',
		width: window.innerWidth,
		height: window.innerHeight,
		createMessage: this.createMessage,
		removeMessage: this.removeMessage,
		messageList: []
	};

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		this.setState({
			width: window.innerWidth,
			height: window.innerHeight
		});
	};

	render() {
		return (
			<LayoutContext.Provider value={this.state}>
				{this.props.children}
				<MessageDock />
			</LayoutContext.Provider>
		);
	}
}
LayoutContextWrapper.displayName = 'LayoutContextWrapper';
LayoutContextWrapper.propTypes = {
	children: PropTypes.element
};

export default LayoutContextWrapper;
