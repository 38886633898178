import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Navigation, {
	ABA_ANALISE,
	ABA_APURACAO,
	ABA_CHAPAS,
	ABA_DELEGADOS,
	ABA_ENTIDADES,
	ABA_PARTICIPANTES,
	ABA_RELATORIOS,
	ABA_VOTACAO
} from '../../components/Navigation';
import useUserProfile from '../../customHooks/useUserProfile';
import { setAba } from '../../Redux/Home/homeSlice';
import { history } from '../../Redux/store';
import { getRole, ROLES } from '../../utils/constants';
import { isExternal } from '../../utils/tools';
import AnaliseComponent from './analise/analiseComponent';
import ApuracaoComponent from './apuracao/apuracaoComponent';
import ChapasComponent from './chapasComponent';
import DelegadosComponent from './delegadosComponent';
import EntidadesComponent from './entidadesComponent';
import ParticipantesComponent from './participantesComponent';
import RelatoriosComponent from './relatoriosComponent';
import VotacaoComponent from './votacao/votacaoComponent';

function Home() {
	const dispatch = useDispatch();

	/* CUSTOM HOOKS */
	const { userProfile, hasRole } = useUserProfile();

	/* ESTADOS */
	const aba = useSelector(state => state.home.aba);

	useEffect(() => {
		if (userProfile && hasRole && !aba) {
			const roleCadPart = getRole(ROLES.CAD_PARTICIPANTE);
			const roleOperador = getRole(ROLES.OPERADOR);
			if (isExternal || hasRole(roleCadPart)) {
				dispatch(setAba(ABA_PARTICIPANTES));
			} else if (hasRole(roleOperador)) {
				dispatch(setAba(ABA_VOTACAO));
			} else {
				history.push('/accessdenied');
			}
		}
	}, [dispatch, hasRole, userProfile, aba]);

	return (
		<div className="container">
			<div className="areas-home">
				<Navigation />
				{aba === ABA_CHAPAS && <ChapasComponent />}
				{aba === ABA_DELEGADOS && <DelegadosComponent />}
				{aba === ABA_PARTICIPANTES && <ParticipantesComponent />}
				{aba === ABA_ENTIDADES && <EntidadesComponent />}
				{aba === ABA_RELATORIOS && <RelatoriosComponent />}
				{aba === ABA_ANALISE && <AnaliseComponent />}
				{aba === ABA_VOTACAO && <VotacaoComponent />}
				{aba === ABA_APURACAO && <ApuracaoComponent />}
			</div>
		</div>
	);
}
Home.displayName = 'Home';
Home.propTypes = {};

export default Home;
