import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

const ErrorMessages = props =>
	size(props.errorList) > 0 ? (
		<ul className={`error-block ${props.type}-message`}>
			{props.errorList.map((error, i) => {
				let message;
				let type = props.type;
				let resolvido = false;

				if (typeof error === 'string') {
					message = error;
				} else {
					message = error.msg || error.message;
					type = error.type ? error.type : type;
					resolvido = error.resolvido ? error.resolvido : resolvido;
				}

				return (
					<li key={i} className={`${type}-message`} style={{ textDecoration: resolvido ? 'line-through' : '' }}>
						<i className="fa fa-exclamation-circle" style={{ marginRight: '8px' }} aria-hidden="true" />
						<span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>
					</li>
				);
			})}
		</ul>
	) : null;

ErrorMessages.displayName = 'ErrorMessages';

ErrorMessages.propTypes = {
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	type: PropTypes.string
};

ErrorMessages.defaultProps = {
	type: 'error'
};

export default ErrorMessages;
