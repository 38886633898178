import React from 'react';

import PropTypes from 'prop-types';

import { ReactReduxContext } from 'react-redux';

import { get } from 'lodash';

import { ROLES } from '../utils/constants';

class Permissao extends React.Component {
	displayName = 'Permissao';
	static contextType = ReactReduxContext;

	constructor(props) {
		super(props);
		this.state = { skip: 0, searchTerm: '' };
	}

	componentDidMount() {
		if (!this.state.hasRole) {
			const hasRole = get(this.context, 'store.kc.authenticated')
				? get(this.context, 'store.kc.hasResourceRole')
				: null;
			this.setState({ hasRole });
		}
	}

	render() {
		const { roles, role, children, semPermissao = null } = this.props;

		let temPermissao = !role && !roles;

		if (this.state.hasRole) {
			if (role) {
				if (ROLES.LOGADO === role) {
					temPermissao = get(this.context, 'store.kc.authenticated');
				} else {
					temPermissao = ROLES.properties[role] && this.state.hasRole(ROLES.properties[role].value);
				}
			}
			if (roles) {
				temPermissao = roles.reduce(
					(acc, r) => (acc ? acc : ROLES.properties[r] && this.state.hasRole(ROLES.properties[r].value)),
					temPermissao
				);
			}
		}

		return temPermissao ? children : semPermissao;
	}
}

Permissao.propTypes = {
	roles: PropTypes.array,
	role: PropTypes.number,
	children: PropTypes.any,
	semPermissao: PropTypes.element
};

const SemPermissao = ({
	h3 = 'Você não tem permissão para ver isso.',
	h4 = 'Se precisa fazer disso, solicite ao administrador do seu setor.'
}) => (
	<>
		<h3>{h3}</h3>
		<h4>{h4}</h4>
	</>
);
SemPermissao.displayName = 'SemPermissao';
SemPermissao.propTypes = {
	h3: PropTypes.string,
	h4: PropTypes.string
};

export default Permissao;
export { SemPermissao };
