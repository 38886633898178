import React, { useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';
import { v4 as uuid } from 'uuid';

import useMessages from '../../customHooks/useMessages';
import {
	FileUploadContainer,
	FormField,
	DragDropText,
	FilePreviewContainer,
	ImagePreview,
	PreviewContainer,
	PreviewList,
	FileMetaData,
	RemoveFileIcon,
	InputLabel,
	ImageContainer
} from './file-upload.styles';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 2 * 1000 * 1000; // 2Mb
const DEFAULT_MAX_MULTILPE_FILES = null;

const TYPES_ALLOWED = {
	jpg: 'image/jpg',
	jpeg: 'image/jpeg',
	png: 'image/png',
	bpm: 'image/bpm',
	gif: 'image/gif',
	pdf: 'application/pdf',
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	txt: 'text/plain',
	rtf: 'application/rtf',
	odt: 'application/vnd.oasis.opendocument.text'
};

const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key]);

const convertBytesToKB = bytes => Math.round(bytes / 1000); // é como mostra nos gerenciadores de arquivos

const convertBytesToMB = bytes => Math.round(bytes / 1000 / 1000);

const FileUpload = ({
	label,
	value,
	updateFilesCb = () => {},
	maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
	maxFiles = DEFAULT_MAX_MULTILPE_FILES,
	...otherProps
}) => {
	const { createMessage } = useMessages();
	const fileInputField = useRef(null);
	const [files, setFiles] = useState(
		value
			? value.reduce((acc, f) => {
					acc[f.name] = f;
					return acc;
			  }, {})
			: {}
	);

	const addNewFiles = newFiles => {
		const qtd = size(newFiles);
		let promises = [];
		if (otherProps.disabled) {
			createMessage({ message: 'Alterações não são permitidas', type: 'error', timeout: 20 });
			promises.push(Promise.resolve());
		} else if (maxFiles && qtd + size(files) > maxFiles) {
			createMessage({ message: `Você pode adicionar, no máximo, ${maxFiles}} arquivos`, type: 'error', timeout: 5 });
			promises.push(Promise.resolve());
		} else if (!otherProps.multiple && qtd > 1) {
			createMessage({ message: 'Você pode adicionar apenas um arquivo', type: 'error', timeout: 5 });
			promises.push(Promise.resolve());
		} else {
			for (let i = 0; i < Object.keys(newFiles).length; i++) {
				const filename = Object.keys(newFiles)[i];
				const file = newFiles[filename];
				if (!Object.values(TYPES_ALLOWED).includes(file.type)) {
					createMessage({
						message: `Tipo de arquivo não permitido. Tipos permitidos: ${Object.keys(TYPES_ALLOWED).join(', ')}`,
						type: 'error',
						timeout: 5
					});
					promises.push(Promise.resolve());
				} else if (file.size <= maxFileSizeInBytes) {
					promises.push(
						new Promise((resolve, reject) => {
							getBase64(file)
								.then(base64String => {
									files[file.name] = {
										id: uuid(),
										name: file.name,
										type: file.type,
										size: file.size,
										base64: base64String
									};
									resolve();
								})
								.catch(error => {
									reject(error);
								});
						})
					);
				} else {
					createMessage({
						message: `Arquivo ${file.name} tem ${file.size} bytes, o que é mais que o máximo permitido (${maxFileSizeInBytes})`,
						type: 'error',
						timeout: 5
					});
					promises.push(Promise.resolve());
				}
			}
		}
		return Promise.all(promises).then(() => ({ ...files }));
	};

	const callUpdateFilesCb = files => {
		const filesAsArray = convertNestedObjectToArray(files);
		updateFilesCb(filesAsArray);
	};

	const handleNewFileUpload = e => {
		const { files: newFiles } = e.target;
		if (newFiles.length) {
			addNewFiles(newFiles).then(updatedFiles => {
				setFiles(updatedFiles);
				callUpdateFilesCb(updatedFiles);
			});
		}
	};

	const removeFile = fileName => {
		if (!otherProps.disabled) {
			delete files[fileName];
			setFiles({ ...files });
			callUpdateFilesCb({ ...files });
		}
	};

	const showFileUploadContainer = useMemo(() => {
		let show = false;
		if (otherProps.multiple) {
			if (maxFiles) {
				show = size(files) < maxFiles;
			} else {
				show = true;
			}
		} else {
			show = size(files) === 0;
		}
		return show;
	}, [files, maxFiles, otherProps.multiple]);

	return (
		<>
			{showFileUploadContainer && (
				<FileUploadContainer>
					{label && <InputLabel>{label}</InputLabel>}
					{otherProps.disabled ? (
						<DragDropText>
							<small>não fornecido</small>
						</DragDropText>
					) : (
						<DragDropText>
							{otherProps.multiple
								? 'Solte seus arquivos aqui ou clique para selecioná-los'
								: 'Solte seu arquivo aqui ou clique para selecioná-lo'}
							{maxFiles && <small>São permitidos no máximo {maxFiles} arquivos</small>}
							<small>Cada arquivo pode ter no máximo {convertBytesToMB(maxFileSizeInBytes)}MB de tamanho</small>
						</DragDropText>
					)}
					<FormField
						type="file"
						ref={fileInputField}
						onChange={handleNewFileUpload}
						title=""
						value=""
						{...otherProps}
					/>
				</FileUploadContainer>
			)}
			{size(files) > 0 && (
				<FilePreviewContainer id="FilePreviewContainer">
					<PreviewList id="PreviewList">
						{Object.keys(files).map((fileName, index) => {
							let file = files[fileName];
							let isImageFile = !!(file.type.split('/')[0] === 'image' && (file.base64 || file.link));
							return (
								<PreviewContainer
									id="PreviewContainer"
									key={fileName}
									onClick={() => (file.link ? window.open(file.link.replace(/\?.*/, '')) : null)}
									isMultiple={!!otherProps.multiple}
									isImageFile={isImageFile}
								>
									<div>
										{isImageFile &&
											(file.base64 ? (
												<ImagePreview src={`data:${file.type};base64, ${file.base64}`} alt={`file preview ${index}`} />
											) : file.link ? (
												<ImageContainer>
													<ImagePreview src={file.link.replace(/\?.*/, '')} alt={`file preview ${index}`} />
												</ImageContainer>
											) : null)}

										<FileMetaData id="FileMetaData" isImageFile={isImageFile}>
											<span title={file.name}>{file.name}</span>
											<aside>
												<span>{convertBytesToKB(file.size)} kb</span>
												{!otherProps.disabled && (
													<RemoveFileIcon
														className="fa fa-trash"
														onClick={e => {
															e.stopPropagation();
															removeFile(fileName);
														}}
													/>
												)}
											</aside>
										</FileMetaData>
									</div>
								</PreviewContainer>
							);
						})}
					</PreviewList>
				</FilePreviewContainer>
			)}
		</>
	);
};
FileUpload.displayName = 'FileUploadComponent';
FileUpload.propTypes = {
	label: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.object),
	updateFilesCb: PropTypes.func,
	maxFileSizeInBytes: PropTypes.number,
	maxFiles: PropTypes.number
};

export default FileUpload;

async function getBase64(file) {
	const promise = new Promise((resolve, reject) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = () => {
			// use a regex to remove data url part
			const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
			resolve(base64String);
		};
		reader.readAsDataURL(file);
		reader.onerror = error => {
			reject(error);
		};
	});
	return promise;
}
