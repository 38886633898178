import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useLazyQuery, useQuery } from 'react-apollo';
import InputMask from 'react-input-mask';

import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { omit, size, trim } from 'lodash';
import moment from 'moment';

import CdlField from '../../components/CdlField';
import ErrorMessages from '../../components/ErrorMessages';
import FileUpload from '../../components/file-upload/file-upload.component';
import Loader from '../../components/Loader';
import ModalMessage from '../../components/ModalMessage';
// import Navigation from '../../components/Navigation';
import Selecao from '../../components/Selecao';
import useCalendario from '../../customHooks/useCalendario';
import useUserProfile from '../../customHooks/useUserProfile';
import { history } from '../../Redux/store';
import { accessApi, isCPF, isDebug, isEmail, permiteInput, isDate, isExternal } from '../../utils/tools';
import { isAtLeast } from '../Participante/participante';

const tiposProp = ['titular', 'suplente1', 'suplente2'];
const tiposLabel = ['Titular', 'Primeiro Suplente', 'Segundo Suplente'];
const probsProp = ['idade', 'endereco', 'cargo'];
const probsLabel = [
	'Idade inferior a 18 anos',
	'Reside fora da região da chapa',
	'Exerce cargo em comissão na Prefeitura Municipal de Porto Alegre, cargo eletivo municipal, ou de representação em outro Conselho Municipal'
];

const participanteList = loader('./participanteList.gql');

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[SOLICITACAO_IMPUGNACAO ${i++}]:`, ...args);

function SolicitacaoImpugnacaoChapa() {
	/* CUSTOM HOOKS */
	const { userProfile, authenticated } = useUserProfile();
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const [participante, setParticipante] = useState(null);
	const [participanteLido, setParticipanteLido] = useState(null);
	const [mensagem, setMensagem] = useState('');
	const [showPopupMessage, setShowPopupMessage] = useState(false);
	const [errors, setErrors] = useState([]);
	const [regioes, setRegioes] = useState([]);
	const [regiaoSearchTerm, setRegiaoSearchTerm] = useState('');
	const [chapas, setChapas] = useState(null);
	const [chapaSelecionada, setChapaSelecionada] = useState(null);
	const [chapaSearchTerm, setChapaSearchTerm] = useState('');
	const [provasImpugnacao, setProvasImpugnacao] = useState(null);
	const [problemas, setProblemas] = useState(() => {
		let saida = {};
		tiposProp.forEach((tipo, idx) => {
			saida[tipo] = {
				label: tiposLabel[idx],
				problemas: {}
			};
			probsProp.forEach((prob, index) => {
				saida[tipo].problemas[prob] = {
					value: false,
					label: probsLabel[index]
				};
			});
		});
		return saida;
	});

	const [getParticipantes, { data: dataPart, loading: loadingPart, errors: errorsPart }] = useLazyQuery(
		participanteList,
		{
			fetchPolicy: 'network-only'
		}
	);

	const { data: dataRegiao, loading: loadingRegiao, errors: errorRegiao } = useQuery(
		gql`
			query ListaRegioes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaRegiaoList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					numero
					bairros
				}
				count: ECmduaRegiaoCount
			}
		`,
		{
			variables: {},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const [getChapas, { data: dataChapas, loading: loadingChapas, errors: errorChapas }] = useLazyQuery(
		gql`
			query ListaChapas($term: String, $skip: Int, $limit: Int) {
				list: ECmduaChapaList(term: $term, skip: $skip, limit: $limit) {
					id
					numero
					regiao {
						id
						numero
						nome
					}
					titular {
						id
						nome
					}
					suplente1 {
						id
						nome
					}
					suplente2 {
						id
						nome
					}
				}
			}
		`,
		{
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		if (calendario?.calendario) {
			// debugLog('[participante.regiao]', participante.regiao);
			getChapas({
				variables: {
					term: JSON.stringify({
						calendario: calendario?.calendario,
						sort: { numero: 1 }
					}),
					skip: 0,
					limit: 10000
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calendario, getChapas]);

	useEffect(() => {
		if (dataChapas?.list) {
			debugLog('[dataChapas]', dataChapas);
			setChapas(dataChapas.list);
		}
	}, [dataChapas]);

	useEffect(() => {
		if (authenticated && userProfile) {
			debugLog('[authenticated, userProfile]', authenticated, userProfile);
			getParticipantes({
				variables: {
					term: JSON.stringify({
						email: userProfile.email
					}),
					skip: 0,
					limit: 1
				}
			});
		}
	}, [authenticated, getParticipantes, userProfile]);

	useEffect(() => {
		if (dataPart) {
			const lista = dataPart.list;
			debugLog('[dataPart]', lista);
			if (lista && lista.length === 0) {
				setErrors({
					load: [
						'Você precisa estar cadastrado como participante no sistema para solicitar a impugnação de chapa. IMPORTANTE: O e-mail do cadastro do participante deve ser o mesmo do login AcessoPOA.'
					]
				});
			} else {
				setParticipante(lista[0]);
				setParticipanteLido(lista[0]);
			}
		}
	}, [dataPart]);

	useEffect(() => {
		if (dataRegiao) {
			debugLog('[dataRegiao]', dataRegiao);
			setRegioes(dataRegiao.list);
		}
	}, [dataRegiao]);

	useEffect(() => {
		if (participante) {
			debugLog('[participante]', participante);
			if (participante.regiao && !participante.endereco) {
				setParticipante({ ...participante, regiao: null });
			}
		}
	}, [participante]);

	const changeHandler = useCallback(
		participante => e => {
			let { name, value } = e.target;
			let allow = true;
			if (name === 'nome') {
				allow = permiteInput(value, 'text', 0, 100);
			} else if (name === 'email') {
				allow = permiteInput(value, 'email', 0, 100);
			} else if (name === 'tituloEleitor') {
				allow = permiteInput(value, 'number', 0, 12);
			}
			if (allow) {
				setErrors(old => omit(old, [name]) || {});
				setParticipante({ ...participante, [name]: value });
			}
		},
		[]
	);

	const changeHandlerProvasImpugnacao = useCallback(
		e => {
			let { name, value } = e.target;
			setErrors(old => omit(old, [name]) || {});
			setProvasImpugnacao({ ...provasImpugnacao, [name]: value });
		},
		[provasImpugnacao]
	);

	const valida = useCallback(async () => {
		let errors = {};
		if (size(trim(participante.nome)) === 0) {
			errors.nome = ['Informe o nome do participante'];
		} else {
			if (/[^A-Za-záâäàãéêëèẽíîïìĩóôöòõúûüùũ\s]/.test(participante.nome)) {
				errors.nome = ['Nome só pode possuir letras'];
			} else {
				const naoTemEspaco = !/\s/.test(participante.nome.trim());
				if (naoTemEspaco) {
					errors.nome = ['Informar nome completo'];
				}
			}
		}
		if (size(trim(participante.email)) === 0) {
			errors.email = ['Informe o e-mail do participante'];
		} else if (!isEmail(participante.email)) {
			errors.email = ['E-mail inválido'];
		}
		if (size(trim(participante.telefone)) > 0) {
			const telefone = participante.telefone.replace(/\D/g, '');
			if (/^\d\d9/.test(telefone) && size(telefone) < 11) {
				errors.telefone = ['Telefone celular deve ser composto por DDD + 9 dígitos'];
			} else if (size(telefone) < 10) {
				errors.telefone = ['Telefone convencional deve ser composto por DDD + 8 dígitos'];
			}
		}
		if (size(trim(participante.dataNascimento)) === 0) {
			errors.dataNascimento = ['Informe a data de nascimento do participante'];
		} else if (size(participante.dataNascimento.replace(/\D/g, '')) !== 8) {
			errors.dataNascimento = ['Data de nascimento inválida 1'];
		} else if (!isDate(participante.dataNascimento)) {
			errors.dataNascimento = ['Data de nascimento inválida 2'];
		} else if (!isAtLeast(participante.dataNascimento, 16)) {
			errors.dataNascimento = ['A idade deve ser pelo menos 16 anos para votar e pelo menos 18 anos para concorrer'];
		}
		if (size(trim(participante.endereco)) === 0) {
			errors.endereco = ['Informe o endereço do participante'];
		}
		if (size(trim(participante.regiao)) === 0) {
			errors.regiao = ['Informe a região'];
		}
		if (size(trim(participante.cpf)) === 0) {
			errors.cpf = ['Informe o CPF do participante'];
		} else if (!isCPF(participante.cpf)) {
			errors.cpf = ['CPF inválido'];
		}
		if (size(trim(participante.rg)) === 0) {
			errors.rg = ['Informe o RG do participante'];
		}
		if (size(trim(participante.rgExpedicao)) === 0) {
			errors.rgExpedicao = ['Informe a data de expedição do RG do participante'];
		} else if (size(participante.rgExpedicao.replace(/\D/g, '')) !== 8) {
			errors.rgExpedicao = ['Data de expedição do RG inválida'];
		} else if (!isDate(participante.rgExpedicao)) {
			errors.rgExpedicao = ['Data de expedição do RG inválida'];
		}
		if (size(trim(participante.rgOrgaoEmissor)) === 0) {
			errors.rgOrgaoEmissor = ['Informe o Órgão Emissor do RG do participante'];
		}
		const problema = tiposProp.reduce(
			(acc, p) => acc || probsProp.reduce((acc2, pp) => acc2 || problemas[p].problemas[pp].value, false),
			false
		);
		if ((chapaSelecionada.titular || chapaSelecionada.suplente1 || chapaSelecionada.suplente2) && !problema) {
			errors.problemas = ['Ao menos um problema com um dos integrantes da chapa deve ser selecionado'];
		}
		if (isExternal) {
			if (size(trim(provasImpugnacao?.provasImpugnacao)) === 0) {
				errors.provasImpugnacao = ['As provas para impugnação devem ser fornecidas'];
			}
		}

		if (size(trim(mensagem)) === 0) {
			errors.mensagem = ['É necessário explicar o motivo pelo qual a chapa deve ser impugnada'];
		}
		return errors;
	}, [participante, problemas, mensagem, provasImpugnacao, chapaSelecionada]);

	const submeteForm = useCallback(async () => {
		const errors = await valida();
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			let data = {};
			data.participante = participante;
			data.chapa = chapaSelecionada;
			data.problemasTotais = [];
			data.problemasImpugnados = [];
			let provasImpugnacaoAux = provasImpugnacao.provasImpugnacao;
			provasImpugnacaoAux.docName = `provasImpugnacao-${participante.id}-${chapaSelecionada.id}`;
			data.provasImpugnacao = provasImpugnacaoAux;
			const from = `${participante.nome} <${participante.email}>`;
			const subject = `Solicitação de impugnação da Chapa ${chapaSelecionada.numero} da região ${participante.regiao.numero}`;
			let messages = [];
			messages.push(`<h1>Região: ${participante.regiao.nome}</h1>`);

			messages.push('<h2>Solicitante</h2>');
			messages.push(`<div>Nome: <b>${participante.nome}</b></div>`);
			messages.push(`<div>E-mail: <b>${participante.email}</b></div>`);
			messages.push(`<div>Telefone: <b>${participante.telefone}</b></div>`);
			messages.push(`<div>Data de Nascimento: <b>${participante.dataNascimento}</b></div>`);
			messages.push(`<div>CPF: <b>${participante.cpf}</b></div>`);
			messages.push(`<div>RG: <b>${participante.rg}</b></div>`);
			messages.push(`<div>Órgão Emissor: <b>${participante.rgOrgaoEmissor}</b></div>`);
			messages.push(`<div>Data de Expedição: <b>${participante.rgExpedicao}</b></div>`);
			messages.push(
				`<div>Endereço: <b>${participante.endereco.enderecoFormatadoCurto}${
					participante.complemento ? ` - ${participante.complemento}` : ''
				}</b></div>`
			);
			messages.push('<h2>Chapa</h2>');
			messages.push(`<div>Número: ${chapaSelecionada.numero}</div>`);

			messages.push('<h2>Problemas</h2>');
			tiposProp.forEach(tipo => {
				const problema = probsProp.reduce((acc2, pp) => acc2 || problemas[tipo].problemas[pp].value, false);
				if (problema) {
					messages.push(`<h3>${chapaSelecionada[tipo].nome}<small> (${problemas[tipo].label})</small></h3>`);
					probsProp.forEach(pp => {
						if (problemas[tipo].problemas[pp].value) {
							messages.push(`<div>${problemas[tipo].problemas[pp].label}</div>`);
							data.problemasImpugnados.push(`${problemas[tipo].label} - ${problemas[tipo].problemas[pp].label}`);
						}
						data.problemasTotais.push(`${problemas[tipo].label} - ${problemas[tipo].problemas[pp].label}`);
					});
				}
			});
			messages.push('<h3>Explicação:</h3>');
			messages.push(`<div><b>${mensagem.replace(/\n/g, '<br />')}</b></div>`);

			const message = messages.join('');

			try {
				const msgComissao = await accessApi('/api/mensagem-para-comissao-eleitoral', false, {
					method: 'post',
					data: { subject, from, message: message.replace('\n', '<br />'), data }
				});
				setShowPopupMessage({ message: 'Impugnação registrada com sucesso!', type: 'success' });
				if (msgComissao) {
					debugLog('msgComissao', msgComissao);
				}
			} catch (error) {
				console.error(error);
				setShowPopupMessage({ message: 'Não foi possível registrar a impugnação!', type: 'error' });
			}
		}
	}, [valida, participante, chapaSelecionada, mensagem, problemas, provasImpugnacao]);

	// eslint-disable-next-line
	const hidePopupMessage = useCallback(() => {
		if (showPopupMessage.type === 'success') {
			setShowPopupMessage(false);
			history.push('/publica');
		} else {
			setShowPopupMessage(false);
		}
	});

	const isForaPeriodoImpugnacao = useMemo(
		() =>
			!isDebug &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimImpugnacaoChapasDelegados')),
		[calendario, isAfter]
	);

	return loadingRegiao || loadingCalendario ? (
		<Loader msg="Carregando regiões" />
	) : loadingPart ? (
		<Loader msg="Carregando dados do usuário logado" />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendário']} />
	) : errorsPart ? (
		<ErrorMessages errorList={['Problemas ao carregar o participante']} />
	) : errorRegiao ? (
		<ErrorMessages errorList={['Problemas ao carregar regiões']} />
	) : errorChapas ? (
		<ErrorMessages errorList={['Problemas ao carregar chapas da região']} />
	) : errors?.load && !participante ? (
		<div className="container">
			<ErrorMessages errorList={errors.load} />
			<div className="buttons">
				<button type="button" className="btn btn-primary" onClick={() => history.push('/participante/new')}>
					Cadastrar-se como participante
				</button>
				<button type="button" className="btn btn-outline-danger" onClick={() => history.push('/publica')}>
					<i className="fa fa-times" aria-hidden="true"></i>Cancelar
				</button>
			</div>
		</div>
	) : participante ? (
		<div className="container">
			{/* <Navigation /> */}
			{authenticated && !isForaPeriodoImpugnacao ? (
				<form>
					<div className="titulo-pagina">
						<h1 className="h3">Solicitação de Impugnação de Chapa</h1>
					</div>
					<div className="row">
						<div className="form-group col-md-6">
							<label className="required">Nome Completo</label>
							<input
								type="text"
								className={`form-control${errors.nome ? ' form-control-error' : ''}`}
								value={participante.nome}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								disabled={!!participanteLido.nome}
							/>
							{errors.nome && <ErrorMessages errorList={errors.nome} />}
						</div>
						<div className="form-group col-md-6">
							<label className="required">E-mail</label>
							<input
								type="text"
								className={`form-control${errors.email ? ' form-control-error' : ''}`}
								value={participante.email}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								disabled={!!participanteLido.email}
							/>
							{errors.email && <ErrorMessages errorList={errors.email} />}
						</div>
						<div className="form-group col-md-4">
							<label className="">Telefone</label>
							<InputMask
								className={`form-control${errors.telefone ? ' form-control-error' : ''}`}
								name="fone"
								value={participante.telefone || ''}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								autoComplete="off"
								mask={
									size(participante.telefone) >= 6 && participante.telefone.split('')[5] === '9'
										? '(99) 99999-9999'
										: '(99) 9999-9999'
								}
								alwaysShowMask={false}
								disabled={!!participanteLido.telefone}
							/>
							{errors.telefone && <ErrorMessages errorList={errors.telefone} />}
						</div>
						<div className="form-group col-md-4">
							<label className="required">Data de nascimento</label>
							<InputMask
								className={`form-control${errors.dataNascimento ? ' form-control-error' : ''}`}
								name="dataNascimento"
								value={participante.dataNascimento || ''}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								autoComplete="off"
								mask="99/99/9999"
								disabled={!!participanteLido.dataNascimento}
							/>
							{errors.dataNascimento && <ErrorMessages errorList={errors.dataNascimento} />}
						</div>
						<div className="form-group col-md-4">
							<label className="required">CPF</label>
							<InputMask
								className={`form-control${errors.cpf ? ' form-control-error' : ''}`}
								name="cpf"
								value={participante.cpf || ''}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								autoComplete="off"
								mask="999.999.999-99"
								disabled={!!participanteLido.cpf}
							/>
							{errors.cpf && <ErrorMessages errorList={errors.cpf} />}
						</div>
						<div className="form-group col-md-4">
							<label className="required">RG</label>
							<input
								type="text"
								className={`form-control${errors.rg ? ' form-control-error' : ''}`}
								name="rg"
								value={participante.rg || ''}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								autoComplete="off"
								disabled={!!participanteLido.rg}
							/>
							{errors.rg && <ErrorMessages errorList={errors.rg} />}
						</div>
						<div className="form-group col-md-4">
							<label className="required">Data de Expedição</label>
							<InputMask
								className={`form-control${errors.rgExpedicao ? ' form-control-error' : ''}`}
								name="rgExpedicao"
								value={participante.rgExpedicao || ''}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								autoComplete="off"
								mask="99/99/9999"
								disabled={!!participanteLido.rgExpedicao}
							/>
							{errors.nome && <ErrorMessages errorList={errors.nome} />}
						</div>
						<div className="form-group col-md-4">
							<label className="required">Órgão Emissor</label>
							<input
								type="text"
								name="rgOrgaoEmissor"
								className={`form-control${errors.rgOrgaoEmissor ? ' form-control-error' : ''}`}
								value={participante.rgOrgaoEmissor || ''}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								disabled={!!participanteLido.rgOrgaoEmissor}
							/>
							{errors.rgOrgaoEmissor && <ErrorMessages errorList={errors.rgOrgaoEmissor} />}
						</div>
					</div>

					<label className="h4">Endereço</label>
					<CdlField
						title="Endereço"
						label={['Logradouro', 'Número']}
						required={true}
						name="endereco"
						value={participante.endereco}
						errorList={errors.endereco}
						onChangeHandler={changeHandler(participante)}
						disabled={!!participanteLido.endereco}
					/>
					<div className="row">
						<div className="form-group col-md-5 col-lg-4">
							<label>Complemento</label>
							<input
								type="text"
								className={`form-control${errors.complemento ? ' form-control-error' : ''}`}
								name="complemento"
								value={participante.complemento || ''}
								onChange={changeHandler(participante)}
								onKeyDown={e => e.key === 'Enter' && submeteForm()}
								autoComplete="off"
								disabled={!!participanteLido.complemento}
							/>
							{errors.complemento && <ErrorMessages errorList={errors.complemento} />}
						</div>
						<div className="form-group col-md-7 col-lg-8">
							<label className="control-label required">Região</label>
							<Selecao
								className={`form-control${errors.regiao ? ' form-control-error' : ''}`}
								selected={participante.regiao}
								label={'Região'}
								detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
								detailCodigo={''}
								detailDescricao={'nome'}
								autoShowList={false}
								searchTerm={regiaoSearchTerm}
								searchList={regioes}
								searchTermMinLength={0}
								errorList={errors.regiao}
								onChangeSearchTerm={e => {
									setRegiaoSearchTerm(e.target.value);
								}}
								onBlurSearchTerm={() => false}
								onSelectItem={item => () => changeHandler(participante)({ target: { name: 'regiao', value: item } })}
								onUnselect={() => () => changeHandler(participante)({ target: { name: 'regiao', value: null } })}
								noResetList={true}
								loading={loadingRegiao}
								maxDescricaoLength={80}
							/>
						</div>
					</div>
					<div className="row">
						<div className="form-group col-md-12">
							<label className="control-label required">Chapas</label>
							<Selecao
								className={`form-control${errors.chapaSelecionada ? ' form-control-error' : ''}`}
								selected={chapaSelecionada}
								label={'Chapa Selecionada'}
								detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
								detailCodigo={''}
								templateDescricao={'RGP {{regiao.numero}} Chapa {{numero}}'}
								autoShowList={false}
								searchTerm={chapaSearchTerm}
								searchList={chapas || []}
								searchTermMinLength={0}
								errorList={errors.chapaSelecionada}
								onChangeSearchTerm={e => {
									setChapaSearchTerm(e.target.value);
								}}
								onBlurSearchTerm={() => false}
								onSelectItem={item => () => setChapaSelecionada(item)}
								onUnselect={() => () => setChapaSelecionada(null)}
								noResetList={true}
								loading={loadingChapas}
								readOnly={!participante.regiao}
								maxDescricaoLength={80}
								orderBy={'asc'}
							/>
							{!participante?.endereco && (
								<small>{'Não é possível alterar a região, pois o endereço não foi informado'}</small>
							)}
						</div>{' '}
					</div>
					{chapaSelecionada && (
						<>
							{/* TODO */}
							<div className="row">
								<div className="col-md-4 mb-3">
									{chapaSelecionada.titular ? (
										<Problemas
											tipo="Titular"
											nome={chapaSelecionada.titular.nome}
											problemas={problemas}
											setProblemas={setProblemas}
											tipoProp="titular"
										/>
									) : (
										<spa>Sem Titular</spa>
									)}
								</div>
								<div className="col-md-4">
									{chapaSelecionada.suplente1 ? (
										<Problemas
											tipo="Primeiro Suplente"
											nome={chapaSelecionada.suplente1.nome}
											problemas={problemas}
											setProblemas={setProblemas}
											tipoProp="suplente1"
										/>
									) : (
										<span>Sem Primeiro Suplente</span>
									)}
								</div>
								<div className="col-md-4">
									{chapaSelecionada.suplente2 ? (
										<Problemas
											tipo="Segundo Suplente"
											nome={chapaSelecionada.suplente2.nome}
											problemas={problemas}
											setProblemas={setProblemas}
											tipoProp="suplente2"
										/>
									) : (
										<span>Sem Segundo Suplente</span>
									)}
								</div>
							</div>
							{errors.problemas && <ErrorMessages errorList={errors.problemas} />}
						</>
					)}
					{chapaSelecionada && (
						<div className="row">
							<div className="form-group col-12">
								<label className="required">Juntada de Provas para Impugnação</label>
								<FileUpload
									value={provasImpugnacao?.provasImpugnacao ? [provasImpugnacao.provasImpugnacao] : null}
									updateFilesCb={files =>
										changeHandlerProvasImpugnacao({
											target: {
												name: 'provasImpugnacao',
												value: files[0] || null
											}
										})
									}
									maxFileSizeInBytes={5 * 1000 * 1000}
									maxFiles={1}
								/>
								{errors.provasImpugnacao && <ErrorMessages errorList={errors.provasImpugnacao} />}
							</div>
							<div className="form-group col-12">
								<label className="required">Explicação</label>
								<textarea
									className={`form-control${errors.mensagem ? ' form-control-error' : ''}`}
									value={mensagem}
									rows="12"
									onChange={e => setMensagem(e.target.value)}
								/>
								{errors.mensagem && <ErrorMessages errorList={errors.mensagem} />}
							</div>
						</div>
					)}
					{errors.ataEleicaoDiretoria && <ErrorMessages errorList={errors.ataEleicaoDiretoria} />}
					{errors.send && <ErrorMessages errorList={errors.send} />}
					<div className="buttons">
						<button type="button" className="btn btn-outline-danger" onClick={() => history.push('/publica')}>
							<i className="fa fa-times" aria-hidden="true"></i>Cancelar
						</button>
						{chapaSelecionada && (
							<button type="button" className="btn btn-primary" onClick={submeteForm}>
								<i className="fa fa-save" aria-hidden="true"></i>Enviar Mensagem
							</button>
						)}
					</div>
					{size(errors) > 0 && (
						<div>
							<ErrorMessages errorList={['Há erros no preenchimento do formulário. Verifique acima.']} />
						</div>
					)}
					{size(showPopupMessage) > 0 && (
						<ModalMessage showPopupMessage={showPopupMessage} hidePopupMessage={hidePopupMessage} />
					)}
				</form>
			) : isForaPeriodoImpugnacao ? (
				<>
					<h2>Encerrado período de impugnação de chapas e delegados</h2>
					<p>
						Acompanhe a liberação dos resultados no &nbsp;
						<a href="https://www2.portoalegre.rs.gov.br/dopa/">Diário Oficial de Porto Alegre.</a>
					</p>
					<button type="button" className="btn btn-outline-success" onClick={() => history.push('/publica')}>
						<i className="fa fa-arrow-left" aria-hidden="true"></i>Voltar
					</button>
				</>
			) : (
				<>
					<h1>Vocês precisa estar logado para solicitar a impugnação de uma chapa</h1>
					<div className="buttons">
						<button type="button" className="btn btn-outline-danger" onClick={() => history.push('/publica')}>
							<i className="fa fa-times" aria-hidden="true"></i>Cancelar
						</button>
					</div>
				</>
			)}
		</div>
	) : (
		<Loader msg="Carregando dados do usuário logado ..." />
	);
}
SolicitacaoImpugnacaoChapa.displayName = 'SolicitacaoImpugnacaoChapa';

export default SolicitacaoImpugnacaoChapa;

function Problemas({ nome, problemas, setProblemas, tipoProp }) {
	return (
		<>
			<label className="h4">{problemas[tipoProp].label}</label>
			<strong>{nome}</strong>
			{probsProp.map(problema => (
				<Fragment key={`${tipoProp}.${problema}`}>
					<div className="">
						<div className="checkbox-input">
							<input
								type="checkbox"
								value={problemas[tipoProp].problemas[problema].value || false}
								checked={problemas[tipoProp].problemas[problema].value || false}
								onChange={() => null}
								onClick={() =>
									setProblemas(old => ({
										...old,
										[tipoProp]: {
											...old[tipoProp],
											problemas: {
												...old[tipoProp].problemas,
												[problema]: {
													...old[tipoProp].problemas[problema],
													value: !old[tipoProp].problemas[problema].value
												}
											}
										}
									}))
								}
							/>
							<label
								className="problemas"
								onClick={() =>
									setProblemas(old => ({
										...old,
										[tipoProp]: {
											...old[tipoProp],
											problemas: {
												...old[tipoProp].problemas,
												[problema]: {
													...old[tipoProp].problemas[problema],
													value: !old[tipoProp].problemas[problema].value
												}
											}
										}
									}))
								}
							>
								{problemas[tipoProp].problemas[problema].label}
							</label>
						</div>
					</div>
				</Fragment>
			))}
		</>
	);
}
Problemas.displayName = 'Problema';
Problemas.propTypes = {
	nome: PropTypes.string,
	tipoProp: PropTypes.string,
	problemas: PropTypes.object,
	setProblemas: PropTypes.func
};
