import React from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { titleCase } from '../utils/tools';

const ListItem = ({ innerClassName, codigo, descricao, data, icon, modifier = titleCase }) =>
	descricao ? (
		<span className={innerClassName ? innerClassName : ''}>
			{icon && <i className={`fa fa-${icon}`} aria-hidden="true" />}
			{codigo && <span className="id">{codigo} -</span>}
			{modifier(descricao)}
			{data && <span className="date"> ({moment(data).format('DD/MM/YYYY')})</span>}
		</span>
	) : (
		<strong>Para usar o componente ListItem, no mínimo, deve ser informada a descrição</strong>
	);

ListItem.displayName = 'ListItem';

ListItem.propTypes = {
	innerClassName: PropTypes.string,
	codigo: PropTypes.string,
	descricao: PropTypes.string,
	data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	icon: PropTypes.string,
	modifier: PropTypes.func
};

export default ListItem;
