import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { get, size } from 'lodash';

function SearchBar({ searchRef, onSubmit, placeholder, searchTermSelector, setSearchTerm }) {
	const dispatch = useDispatch();
	const searchTerm = useSelector(state => get(state, searchTermSelector));
	const [delayedSearchTerm, setDelayedSearchTerm] = useState(searchTerm);
	const [loading, setLoading] = useState(false);

	// useEffect(() => {
	// 	let timer = null;
	// 	if (searchTerm !== termo) {
	// 		if (timer) {
	// 			clearTimeout(timer);
	// 		}
	// 		timer = setTimeout(() => {
	// 			onClick(searchTerm);
	// 		}, 500);
	// 	}

	// 	return () => {
	// 		if (timer) {
	// 			clearTimeout(timer);
	// 		}
	// 	};
	// }, [searchTerm, termo]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			dispatch(setSearchTerm(delayedSearchTerm));
			setLoading(false);
		}, 1500);

		return () => clearTimeout(delayDebounceFn);
	}, [delayedSearchTerm, dispatch, setSearchTerm]);

	return (
		<div className="row pesquisa">
			<div className="form-group col-lg-10 col-xl-8">
				<div className="input-group">
					<input
						ref={searchRef}
						type="text"
						className="form-control"
						name="searchTerm"
						value={delayedSearchTerm || ''}
						onChange={e => setDelayedSearchTerm(e.target.value)}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								onSubmit(delayedSearchTerm);
							}
							if (e.key === 'Escape') {
								setDelayedSearchTerm('');
								searchRef.current.focus();
							}
							return true;
						}}
						autoComplete="off"
						placeholder={placeholder}
					/>
					{size(delayedSearchTerm) > 0 && (
						<button
							type="button"
							className="btn btn-outline-danger icon-only"
							onClick={() => {
								setDelayedSearchTerm('');
								onSubmit('');
								searchRef.current.focus();
							}}
						>
							<i className="fa fa-times" aria-hidden="true" />
							<span className="sr-only">Limpar</span>
						</button>
					)}

					<button
						type="button"
						className="btn btn-outline-primary icon-only"
						onClick={() => onSubmit(delayedSearchTerm)}
					>
						{loading ? (
							<i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
						) : (
							<i className="fa fa-search" aria-hidden="true"></i>
						)}
						&nbsp;Pesquisar
					</button>
				</div>
			</div>
		</div>
	);
}
SearchBar.displayName = 'SearchBar';
SearchBar.propTypes = {
	searchRef: PropTypes.any,
	placeholder: PropTypes.string,
	searchTermSelector: PropTypes.string,
	setSearchTerm: PropTypes.func,
	onSubmit: PropTypes.func
};

export default SearchBar;
