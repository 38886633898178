import React from 'react';

import PropTypes from 'prop-types';

import AnaliseParticipanteComponent from './analiseParticipanteComponent';

function AnaliseDelegadoComponent({ delegado }) {
	return delegado ? (
		<>
			<AnaliseParticipanteComponent participante={delegado.delegado} />
			{delegado.situacao && delegado.motivoImpugnacao && (
				<div className="row">
					<div className="col-12">
						<label htmlFor="">Motivo da Impugnação</label>
						<p>{delegado?.motivoImpugnacao}</p>
					</div>
				</div>
			)}
		</>
	) : null;
}
AnaliseDelegadoComponent.displayName = 'AnaliseDelegadoComponent';
AnaliseDelegadoComponent.propTypes = {
	delegado: PropTypes.object
};

export default AnaliseDelegadoComponent;
