import { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';

import gql from 'graphql-tag';
import { size } from 'lodash';
import moment from 'moment';

import { setCalendario as setCalendarioStore } from '../Redux/Home/homeSlice';

function useCalendario() {
	const dispatch = useDispatch();
	const calendarioStore = useSelector(state => state.home.calendario);
	const [calendario, setCalendario] = useState(calendarioStore);
	const [isSetCalendarioMoment, setIsSetCalendarioMoment] = useState(false);

	const [getCalendar, { data, loading, errors }] = useLazyQuery(
		gql`
			query ListaCalendario($term: String, $skip: Int, $limit: Int) {
				list: ECmduaCalendarioList(term: $term, skip: $skip, limit: $limit) {
					id
					calendario
					ativo
					iniInscricoesChapasDelegados
					fimInscricoesChapasDelegados
					publicacaoInscricaoChapasDelegados
					iniImpugnacaoChapasDelegados
					fimImpugnacaoChapasDelegados
					publicacaoImpugnacoesAntesRecursos
					iniRecursosImpugnacaoChapasDelegados
					fimRecursosImpugnacaoChapasDelegados
					publicacaoImpugnacoesAposRecursos
					iniPeriodoEleitoral
					fimPeriodoEleitoral
					publicacaoEleicao
					iniInscricoesEntidades
					fimInscricoesEntidades
					publicacaoInscricaoEntidades
					iniImpugnacaoEntidades
					fimImpugnacaoEntidades
					publicacaoImpugnacoesEntidadesAntesRecursos
					iniRecursosImpugnacaoEntidades
					fimRecursosImpugnacaoEntidades
					publicacaoImpugnacoesEntidadesAposRecursos
					reuniaoEleicaoEntidades
				}
			}
		`,
		{
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		if (!calendario) {
			getCalendar({
				variables: {
					term: JSON.stringify({
						ativo: true,
						sort: { calendario: -1 }
					}),
					limit: 1
				}
			});
			setIsSetCalendarioMoment(false);
		}
	}, [calendario, getCalendar]);

	useEffect(() => {
		if (data && size(data.list) > 0) {
			const cal = data.list[0];
			setCalendario(cal);
			dispatch(setCalendarioStore(cal));
		}
	}, [data, dispatch]);

	useEffect(() => {
		if (calendario && !calendario.iniInscricoesChapasDelegadosMoment) {
			setCalendario(cal => {
				let newCal = { ...cal };

				// adiciona propriedades de datas já como moment
				Object.keys(cal).forEach(key => {
					if (!['_id', 'ativo'].includes(key)) {
						const newKey = `${key}Moment`;
						newCal[newKey] = moment(cal[key], 'DD-MM-YYYY');
					}
				});

				return newCal;
			});
			setIsSetCalendarioMoment(true);
		}
	}, [calendario]);

	const isAfter = useCallback(
		(nome, endOfDay = false) => {
			let resultado = false;
			if (size(calendario) > 0 && calendario.calendarioMoment && size(nome) > 0) {
				const isEndOfDay = endOfDay || nome.startsWith('fim');
				const data = calendario?.[`${nome}Moment`];
				const isAfter = moment().isAfter(isEndOfDay && data ? data.endOf('day') : data);
				resultado = isAfter;
			}
			return resultado;
		},
		[calendario]
	);

	const isBetween = useCallback(
		(nomeInicio, nomeFim, endOfDay = false) => {
			let resultado = false;
			if (size(calendario) > 0 && calendario.calendarioMoment && size(nomeInicio) > 0 && size(nomeFim) > 0) {
				const isInicioEndOfDay = endOfDay || nomeInicio.startsWith('fim');
				const isFimEndOfDay = endOfDay || nomeFim.startsWith('fim');
				let dataInicio = calendario?.[`${nomeInicio}Moment`];
				let dataFim = calendario?.[`${nomeFim}Moment`];
				dataInicio = isInicioEndOfDay && dataInicio ? dataInicio.endOf('day') : dataInicio;
				dataFim = isFimEndOfDay && dataFim ? dataFim.endOf('day') : dataFim;
				const isBetween = moment().isBetween(dataInicio, dataFim, 'minute', '[]'); // precisão de minutos e inclusiva
				resultado = isBetween;
			}
			return resultado;
		},
		[calendario]
	);

	return {
		calendario,
		loading: loading && isSetCalendarioMoment,
		error: errors || (!!data && size(data.list) === 0),
		isAfter,
		isBetween
	};
}

export default useCalendario;
