import React from 'react';

import PropTypes from 'prop-types';

function AnaliseParticipanteComponent({ participante, titulo }) {
	return participante ? (
		<>
			<div className="info-participante form-group col-md-4">
				{titulo && <h2 className="h4">{titulo}</h2>}
				<p>
					<span>Nome: </span>
					<strong>{participante.nome}</strong>
				</p>
				{participante.dataNascimento && (
					<p>
						<span>Idade: </span>
						<strong>{participante.idade} </strong>
						<small>(nascido em {participante.dataNascimento})</small>
					</p>
				)}
				{participante.endereco && (
					<p>
						<span>Endereço: </span>
						<strong>{participante.endereco.enderecoFormatadoCurto}</strong>
						{participante.comprovanteResidencia?.link && (
							<>
								<br />
								<a href={participante.comprovanteResidencia.link.replace(/\?.*/, '')}>Comprovante de Residência</a>
							</>
						)}
						{participante.declaracaoTitularConta?.link && (
							<>
								<br />
								<a href={participante.declaracaoTitularConta.link.replace(/\?.*/, '')}>
									Declaração do titular da conta
								</a>
							</>
						)}
					</p>
				)}
				<p>
					<span>CPF: </span>
					<strong>{participante.cpf}</strong>
					{participante.comprovanteCpf?.link && (
						<>
							<br />
							<a href={participante.comprovanteCpf.link.replace(/\?.*/, '')}>Comprovante de CPF</a>
						</>
					)}
				</p>
			</div>
		</>
	) : null;
}
AnaliseParticipanteComponent.displayName = 'AnaliseParticipanteComponent';
AnaliseParticipanteComponent.propTypes = {
	participante: PropTypes.object,
	titulo: PropTypes.string
};

export default AnaliseParticipanteComponent;
