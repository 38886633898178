import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import gql from 'graphql-tag';
import { isNil, size, trim } from 'lodash';
import uuid from 'uuid/v4';

import Selecao from '../components/Selecao';
import { REGEX_NUMBERS_ONLY } from '../utils/constants';
import apolloClient from '../utils/graphql';
import { normalizedAddress, permiteInput } from '../utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';
const CLASS_NAME_FORM_CONTROL_CHANGED = 'form-control-changed';

/* eslint-disable react-hooks/exhaustive-deps */
const CdlField = ({
	title,
	label = ['Logradouro', 'Número'],
	required,
	placeHolder = ['Nome do logradouro', 'Número do imóvel'],
	name,
	value,
	readOnly,
	disabled,
	errorList,
	onChangeHandler,
	...otherProps
}) => {
	const [loading, setLoading] = useState(false);
	const [timer, setTimer] = useState(null);
	const [timer2, setTimer2] = useState(null);
	const [logradourosPesquisados, setLogradourosPesquisados] = useState([]);
	const [logradouro, setLogradouro] = useState(null);
	const [numero, setNumero] = useState(null);
	const [validar, setValidar] = useState(false);
	const [formTouched, setFormTouched] = useState(false);
	const searchTermRef = useRef();
	const numeroRef = useRef();

	useEffect(
		() => () => {
			if (timer) {
				clearTimeout(timer);
			}
			if (timer2) {
				clearTimeout(timer2);
			}
		},
		[]
	);

	useEffect(() => {
		if (value) {
			setValidar(true);
			if (!logradouro) {
				setLogradouro(value.nomeLogradouro);
			}
			if (!numero) {
				setNumero(value.numero);
			}
		}
	}, [value]);

	useEffect(() => {
		let isMounted = true;
		if (timer) {
			clearTimeout(timer);
		}
		setTimer(
			setTimeout(async () => {
				const enderecosCdl = await buscaEnderecosCdl(logradouro);
				if (isMounted) setLogradourosPesquisados(enderecosCdl);
			}, 500)
		);
		return () => {
			isMounted = false;
		};
	}, [logradouro]);

	useEffect(() => {
		if (timer2) {
			clearTimeout(timer2);
		}
		setTimer2(
			setTimeout(async () => {
				if (!isNil(numero)) {
					const enderecoCdl = await obtemEndereco(value, numero);
					sendRetorno(enderecoCdl);
				}
			}, 300)
		);
	}, [numero]);

	const setCDLHandler = cdl => async () => {
		setLogradourosPesquisados([]);
		const enderecoCdl = await obtemEndereco(cdl, numero);
		sendRetorno(enderecoCdl);
		if (numeroRef && numeroRef.current) {
			numeroRef.current.focus();
		}
	};

	const unsetCDLHandler = () => async () => {
		setLogradouro(null);
		sendRetorno(null, true);
		setTimeout(() => {
			if (searchTermRef && searchTermRef.current) {
				searchTermRef.current.focus();
			}
		}, 300);
		if (logradouro) {
			const enderecosCdl = await buscaEnderecosCdl(logradouro);
			setLogradourosPesquisados(enderecosCdl);
		}
	};

	const setNumeroHandler = e => {
		const numAux = e.target.value;
		if (permiteInput(numAux, 'int')) {
			setNumero(numAux);
			setFormTouched(true);
		}
	};

	const sendRetorno = (cdl, force) => (formTouched || force) && onChangeHandler({ target: { name, value: cdl } });

	const buscaEnderecosCdl = async logradouro => {
		let saida = [];
		if (!value || (value && value.nomeLogradouro !== logradouro)) {
			try {
				const queryCDL = gql`
					query CDL($term: String) {
						list: CdlEnderecoList(term: $term) {
							id
							nomeLogradouro
							enderecoFormatadoCurto
						}
					}
				`;
				if (size(logradouro) > 2) {
					setLoading(true);
					const {
						data: { list }
					} = await apolloClient.query({
						query: queryCDL,
						variables: { term: logradouro },
						fetchPolicy: 'network-only'
					});
					saida = list;
					setLoading(false);
				}
			} catch (e) {
				setLoading(false);
				console.error('erro no servidor', e);
				// yield put(push('/serverUnavailable'));
			}
		}
		return saida;
	};

	const obtemEndereco = async (cdl, numero) => {
		let saida = null;
		if (size(cdl) > 0) {
			if (cdl.nomeLogradouro !== logradouro || `${cdl.numero}` !== `${numero}`) {
				setLoading(true);
				saida = await obtainCdl(cdl, numero);
				setLoading(false);
			} else {
				saida = cdl;
			}
		}
		return saida;
	};
	return (
		<div className="row">
			<div className="form-group col-md-8">
				<label className={required ? 'required control-label' : 'control-label'}>{label[0]}</label>
				<Selecao
					searchTermRef={searchTermRef}
					className={
						size(errorList) > 0 || (validar && size(validate(logradouro, numero, value, required, label, title)) > 0)
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					multiple={false}
					selected={value}
					label={''}
					detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
					detailCodigo={''}
					detailDescricao={'nomeLogradouro'}
					autoShowList={false}
					searchTerm={logradouro}
					searchList={logradourosPesquisados}
					searchTermMinLength={3}
					errorList={errorList}
					onChangeSearchTerm={e => {
						const val = e.target.value;
						if (permiteInput(val, 'text', 0, 100)) {
							setFormTouched(true);
							setLogradouro(val);
						}
					}}
					onBlurSearchTerm={() => false}
					onSelectItem={setCDLHandler}
					onUnselect={unsetCDLHandler}
					noResetList={true}
					loading={loading}
					required={required}
					placeholder={placeHolder[0]}
					readOnly={disabled || readOnly}
					{...otherProps}
				/>
			</div>
			<div className="form-group col-md-4">
				<label className={required ? 'required control-label' : 'control-label'}>{label[1]}</label>
				<div className="input-with-icon">
					<input
						ref={numeroRef}
						className={
							size(errorList) > 0 || (validar && size(validate(logradouro, numero, value, required, label, title)) > 0)
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL} ${
										value?.numeroOriginal && parseInt(value?.numero, 10) !== parseInt(value.numeroOriginal, 10)
											? ` ${CLASS_NAME_FORM_CONTROL_CHANGED}`
											: ''
								  }`
						}
						type="text"
						placeholder={placeHolder[1]}
						name={name}
						value={numero || ''}
						onChange={setNumeroHandler}
						readOnly={readOnly}
						disabled={disabled}
						{...otherProps}
					/>
					{loading && <i className="fa fa-refresh fa-spin btn" />}
				</div>
			</div>
		</div>
	);
};

CdlField.displayName = 'CdlField';

CdlField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.bool,
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.object,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func
};

export default CdlField;

const isValidLogradouro = logradouro => size(logradouro) === 0 || size(trim(normalizedAddress(logradouro))) > 0;
const isValidNumero = numero => size(numero) === 0 || REGEX_NUMBERS_ONLY.test(numero);

export const validate = (
	logradouro = '',
	numero,
	cdl,
	required,
	label = ['Logradouro', 'Número'],
	title = 'Endereço'
) => {
	const numeroAsString = `${numero ? numero : ''}`;
	const errors = [];
	if (!isValidLogradouro(logradouro)) {
		errors.push(`${label[0]} inválido. Ex: Av. Ipiranga`);
	}
	if (!isValidNumero(numeroAsString)) {
		errors.push(`${label[1]} inválido. Ex: 1200`);
	}
	if (required) {
		if (size(logradouro) === 0) {
			errors.push(`${label[0]} deve ser informado`);
		}
		if (size(numeroAsString) === 0 || numeroAsString === '0') {
			errors.push(`${label[1]} deve ser informado`);
		}
		if (size(cdl) === 0) {
			errors.push(`Você deve pesquisar e selecionar um ${title}`);
		}
	}
	return errors;
};

export async function obtainCdl(cdl, numero) {
	let saida = null;
	try {
		const queryCDL = gql`
			query byId($id: String!) {
				cdlLido: CdlEnderecoById(id: $id) {
					id
					nomeLogradouro
					nomeHistorico
					codigoBairro
					nomeBairro
					lado
					nomeBairroHistorico
					numero
					cep
					enderecoFormatado
					enderecoFormatadoCurto
				}
			}
		`;
		const id = { codLogradouro: cdl.codLogradouro || cdl.id, numero };
		const query = {
			query: queryCDL,
			variables: { id: JSON.stringify(id) },
			fetchPolicy: 'network-only'
		};
		const {
			data: { cdlLido }
		} = await apolloClient.query(query);
		saida = { ...cdlLido, codLogradouro: cdlLido.id, id: uuid() };
	} catch (e) {
		console.error('erro no servidor', e);
		// yield put(push('/serverUnavailable'));
	}
	return saida;
}
