import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-apollo';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { size, trim, omit, cloneDeep } from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v4';

import ErrorMessages from '../../components/ErrorMessages';
import FileUpload from '../../components/file-upload/file-upload.component';
import Loader from '../../components/Loader';
import ModalMessage from '../../components/ModalMessage';
import { ABA_ENTIDADES } from '../../components/Navigation';
import Navigation from '../../components/Navigation';
import Selecao from '../../components/Selecao';
import useCalendario from '../../customHooks/useCalendario';
import useMessages from '../../customHooks/useMessages';
import { limpaStore, removeEntidade, saveEntidade, setCurrent, setMessage } from '../../Redux/entidade/entidadeSlice';
import { setAba } from '../../Redux/Home/homeSlice';
import { history } from '../../Redux/store';
import { getIdade, isCNPJ, isDebug, isEmail, isExternal } from '../../utils/tools';

const entidadeQuery = loader('./entidadeQuery.gql');

const DOCUMENTO_PROPS = [
	'estatutoSocial',
	'ataEleicaoDiretoria',
	'comprovanteEndereco',
	'cartaSindical',
	'comprovanteUmAnoPoa'
];

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[ENTIDADE ${i++}]:`, ...args);

function Entidade() {
	/* REDUX */
	const dispatch = useDispatch();
	const entidade = useSelector(state => state.entidade.current);
	const updating = useSelector(state => state.entidade.updating);
	const message = useSelector(state => state.entidade.message);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */
	const { createMessage } = useMessages();
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const { id } = useParams();
	const [errors, setErrors] = useState({});
	const [showPopupRemove, setShowPopupRemove] = useState(false);
	const [showPopupMessage, setShowPopupMessage] = useState(false);
	const [categorias, setCategorias] = useState([]);
	const [categoriaSearchTerm, setCategoriaSearchTerm] = useState('');
	const [participantes, setParticipantes] = useState([]);
	const [titularSearchTerm, setTitularSearchTerm] = useState('');
	const [suplente1SearchTerm, setSuplente1SearchTerm] = useState('');
	const [suplente2SearchTerm, setSuplente2SearchTerm] = useState('');

	const { data: dataEntidade, loading: loadingEntidade, errors: errorEntidade } = useQuery(entidadeQuery, {
		variables: { id },
		fetchPolicy: 'network-only'
	});

	const { data: dataCategoria, loading: loadingCategoria, errors: errorCategoria } = useQuery(
		gql`
			query ListaCategorias($term: String, $skip: Int, $limit: Int) {
				list: ECmduaCategoriaEntidadeList(term: $term, skip: $skip, limit: $limit) {
					id
					tipo
				}
				count: ECmduaCategoriaEntidadeCount
			}
		`,
		{
			variables: {},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const {
		data: dataParticipantes,
		loading: loadingParticipantes,
		errors: errorParticipantes,
		refetch: refetchParticipantes
	} = useQuery(
		gql`
			query ListaParticipantes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaParticipanteList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					email
					dataNascimento
				}
			}
		`,
		{
			variables: {
				skip: 0,
				limit: 2000,
				term: isExternal ? 'RECARREGA QUANDO TIVER O USERPROFILE' : ''
			},
			fetchPolicy: 'network-only'
			// nextFetchPolicy: 'cache-first'
		}
	);

	const onNavigateHandler = useCallback(() => {
		dispatch(limpaStore());
	}, [dispatch]);

	useEffect(() => {
		if (size(message) > 0) {
			debugLog('[message]: ', message);
			if (message.message) {
				setShowPopupMessage(message);
			} else {
				createMessage(message, 15);
			}
			dispatch(setMessage(null));
		}
	}, [createMessage, dispatch, message]);

	useEffect(() => {
		if (id === 'new' && !entidade?.id) {
			debugLog('[entidade, id]: ', entidade, id);
			dispatch(setCurrent({ id: uuid() }));
		}
	}, [entidade, dispatch, id]);

	useEffect(() => {
		if (isExternal && userProfile) {
			debugLog('[userProfile]: ', userProfile);
			const variables = {
				skip: 0,
				limit: 2000,
				term: JSON.stringify({
					usuarioCriador: userProfile.email
				})
			};
			refetchParticipantes(variables);
		}
	}, [userProfile, refetchParticipantes]);

	useEffect(() => {
		if (dataEntidade && id !== 'new') {
			debugLog('[dataEntidade, id]: ', dataEntidade, id);
			let entidadeAux = cloneDeep(dataEntidade.item);
			if (entidadeAux.titular?.dataNascimento) {
				entidadeAux.titular.idade = getIdade(entidadeAux.titular?.dataNascimento);
			}
			if (entidadeAux.suplente1?.dataNascimento) {
				entidadeAux.suplente1.idade = getIdade(entidadeAux.suplente1?.dataNascimento);
			}
			if (entidadeAux.suplente2?.dataNascimento) {
				entidadeAux.suplente2.idade = getIdade(entidadeAux.suplente2?.dataNascimento);
			}

			DOCUMENTO_PROPS.filter(prop => entidadeAux[prop]).forEach(prop => {
				entidadeAux[prop].docName = `${prop}-${entidadeAux.id}`;
			});

			// atualiza parte síncrona
			dispatch(setCurrent(entidadeAux));
		}
	}, [dataEntidade, dispatch, id]);

	useEffect(() => {
		if (dataParticipantes) {
			debugLog('[dataParticipantes, participantes]: ', dataParticipantes.list, participantes);
			if (size(dataParticipantes.list) !== size(participantes)) {
				setParticipantes(
					dataParticipantes.list
						.sort((a, b) => (a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1))
						.map(p => ({ ...p, idade: getIdade(p.dataNascimento) }))
				);
			}
		}
	}, [dataParticipantes, participantes]);

	useEffect(() => {
		if (dataCategoria) {
			debugLog('[dataCategoria]: ', dataCategoria);
			setCategorias(dataCategoria.list);
		}
	}, [dataCategoria]);

	const changeHandler = useCallback(
		e => {
			const target = Array.isArray(e.target) ? e.target : [e.target];
			let errorsToRemove = [];
			let update = { ...entidade };
			target.forEach(t => {
				const { name, value } = t;
				errorsToRemove.push(name);
				update = { ...update, [name]: value };
			});
			setErrors(old => omit(old, errorsToRemove) || {});
			dispatch(setCurrent({ ...update }));
		},
		[dispatch, entidade]
	);

	const valida = useCallback(async () => {
		let errors = {};
		if (size(trim(entidade.razaoSocial)) === 0) {
			errors.razaoSocial = ['Informe a razão social da entidade'];
		}
		if (size(trim(entidade.email)) === 0) {
			if (isExternal) {
				errors.email = ['Informe o e-mail'];
			}
		} else if (!isEmail(entidade.email)) {
			errors.email = ['E-mail inválido'];
		}
		if (size(trim(entidade.cnpj)) === 0) {
			errors.cnpj = ['Informe o CNPJ da entidade'];
		} else if (!isCNPJ(entidade.cnpj)) {
			errors.cnpj = ['CNPJ inválido'];
		}
		if (size(trim(entidade.categoria)) === 0) {
			errors.categoria = ['Selecione a categoria da entidade'];
		}
		if (size(entidade.titular) === 0) {
			// errors.titular = ['Selecione o titular'];
		} else if (entidade.titular.idade < 18) {
			errors.titular = ['O titular deve ter pelo menos 18 anos'];
		}
		if (size(entidade.suplente1) === 0) {
			// errors.suplente1 = ['Selecione o primeiro suplente'];
		} else if (entidade.suplente1.idade < 18) {
			errors.suplente1 = ['O primeiro suplente deve ter pelo menos 18 anos'];
		}
		if (size(entidade.suplente2) === 0) {
			// errors.suplente2 = ['Selecione o segundo suplente'];
		} else if (entidade.suplente2.idade < 18) {
			errors.suplente2 = ['O segundo suplente deve ter pelo menos 18 anos'];
		}
		if (isExternal) {
			if (size(trim(entidade.estatutoSocial)) === 0) {
				errors.estatutoSocial = ['O estatuto social deve ser fornecido'];
			}
			if (size(trim(entidade.ataEleicaoDiretoria)) === 0) {
				errors.ataEleicaoDiretoria = ['A ata de eleição da atual diretoria deve ser fornecida'];
			}
			if (size(trim(entidade.comprovanteEndereco)) === 0) {
				errors.comprovanteEndereco = ['O comprovante de endereço deve ser fornecido'];
			}
		}
		if (!calendario || isAfter('fimInscricoesChapasDelegados')) {
			errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
		}
		return errors;
	}, [calendario, entidade, isAfter]);

	const submeteForm = useCallback(async () => {
		const errors = await valida();
		let origem = 'edicao';
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			let entidadeAux = cloneDeep(entidade);

			DOCUMENTO_PROPS.filter(prop => entidade[prop] && !entidade[prop].docName).forEach(prop => {
				entidadeAux[prop].docName = `${prop}-${entidadeAux.id}`;
			});

			if (isExternal && !entidadeAux.usuarioCriador) {
				entidadeAux.usuarioCriador = userProfile.email;
			}

			if (!entidadeAux.calendario) {
				entidadeAux.calendario = calendario.calendario;
			}

			entidadeAux.nomeConsolidado = entidadeAux.nomeFantasia || entidadeAux.razaoSocial;

			if (id === 'new') {
				// apenas para transporte... não será gravado no banco
				origem = 'cadastro';
				entidadeAux.enviarEmail = true;
			}
			// apenas para transporte
			entidadeAux.origem = origem;

			dispatch(saveEntidade(entidadeAux));
		}
	}, [valida, entidade, dispatch, userProfile, id, calendario]);

	const cancelar = useCallback(() => {
		dispatch(limpaStore());
		history.go(-1);
	}, [dispatch]);

	const remover = useCallback(() => {
		let errors = {};
		if (calendario && !isAfter('fimInscricoesChapasDelegados')) {
			dispatch(removeEntidade(entidade.id));
		} else {
			errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
			setErrors(errors);
		}
		setShowPopupRemove(false);
	}, [calendario, dispatch, entidade, isAfter]);

	// eslint-disable-next-line
	const hidePopupMessage = useCallback(() => {
		if (showPopupMessage.type === 'success') {
			setShowPopupMessage(false);
			dispatch(limpaStore());
			dispatch(setAba(ABA_ENTIDADES));
			if (window.location.pathname !== '/home') {
				history.push('/home');
			}
		} else {
			setShowPopupMessage(false);
		}
	});

	const isForaPeriodoInscricao = useMemo(
		() =>
			!isDebug &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimInscricoesChapasDelegados')),
		[calendario, isAfter]
	);

	const readOnly = useMemo(() => {
		const criadoPeloCidadao = !isExternal && !!entidade?.usuarioCriador;
		return criadoPeloCidadao || isForaPeriodoInscricao;
	}, [entidade, isForaPeriodoInscricao]);

	return loadingEntidade || loadingParticipantes || loadingCalendario ? (
		<Loader msg="Carregando dados da entidade..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendario']} />
	) : errorEntidade ? (
		<ErrorMessages errorList={['Problemas ao carregar entidade']} />
	) : errorParticipantes ? (
		<ErrorMessages errorList={['Problemas ao carregar participantes']} />
	) : errorCategoria ? (
		<ErrorMessages errorList={['Problemas ao carregar categorias']} />
	) : (
		<>
			{updating && <Loader msg="Salvando entidade..." />}
			<div className="container">
				<Navigation onNavigate={onNavigateHandler} />
				<h1>{id === 'new' ? 'Nova' : readOnly ? 'Consulta de' : 'Edição de'} Entidade</h1>
				{readOnly && (
					<ErrorMessages
						errorList={[
							{
								msg: ` edição desabilitada ${
									isForaPeriodoInscricao
										? 'pois o período de inscrições está encerrado'
										: `pois esta entidade foi criada no portal externo por ${entidade?.usuarioCriador}`
								}`,
								type: 'warning'
							}
						]}
					/>
				)}
				{entidade && (
					<form autoComplete="off">
						<fieldset>
							<legend>Identificação da Entidade</legend>
							<div className="row">
								<div className="form-group col-lg-6">
									<label className="required">Razão Social</label>
									<input
										type="text"
										className={`form-control${errors.razaoSocial ? ' form-control-error' : ''}`}
										name="razaoSocial"
										value={entidade.razaoSocial || ''}
										onChange={changeHandler}
										onKeyDown={e => e.key === 'Enter' && e.submeteForm()}
										autoComplete="off"
										disabled={readOnly}
									/>
									{errors.razaoSocial && <ErrorMessages errorList={errors.razaoSocial} />}
								</div>
								<div className="form-group col-lg-6">
									<label>Nome Fantasia</label>
									<input
										type="text"
										className={`form-control${errors.nomeFantasia ? ' form-control-error' : ''}`}
										name="nomeFantasia"
										value={entidade.nomeFantasia || ''}
										onChange={changeHandler}
										onKeyDown={e => e.key === 'Enter' && e.submeteForm()}
										autoComplete="off"
										disabled={readOnly}
									/>
									{errors.nomeFantasia && <ErrorMessages errorList={errors.nomeFantasia} />}
								</div>
							</div>
							<div className="row">
								<div className="form-group col-md-4 col-lg-3">
									<label className="required">CNPJ</label>
									<InputMask
										className={`form-control${errors.cnpj ? ' form-control-error' : ''}`}
										name="cnpj"
										value={entidade.cnpj || ''}
										onChange={changeHandler}
										onKeyDown={e => e.key === 'Enter' && e.submeteForm()}
										autoComplete="off"
										mask="99.999.999/9999-99"
										disabled={readOnly}
									/>
									{errors.cnpj && <ErrorMessages errorList={errors.cnpj} />}{' '}
								</div>
								<div className="form-group col-md-8 col-lg-5">
									<label className="required">E-mail</label>
									<input
										type="text"
										className={`form-control${errors.email ? ' form-control-error' : ''}`}
										name="email"
										value={entidade.email || ''}
										onChange={changeHandler}
										onKeyDown={e => e.key === 'Enter' && e.submeteForm()}
										autoComplete="off"
										disabled={readOnly}
									/>
									{errors.email && <ErrorMessages errorList={errors.email} />}
								</div>
								<div className="form-group col-lg-4">
									<label className="required">Categoria</label>
									<Selecao
										className={`form-control${errors.categoria ? ' form-control-error' : ''}`}
										selected={entidade.categoria}
										label={'categoria'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										detailDescricao={'tipo'}
										autoShowList={false}
										searchTerm={categoriaSearchTerm}
										searchList={categorias.filter(r =>
											r.tipo.toLowerCase().includes(categoriaSearchTerm.toLowerCase())
										)}
										searchTermMinLength={0}
										errorList={errors.categoria}
										onChangeSearchTerm={e => {
											setCategoriaSearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'categoria', value: item } })}
										onUnselect={() => () => {
											setParticipantes([]);
											changeHandler({ target: { name: 'categoria', value: null } });
										}}
										noResetList={true}
										loading={loadingCategoria}
										placeholder={'Selecione uma categoria'}
										readOnly={readOnly}
									/>
								</div>
							</div>
						</fieldset>
						<fieldset>
							<div className="titulo-pagina">
								<legend>Integrantes</legend>
								{calendario && !isAfter('fimInscricoesChapasDelegados') && !readOnly && (
									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={() => history.push('/participante/new')}
									>
										<i className="fa fa-plus" aria-hidden="true"></i>Novo Participante
									</button>
								)}
							</div>
							<div className="row">
								<div className="form-group col-12">
									<label>Titular</label>
									<Selecao
										className={`form-control${errors.titular ? ' form-control-error' : ''}`}
										selected={entidade.titular}
										label={'titular'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										templateDescricao={entidade?.titular?.dataNascimento ? '{{nome}} de {{idade}} anos' : '{{nome}}'}
										detailModifier={text => text}
										maxDescricaoLength={100}
										autoShowList={false}
										searchTerm={titularSearchTerm}
										searchList={participantes
											.filter(p => ![entidade.suplente1?.id, entidade.suplente2?.id].includes(p.id))
											.filter(p => p.nome.toLowerCase().indexOf(titularSearchTerm.toLowerCase()) > -1)}
										searchTermMinLength={0}
										errorList={errors.titular}
										onChangeSearchTerm={e => {
											setTitularSearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'titular', value: item } })}
										onUnselect={() => () => changeHandler({ target: [{ name: 'titular', value: null }] })}
										noResetList={true}
										loading={loadingParticipantes}
										placeholder={'Selecione o titular'}
										readOnly={readOnly}
									/>
								</div>
							</div>
							<div className="row">
								<div className="form-group col-lg-6">
									<label>Primeiro Suplente</label>
									<Selecao
										className={`form-control${errors.suplente1 ? ' form-control-error' : ''}`}
										selected={entidade.suplente1}
										label={'suplente1'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										templateDescricao={entidade?.suplente1?.dataNascimento ? '{{nome}} de {{idade}} anos' : '{{nome}}'}
										detailModifier={text => text}
										maxDescricaoLength={100}
										autoShowList={false}
										searchTerm={suplente1SearchTerm}
										searchList={participantes
											.filter(p => ![entidade.titular?.id, entidade.suplente2?.id].includes(p.id))
											.filter(p => p.nome.toLowerCase().indexOf(suplente1SearchTerm.toLowerCase()) > -1)}
										searchTermMinLength={0}
										errorList={errors.suplente1}
										onChangeSearchTerm={e => {
											setSuplente1SearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'suplente1', value: item } })}
										onUnselect={() => () => changeHandler({ target: [{ name: 'suplente1', value: null }] })}
										noResetList={true}
										loading={loadingParticipantes}
										placeholder={'Selecione o primeiro suplente'}
										readOnly={readOnly}
									/>
								</div>
								<div className="form-group col-lg-6">
									<label>Segundo Suplente</label>
									<Selecao
										className={`form-control${errors.suplente2 ? ' form-control-error' : ''}`}
										selected={entidade.suplente2}
										label={'suplente2'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										templateDescricao={entidade?.suplente2?.dataNascimento ? '{{nome}} de {{idade}} anos' : '{{nome}}'}
										detailModifier={text => text}
										maxDescricaoLength={100}
										autoShowList={false}
										searchTerm={suplente2SearchTerm}
										searchList={participantes
											.filter(p => ![entidade.suplente1?.id, entidade.titular?.id].includes(p.id))
											.filter(p => p.nome.toLowerCase().indexOf(suplente2SearchTerm.toLowerCase()) > -1)}
										searchTermMinLength={0}
										errorList={errors.suplente2}
										onChangeSearchTerm={e => {
											setSuplente2SearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'suplente2', value: item } })}
										onUnselect={() => () => changeHandler({ target: [{ name: 'suplente2', value: null }] })}
										noResetList={true}
										loading={loadingParticipantes}
										placeholder={'Selecione o segundo suplente'}
										readOnly={readOnly}
									/>
								</div>
							</div>
						</fieldset>
						<fieldset>
							<legend>Documentos</legend>

							<div className="row">
								<div className="form-group col-md-6 col-lg-4">
									<label className="required">Estatuto Social</label>
									<FileUpload
										value={entidade.estatutoSocial ? [entidade.estatutoSocial] : null}
										updateFilesCb={files =>
											changeHandler({
												target: {
													name: 'estatutoSocial',
													value: files[0] || null
												}
											})
										}
										disabled={readOnly}
									/>
									{errors.estatutoSocial && <ErrorMessages errorList={errors.estatutoSocial} />}
								</div>
								<div className="form-group col-md-6 col-lg-4">
									<label className="required">Ata da eleição da atual diretoria</label>
									<FileUpload
										value={entidade.ataEleicaoDiretoria ? [entidade.ataEleicaoDiretoria] : null}
										updateFilesCb={files =>
											changeHandler({
												target: {
													name: 'ataEleicaoDiretoria',
													value: files[0] || null
												}
											})
										}
										disabled={readOnly}
									/>
									{errors.ataEleicaoDiretoria && <ErrorMessages errorList={errors.ataEleicaoDiretoria} />}
								</div>
								<div className="form-group col-md-6 col-lg-4">
									<label className="required">Comprovante de endereço</label>
									<FileUpload
										value={entidade.comprovanteEndereco ? [entidade.comprovanteEndereco] : null}
										updateFilesCb={files =>
											changeHandler({
												target: {
													name: 'comprovanteEndereco',
													value: files[0] || null
												}
											})
										}
										disabled={readOnly}
									/>
									{errors.comprovanteEndereco && <ErrorMessages errorList={errors.comprovanteEndereco} />}
								</div>
								<div className="form-group col-md-6 col-lg-4">
									<label>Carta Sindical</label>
									<FileUpload
										value={entidade.cartaSindical ? [entidade.cartaSindical] : null}
										updateFilesCb={files =>
											changeHandler({
												target: {
													name: 'cartaSindical',
													value: files[0] || null
												}
											})
										}
										disabled={readOnly}
									/>
									{errors.cartaSindical && <ErrorMessages errorList={errors.cartaSindical} />}
								</div>
								<div className="form-group col-md-6 col-lg-4">
									<label>Comprovante de atuação em POA</label>
									<FileUpload
										value={entidade.comprovanteUmAnoPoa ? [entidade.comprovanteUmAnoPoa] : null}
										updateFilesCb={files =>
											changeHandler({
												target: {
													name: 'comprovanteUmAnoPoa',
													value: files[0] || null
												}
											})
										}
										disabled={readOnly}
									/>
									<small>
										<strong>DOCUMENTO NÃO OBRIGATÓRIO:</strong> Para fins de comprovação de atuação na cidade de Porto
										Alegre, a Entidade deve anexar uma declaração do Presidente/Diretor/Proprietário assinada afirmando
										atuar em Porto Alegre, em formato PDF.
									</small>
									{errors.comprovanteUmAnoPoa && <ErrorMessages errorList={errors.comprovanteUmAnoPoa} />}
								</div>
							</div>
						</fieldset>
					</form>
				)}
				<div className="buttons">
					<button type="button" className="btn btn-outline-danger" onClick={cancelar}>
						<i className="fa fa-times" aria-hidden="true"></i>Cancelar
					</button>
					{!readOnly && (
						<>
							{id !== 'new' && (
								<button type="button" className="btn btn-danger" onClick={() => setShowPopupRemove(true)}>
									<i className="fa fa-trash-o" aria-hidden="true"></i>Remover
								</button>
							)}
							<button type="button" className="btn btn-primary" onClick={submeteForm}>
								<i className="fa fa-save" aria-hidden="true"></i>Salvar
							</button>
							{errors.prazo && <ErrorMessages errorList={errors.prazo} />}
							{size(errors) > 0 && (
								<div className="col-md-12">
									<ErrorMessages errorList={['Há erros no preenchimento do formulário. Verifique acima.']} />
								</div>
							)}
						</>
					)}
				</div>
				{showPopupRemove && (
					<div
						className="modal"
						style={{
							backgroundColor: 'rgba(0,0,0,.5)',
							display: 'flex',
							flexFlow: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						role="dialog"
					>
						<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
							<div className="modal-content">
								<div className="modal-header">
									<h1 className="h3">Remover Entidade</h1>
								</div>
								<div className="modal-body">
									<span>Remover a entidade e manter participantes cadastrados?</span>
								</div>
								<div className="modal-footer">
									<div className="buttons">
										<button type="button" className="btn btn-outline-danger" onClick={() => setShowPopupRemove(false)}>
											<i className="fa fa-times" aria-hidden="true"></i>Cancelar
										</button>
										<button type="button" className="btn btn-danger" onClick={remover}>
											Confirmar Remoção
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{size(showPopupMessage) > 0 && (
					<ModalMessage showPopupMessage={showPopupMessage} hidePopupMessage={hidePopupMessage} />
				)}
			</div>
		</>
	);
}
Entidade.displayName = 'Entidade';
Entidade.propTypes = {};

export default Entidade;
