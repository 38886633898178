import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	current: null,
	updating: false,
	message: null,
	errorMessage: null
};

export const participanteSlice = createSlice({
	name: 'participante',
	initialState: INITIAL_STATE,
	reducers: {
		// saga actions
		saveParticipante: () => {},
		removeParticipante: () => {},
		// reducer actions
		setCurrent: (state, action) => {
			state.current = action.payload;
		},
		setUpdating: (state, action) => {
			state.updating = action.payload;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		limpaStore: state => {
			state = INITIAL_STATE;
			return state;
		}
	}
});

const { actions, reducer: participanteReducer } = participanteSlice;

export const {
	saveParticipante,
	removeParticipante,
	setCurrent,
	setUpdating,
	setMessage,
	setErrorMessage,
	limpaStore
} = actions;

export default participanteReducer;
