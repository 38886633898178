import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { useQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';

import gql from 'graphql-tag';
import moment from 'moment';

import ErrorMessages from '../../components/ErrorMessages';
import Loader from '../../components/Loader';
import PaginationV2 from '../../components/PaginationV2';
import SearchBar from '../../components/SearchBar';
import useCalendario from '../../customHooks/useCalendario';
import { setCount, setPage, setSearchTerm } from '../../Redux/entidades/entidadesSlice';
import { history } from '../../Redux/store';
import { isDebug, isExternal } from '../../utils/tools';

const PAGE_SIZE = 10;

function EntidadesComponent() {
	/* REDUX */
	const dispatch = useDispatch();
	const searchTerm = useSelector(state => state.entidades?.searchTerm);
	const page = useSelector(state => state.entidades?.page);
	const count = useSelector(state => state.entidades?.count);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const searchRef = useRef();
	const [entidades, setEntidades] = useState(null);
	const [inicial, setInicial] = useState(0);
	const [final, setFinal] = useState(0);
	const [termo, setTermo] = useState(
		JSON.stringify({
			term: isExternal ? 'NINGUÉM TEM' : '',
			sort: { numero: 1 }
		})
	);
	const email = useRef();

	const {
		data: dataEntidades,
		loading: loadingEntidades,
		errors: errorEntidades,
		refetch: refetchEntidades
	} = useQuery(
		gql`
			query list($term: String, $skip: Int, $limit: Int) {
				list: ECmduaEntidadeList(term: $term, skip: $skip, limit: $limit) {
					id
					nomeConsolidado
				}
				count: ECmduaEntidadeCount(term: $term)
			}
		`,
		{
			variables: {
				skip: page * PAGE_SIZE,
				limit: PAGE_SIZE,
				term: termo
			},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const prepareTermo = useCallback(
		searchTerm => {
			const termoAux = {
				term: searchTerm,
				usuarioCriador: isExternal ? email.current : undefined,
				calendario: calendario?.calendario,
				sort: { nomeConsolidado: 1 }
			};
			setTermo(JSON.stringify(termoAux));
		},
		[calendario]
	);

	useEffect(() => {
		if (userProfile && !email.current) {
			email.current = userProfile.email;
			prepareTermo(searchTerm);
			refetchEntidades();
		}
	}, [prepareTermo, refetchEntidades, userProfile, searchTerm]);

	useEffect(() => {
		if (dataEntidades) {
			setEntidades(dataEntidades.list);
			dispatch(setCount(dataEntidades.count));
			if (searchRef.current) {
				searchRef.current.focus();
			}
		}
	}, [dispatch, dataEntidades]);

	useEffect(() => {
		if (count !== null) {
			const inicial = page * PAGE_SIZE + 1;
			setInicial(inicial > count ? count : inicial);
			const final = page + 1 * PAGE_SIZE;
			setFinal(final > count ? count : final);
		}
	}, [page, count]);

	const setDispatchPage = useCallback(
		page => {
			dispatch(setPage(page));
		},
		[dispatch]
	);

	const isForaPeriodo = useMemo(
		() =>
			!isDebug &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimInscricoesChapasDelegados')),
		[calendario, isAfter]
	);

	return loadingEntidades || loadingCalendario ? (
		<Loader msg="Carregando entidades..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendário']} />
	) : errorEntidades ? (
		<ErrorMessages errorList={['Problemas ao carregar as entidades']} />
	) : (
		<div>
			<div className="titulo-pagina">
				<h1>Entidades</h1>
				{!isForaPeriodo && (
					<div className="buttons">
						<button type="button" className="btn btn-primary" onClick={() => history.push('/entidade/new')}>
							<i className="fa fa-plus" aria-hidden="true"></i>Nova Entidade
						</button>
					</div>
				)}
			</div>
			<SearchBar
				searchRef={searchRef}
				placeholder="Pesquisar por razão social, nome fantasia, cnpj, nome ou CPF dos integrantes ou por nome da região"
				setSearchTerm={setSearchTerm}
				searchTermSelector="entidades.searchTerm"
				onSubmit={prepareTermo}
			/>
			{count !== null && (
				<p>
					<strong>{count}</strong> entidade{count === 1 ? '' : 's'}
				</p>
			)}
			<ul className="list">
				{entidades?.map((entidade, i) => (
					<li
						key={entidade.id}
						className={`list-items${i % 2 === 0 ? ' even' : ''}`}
						onClick={() => history.push(`/entidade/${entidade.id}`)}
					>
						{entidade.nomeConsolidado}
					</li>
				))}
			</ul>
			{count !== null && (
				<p>
					<strong>
						{inicial} - {final}
					</strong>{' '}
					de <strong>{count}</strong>
				</p>
			)}
			{count !== null && <PaginationV2 totalItems={count} pageSize={PAGE_SIZE} page={page} setPage={setDispatchPage} />}
		</div>
	);
}
EntidadesComponent.displayName = 'EntidadesCompoent';
EntidadesComponent.propTypes = {};

export default EntidadesComponent;
