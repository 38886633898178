import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useMutation } from 'react-apollo';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import { loader } from 'graphql.macro';
import { size } from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v4';

import ErrorMessages from '../../../components/ErrorMessages';
import Loader from '../../../components/Loader';
import useCalendario from '../../../customHooks/useCalendario';
import useMessages from '../../../customHooks/useMessages';
import { history } from '../../../Redux/store';
import { guardaChapas, guardaDelegados } from '../../../Redux/Votacao/votacaoSlice';
import { isCPF, isDebug } from '../../../utils/tools';

const registrosVotacaoQuery = loader('./registrosVotacaoQuery.graphql');
const participantesQuery = loader('./participantesQuery.graphql');
const chapasQuery = loader('./chapasQuery.graphql');
const delegadosQuery = loader('./delegadosQuery.graphql');
const registroVotacaoCreate = loader('./registroVotacaoCreate.graphql');
const registroVotacaoChapa = loader('./registroVotacaoChapa.graphql');
const registroVotacaoDelegado = loader('./registroVotacaoDelegado.graphql');
const votoCreateChapa = loader('./votoCreateChapa.graphql');
const votoCreateDelegado = loader('./votoCreateDelegado.graphql');

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[VOTACAO ${++i}]:`, ...args);

function VotacaoComponent() {
	/* REDUX */
	const dispatch = useDispatch();
	const chapas = useSelector(state => state.votacao.chapas);
	const delegados = useSelector(state => state.votacao.delegados);

	/* CUSTOM HOOKS */
	const { calendario, loading: loadingCalendario, error: errorCalendario } = useCalendario();
	const { createMessage } = useMessages();

	/* ESTADOS */
	const [loading, setLoading] = useState(true);
	const cpfRef = useRef();
	const [cpf, setCpf] = useState(null);
	const [errors, setErrors] = useState({});
	const [registroVotacao, setRegistroVotacao] = useState(null);
	const [participante, setParticipante] = useState(null);
	const [tipoVoto, setTipoVoto] = useState(null);
	const [selected, setSelected] = useState(null);
	const [showPopup, setShowPopup] = useState(false);
	const [votos, setVotos] = useState({
		chapa: {
			label: 'Chapas',
			dataHoraVoto: null,
			observacoes: ''
		},
		delegado: {
			label: 'Delegados',
			dataHoraVoto: null,
			observacoes: ''
		}
	});

	// #region queries and mutations
	const [
		getParticipantes,
		{ data: dataParticipantes, loading: loadingParticipantes, errors: errorParticipantes }
	] = useLazyQuery(participantesQuery, {
		ssr: true,
		fetchPolicy: 'network-only'
	});

	const [
		getRegistrosVotacao,
		{ data: dataRegistrosVotacao, loading: loadingRegistrosVotacao, errors: errorRegistrosVotacao }
	] = useLazyQuery(registrosVotacaoQuery, {
		ssr: true,
		fetchPolicy: 'network-only'
	});

	const [getChapas, { data: dataChapas, loading: loadingChapas, errors: errorChapas }] = useLazyQuery(chapasQuery, {
		ssr: true,
		fetchPolicy: 'network-only'
	});

	const [getDelegados, { data: dataDelegados, loading: loadingDelegados, errors: errorDelegados }] = useLazyQuery(
		delegadosQuery,
		{
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const [createRegistroVotacao, { data: dataCreateRV, error: errorCreateRV, loading: loadingCreateRV }] = useMutation(
		registroVotacaoCreate
	);

	const [registraVotaChapa, { data: dataVotaChapa, error: errorVotaChapa, loading: loadingVotaChapa }] = useMutation(
		registroVotacaoChapa
	);

	const [
		registraVotoDelegado,
		{ data: dataVotaDelegado, error: errorVotaDelegado, loading: loadingVotaDelegado }
	] = useMutation(registroVotacaoDelegado);

	const [
		createVotoChapa,
		{ data: dataCreateVotoChapa, error: errorCreateVotoChapa, loading: loadingCreateVotoChapa }
	] = useMutation(votoCreateChapa);

	const [
		createVotoDelegado,
		{ data: dataCreateVotoDelegado, error: errorCreateVotoDelegado, loading: loadingCreateVotoDelegado }
	] = useMutation(votoCreateDelegado);

	// #endregion

	const setCpfFocus = useCallback(() => {
		if (cpfRef.current) {
			cpfRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (calendario) {
			debugLog('[calendario]', calendario);
			const termChapas = JSON.stringify({
				calendario: calendario.calendario,
				situacao: 'confirmada'
			});
			const termDelegados = JSON.stringify({
				calendario: calendario.calendario
			});
			const auxVars = {
				variables: {
					term: termChapas,
					skip: 0,
					limit: 10000
				}
			};
			getChapas(auxVars);
			getDelegados({ ...auxVars, variables: { ...auxVars.variables, term: termDelegados } });
		}
	}, [calendario, getChapas, getDelegados]);

	useEffect(() => {
		if (dataChapas) {
			debugLog('[dataChapas]', dataChapas.list);
			dispatch(guardaChapas(dataChapas.list));
		}
	}, [dataChapas, dispatch]);

	useEffect(() => {
		if (dataDelegados) {
			debugLog('[dataDelegados]', dataDelegados.list);
			dispatch(guardaDelegados(dataDelegados.list));
		}
	}, [dataDelegados, dispatch]);

	useEffect(() => {
		if (loading && (chapas || errorChapas) && (delegados || errorDelegados)) {
			debugLog('[loading]', loading);
			setLoading(false);
			setTimeout(setCpfFocus, 1000);
		}
	}, [loading, chapas, errorChapas, delegados, errorDelegados, setCpfFocus]);

	const obtemCpf = useCallback(
		cpf => {
			let errors = {};
			const cpfOnlyNumbers = cpf.replace(/[^\d]/g, '');
			if (size(cpfOnlyNumbers) !== 11 || !isCPF(cpfOnlyNumbers)) {
				errors.cpf = ['CPF inválido'];
			}
			if (size(errors) > 0) {
				setErrors(errors);
			} else {
				setErrors({});
				getParticipantes({
					variables: {
						term: JSON.stringify({
							cpf: cpf
						}),
						skip: 0,
						limit: 1
					}
				});
			}
		},
		[getParticipantes]
	);

	useEffect(() => {
		if (dataParticipantes) {
			debugLog('[dataParticipantes]', dataParticipantes);
			if (size(dataParticipantes.list) === 0) {
				setErrors({ cpf: ['Participante não encontrado'] });
			} else {
				setErrors({});
				setParticipante(dataParticipantes.list[0]);
			}
		}
	}, [dataParticipantes]);

	useEffect(() => {
		if (participante) {
			debugLog('[participante]', participante);
			getRegistrosVotacao({
				variables: {
					term: JSON.stringify({
						calendario: calendario.calendario,
						eleitor: participante.id
					}),
					skip: 0,
					limit: 1
				}
			});
		}
	}, [calendario, cpf, getRegistrosVotacao, participante]);

	useEffect(() => {
		if (dataRegistrosVotacao?.list && participante) {
			debugLog('[dataRegistrosVotacao]', dataRegistrosVotacao);
			if (size(dataRegistrosVotacao.list) === 0) {
				const variables = { id: uuid(), calendario: calendario.calendario, eleitor: participante };
				try {
					createRegistroVotacao({ variables });
				} catch (error) {
					console.error('erro ao registrar presença', error);
					history.push('/serverUnavailable');
				}
			} else {
				setRegistroVotacao(dataRegistrosVotacao.list[0]);
			}
		}
	}, [calendario, createRegistroVotacao, dataRegistrosVotacao, participante]);

	useEffect(() => {
		if (dataCreateRV) {
			debugLog('[dataCreateRV]', dataCreateRV);
			const rv = dataCreateRV.item;
			setRegistroVotacao(rv);
			createMessage('Presença registrada com sucesso!', 1);
		}
	}, [dataCreateRV, createMessage]);

	useEffect(() => {
		if (errorCreateRV) {
			debugLog('[errorCreateRV]', errorCreateRV);
			console.error('erro ao registrar presença', errorCreateRV);
			history.push('/serverUnavailable');
		}
	}, [errorCreateRV]);

	useEffect(() => {
		if (
			registroVotacao &&
			(votos.chapa.dataHoraVoto !== registroVotacao.dataHoraVotoChapa ||
				votos.delegado.dataHoraVoto !== registroVotacao.dataHoraVotoDelegado)
		) {
			debugLog('[registroVotacao]', registroVotacao);
			const dhChapa = registroVotacao.dataHoraVotoChapa ? moment(registroVotacao.dataHoraVotoChapa) : null;
			const dhDelegado = registroVotacao.dataHoraVotoDelegado ? moment(registroVotacao.dataHoraVotoDelegado) : null;
			setVotos({
				chapa: {
					...votos.chapa,
					dataHoraVoto: dhChapa?.format('DD/MM/YYYY HH:mm:ss'),
					observacoes: dhChapa ? `votado às ${dhChapa.format('HH:mm')} de ${dhChapa.format('DD/MM/YYYY')}` : null
				},
				delegado: {
					...votos.delegado,
					dataHoraVoto: dhDelegado?.format('DD/MM/YYYY HH:mm:ss'),
					observacoes: dhDelegado
						? `votado às ${dhDelegado.format('HH:mm')} de ${dhDelegado.format('DD/MM/YYYY')}`
						: null
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registroVotacao]);

	const cancelarTipoVoto = useCallback(() => {
		setRegistroVotacao(null);
		setParticipante(null);
		setCpf(null);
		setTimeout(setCpfFocus, 100);
	}, [setCpfFocus]);

	const cancelarVoto = useCallback(() => {
		setTipoVoto(null);
		setSelected(null);
	}, []);

	const confirmarVoto = useCallback(() => {
		if (selected) {
			const agora = moment();
			if (tipoVoto === 'chapa') {
				const variables = { id: registroVotacao.id, dataHoraVotoChapa: agora.toISOString() };
				registraVotaChapa({ variables });
			} else {
				const variables = { id: registroVotacao.id, dataHoraVotoDelegado: agora.toISOString() };
				registraVotoDelegado({ variables });
			}
		} else {
			createMessage('Você precisa selecionar uma opção para confirmar o voto', 3);
		}
	}, [createMessage, registroVotacao, selected, tipoVoto, registraVotaChapa, registraVotoDelegado]);

	const removerRegistroVoto = useCallback(() => {
		if (tipoVoto === 'chapa') {
			const variables = { id: registroVotacao.id, dataHoraVotoChapa: undefined };
			registraVotaChapa({ variables });
		} else {
			const variables = { id: registroVotacao.id, dataHoraVotoDelegado: undefined };
			registraVotoDelegado({ variables });
		}
	}, [registraVotaChapa, registraVotoDelegado, registroVotacao, tipoVoto]);

	const votar = useCallback(() => {
		try {
			const variables = { id: uuid(), calendario: calendario.calendario, regiao: participante.regiao };
			if (tipoVoto === 'chapa') {
				variables.chapa = selected;
				createVotoChapa({ variables });
			} else {
				variables.delegado = selected;
				createVotoDelegado({ variables });
			}
		} catch (error) {
			console.error('erro ao registrar voto', error);
			removerRegistroVoto();
			history.push('/serverUnavailable');
		}
	}, [calendario, tipoVoto, selected, createVotoChapa, createVotoDelegado, removerRegistroVoto, participante]);

	useEffect(() => {
		if (dataVotaChapa) {
			debugLog('[dataVotaChapa]', dataVotaChapa);
			votar();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataVotaChapa]);

	useEffect(() => {
		if (errorVotaChapa) {
			debugLog('[errorVotaChapa]', errorVotaChapa);
			createMessage('Erro ao registrar voto. Tente novamente em seguida', 3);
			console.error('erro ao registrar voto', errorVotaChapa);
		}
	}, [createMessage, errorVotaChapa]);

	useEffect(() => {
		if (dataVotaDelegado) {
			debugLog('[dataVotaDelegado]', dataVotaDelegado);
			votar();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataVotaDelegado]);

	useEffect(() => {
		if (errorVotaDelegado) {
			debugLog('[errorVotaDelegado]', errorVotaDelegado);
			createMessage('Erro ao registrar voto. Tente novamente em seguida', 3);
			console.error('erro ao registrar voto', errorVotaDelegado);
		}
	}, [createMessage, errorVotaDelegado]);

	useEffect(() => {
		if (dataCreateVotoChapa) {
			debugLog('[dataCreateVotoChapa]', dataCreateVotoChapa);
			const item = dataVotaChapa.item;
			const agora = moment(item.dataHoraVotoChapa);
			setVotos({
				...votos,
				chapa: {
					...votos.chapa,
					dataHoraVoto: agora.toISOString(),
					observacoes: `votado às ${agora.format('HH:mm')} de ${agora.format('DD/MM/YYYY')}`
				}
			});
			setTipoVoto(null);
			setSelected(null);
			setShowPopup(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataCreateVotoChapa]);

	useEffect(() => {
		if (dataCreateVotoDelegado) {
			debugLog('[dataCreateVotoDelegado]', dataCreateVotoDelegado);
			const item = dataVotaDelegado.item;
			const agora = moment(item.dataHoraVotoDelegado);
			setVotos({
				...votos,
				delegado: {
					...votos.delegado,
					dataHoraVoto: agora.toISOString(),
					observacoes: `votado às ${agora.format('HH:mm')} de ${agora.format('DD/MM/YYYY')}`
				}
			});
			setTipoVoto(null);
			setSelected(null);
			setShowPopup(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataCreateVotoDelegado]);

	useEffect(() => {
		if (errorCreateVotoChapa) {
			debugLog('[errorCreateVotoChapa]', errorCreateVotoChapa);
			removerRegistroVoto();
			createMessage('Erro ao salvar voto. Tente novamente em seguida', 3);
			console.error('erro ao salvar voto', errorCreateVotoChapa);
		}
	}, [createMessage, errorCreateVotoChapa, removerRegistroVoto]);

	useEffect(() => {
		if (errorCreateVotoDelegado) {
			debugLog('[errorCreateVotoDelegado]', errorCreateVotoDelegado);
			removerRegistroVoto();
			createMessage('Erro ao salvar voto. Tente novamente em seguida', 3);
			console.error('erro ao salvar voto', errorCreateVotoDelegado);
		}
	}, [createMessage, errorCreateVotoDelegado, removerRegistroVoto]);

	// AUXILIARES PARA RENDER
	const regiao = useMemo(() => {
		if (participante) {
			return participante.regiao.nome.split(' - ')[0];
		}
		return null;
	}, [participante]);

	const jaTemVoto = useMemo(() => votos.chapa.dataHoraVoto || votos.delegado.dataHoraVoto, [votos]);

	const apuracaoChapasEmAndamento = useMemo(() => {
		const { chapaEleita } = participante?.regiao || {};
		const cEmAndamento = !chapaEleita;
		return cEmAndamento;
	}, [participante]);

	const apuracaoDelegadosEmAndamento = useMemo(() => {
		const { delegadosEleitos } = participante?.regiao || {};
		const dEmAndamento = size(delegadosEleitos) === 0;
		return dEmAndamento;
	}, [participante]);

	const semChapas = useMemo(() => size(chapas[regiao] || []) === 0, [chapas, regiao]);
	const semDelegados = useMemo(() => size(delegados[regiao] || []) === 0, [delegados, regiao]);

	return loadingCalendario || loadingChapas || loadingDelegados || loading ? (
		<Loader msg="Inicializando tela de votação" />
	) : errorCalendario || errorChapas || errorDelegados ? (
		<ErrorMessages errorList={['Problemas ao inicializar tela de votação']} />
	) : (
		<form autoComplete="off" className="votacao-container">
			<div className="votacao-container-unico-elemento">
				{!participante && (
					<>
						<h1 className="h3 mb-4">Informe o CPF para votar</h1>
						<div className="row">
							<div className="input-group col-md-6 col-lg-5 col-xl-4">
								<InputMask
									ref={cpfRef}
									className="form-control votacao-cpf"
									name="cpf"
									value={cpf || ''}
									onChange={e => setCpf(e.target.value)}
									onKeyDown={e => {
										if (e.key === 'Enter') {
											e.preventDefault();
											e.stopPropagation();
											obtemCpf(cpf);
										}
									}}
									autoComplete="off"
									mask="999.999.999-99"
									disabled={!!registroVotacao}
								/>

								<button
									type="button"
									className="btn btn-primary votacao-ok-button"
									onClick={() => obtemCpf(cpf)}
									disabled={!!registroVotacao}
								>
									OK
								</button>
							</div>
							{errors.cpf && (
								<div className="col-12">
									<ErrorMessages errorList={errors.cpf} />
								</div>
							)}
						</div>
					</>
				)}
				{participante && (
					<>
						<div className="row">
							<div className="col-auto">
								<label htmlFor="">CPF</label>
								<p>{participante.cpf}</p>
							</div>
							<div className="col-auto">
								<label htmlFor="">Nome</label>
								<p>{participante.nome}</p>
							</div>
							<div className="col-auto">
								<label htmlFor="">Região</label>
								<p>{participante.regiao.nome}</p>
							</div>
						</div>
					</>
				)}

				{loadingParticipantes || loadingRegistrosVotacao || loadingCreateRV ? (
					<Loader msg="Carregando participante" />
				) : errorParticipantes || errorRegistrosVotacao ? (
					<ErrorMessages errorList={['Problemas ao carregar participante']} />
				) : (
					registroVotacao &&
					!tipoVoto && (
						<>
							{apuracaoChapasEmAndamento || apuracaoDelegadosEmAndamento ? (
								<>
									<h1 className="h3 my-4">Selecione em que você quer votar para a região {participante.regiao.nome}</h1>
									<div className="votacao-grid">
										{Object.keys(votos).map(tipo => {
											const v = votos[tipo];
											return (tipo === 'chapa' && apuracaoChapasEmAndamento) ||
												(tipo === 'delegado' && apuracaoDelegadosEmAndamento) ? (
												<div
													key={tipo}
													className={`votacao-item${
														v.dataHoraVoto ||
														v.indisponivel ||
														(tipo === 'chapa' && semChapas) ||
														(tipo === 'delegado' && semDelegados)
															? ' votado'
															: ''
													}`}
													onClick={() =>
														v.dataHoraVoto ||
														v.indisponivel ||
														(tipo === 'chapa' && semChapas) ||
														(tipo === 'delegado' && semDelegados)
															? null
															: setTipoVoto(tipo)
													}
												>
													{v.dataHoraVoto && <div className="computado">COMPUTADO</div>}
													{((tipo === 'chapa' && semChapas) || (tipo === 'delegado' && semDelegados)) && (
														<div className={`computado ${tipo}`}>sem {tipo === 'chapa' ? 'chapas' : 'delegados'}</div>
													)}

													<h2 className={`votacao-tipo${v.dataHoraVoto || v.indisponivel ? ' votado' : ''}`}>
														{v.label}
													</h2>
													<div className="votacao-obs">{v.observacoes}</div>
												</div>
											) : null;
										})}
									</div>
								</>
							) : (
								<h1 className="h3">Apuração já encerrada para {participante?.regiao?.nome}</h1>
							)}
							<div className="buttons mt-4">
								<button
									type="button"
									className={`btn ${jaTemVoto ? 'btn-primary' : 'btn-outline-danger'}`}
									onClick={cancelarTipoVoto}
								>
									{jaTemVoto ? 'Encerrar Votação' : 'Cancelar'}
								</button>
							</div>
						</>
					)
				)}

				{tipoVoto && (
					<>
						<div className="h3 my-4">
							Selecione {tipoVoto === 'chapa' ? ' a chapa ' : ' o delegado '}
							{tipoVoto === 'chapa' ? ' na ' : ' no '} qual você deseja votar para a região {participante.regiao.nome}
						</div>
						{tipoVoto === 'chapa' ? (
							<div className="votacao-opcoes">
								{(chapas[regiao] || []).map(chapa => (
									<div
										key={chapa.id}
										className={`votacao-opcao${selected?.id === chapa.id ? ' selected' : ''}`}
										onClick={() => setSelected(chapa)}
									>
										<div className="votacao-chapa-numero h3">CHAPA {chapa.numero}</div>
										<div className="votacao-chapa-regiao">
											Região: <strong>{chapa.regiao.nome}</strong>
										</div>
										<div className="votacao-chapa-integrantes">
											<p>
												Titular: <strong>{chapa.titular.nome}</strong>
											</p>
											<p>
												Suplente 1: <strong>{chapa.suplente1.nome}</strong>
											</p>
											<p>
												Suplente 2: <strong>{chapa.suplente2.nome}</strong>
											</p>
										</div>
									</div>
								))}
							</div>
						) : (
							<div className="votacao-opcoes">
								{(delegados[regiao] || []).map(delegado => (
									<div
										key={delegado.id}
										className={`votacao-opcao${selected?.id === delegado.id ? ' selected' : ''}`}
										onClick={() => setSelected(delegado)}
									>
										<div className="h3 text-center py-4">{delegado.delegado.nome}</div>
									</div>
								))}
							</div>
						)}
						<div className="mt-4 buttons">
							<button type="button" className="btn btn-outline-danger" onClick={cancelarVoto}>
								<i className="fa fa-times" aria-hidden="true"></i> Cancelar
							</button>
							<button type="button" className="btn btn-primary" onClick={() => setShowPopup(true)} disabled={!selected}>
								Confirmar voto
							</button>
						</div>
					</>
				)}
			</div>
			{showPopup && (
				<div
					className="modal"
					style={{
						backgroundColor: 'rgba(0,0,0,.5)',
						display: 'flex',
						flexFlow: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
					role="dialog"
				>
					<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
						<div className="modal-content">
							<div className="modal-header">
								<h1 className="modal-title h2">Confirmar voto</h1>
							</div>
							{tipoVoto === 'chapa' ? (
								<>
									<div className="modal-body msg-confirma-voto">
										Você confirma o seu voto na <strong>chapa {selected.numero}</strong> da região{' '}
										<strong>{participante.regiao.nome}</strong>?
									</div>
								</>
							) : (
								<>
									<div className="modal-body msg-confirma-voto">
										Você confirma o seu voto no candidato a delegado <strong>{selected.delegado.nome}</strong> para a
										região <strong>{participante.regiao.nome}</strong>?
									</div>
								</>
							)}
							<div className="modal-footer">
								<div className="buttons">
									<button type="button" className="btn btn-outline-danger" onClick={() => setShowPopup(false)}>
										<i className="fa fa-times" aria-hidden="true"></i> Cancelar
									</button>
									<button type="button" className="btn btn-primary" onClick={confirmarVoto}>
										Confirmar voto
									</button>
								</div>
							</div>
						</div>
					</div>
					{(loadingVotaChapa || loadingVotaDelegado || loadingCreateVotoChapa || loadingCreateVotoDelegado) && (
						<Loader msg="Registrando voto" />
					)}
				</div>
			)}
			{/* {isDebug && <pre>{JSON.stringify({ tipoVoto, selected, registroVotacao }, null, 2)}</pre>} */}
		</form>
	);
}
VotacaoComponent.displayName = 'VotacaoComponent';

export default VotacaoComponent;
