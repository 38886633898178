import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import useCalendario from '../customHooks/useCalendario';
import { setAba } from '../Redux/Home/homeSlice';
import { history } from '../Redux/store';
import { ROLES } from '../utils/constants';
import { isDebug, isExternal } from '../utils/tools';
import Permissao from './Permissao';

export const ABA_CHAPAS = 'chapas';
export const ABA_DELEGADOS = 'delegados';
export const ABA_PARTICIPANTES = 'participantes';
export const ABA_ENTIDADES = 'entidades';
export const ABA_RELATORIOS = 'relatorios';
export const ABA_ANALISE = 'analise';
export const ABA_VOTACAO = 'votacao';
export const ABA_APURACAO = 'apuracao';

function Navigation({ onNavigate }) {
	/* REDUX */
	const dispatch = useDispatch();
	const aba = useSelector(state => state.home.aba);

	/* CUSTOM HOOKS */
	const { calendario } = useCalendario();

	/* ESTADOS */
	const selectAba = useCallback(
		abaAux => {
			if (aba !== abaAux) {
				dispatch(setAba(abaAux));
			}
			onNavigate && onNavigate(abaAux);
			if (window.location.pathname !== '/home') {
				history.push('/home');
			}
		},
		[aba, dispatch, onNavigate]
	);

	const isPosPeriodoRecursos = useMemo(
		() => !calendario || moment().isAfter(calendario.publicacaoImpugnacoesAposRecursosMoment),
		[calendario]
	);

	const isPosPublicacaoEleicao = useMemo(() => !calendario || moment().isAfter(calendario.publicacaoEleicaoMoment), [
		calendario
	]);

	return (
		<nav className="nav nav-tabs">
			<Permissao role={isExternal ? null : ROLES.CAD_PARTICIPANTE}>
				<li onClick={() => selectAba(ABA_PARTICIPANTES)} className={`${aba === ABA_PARTICIPANTES ? ' selected' : ''}`}>
					Participantes
				</li>
			</Permissao>
			<Permissao role={isExternal ? null : isExternal ? null : ROLES.ADMIN}>
				<li onClick={() => selectAba(ABA_CHAPAS)} className={`${aba === ABA_CHAPAS ? ' selected' : ''}`}>
					Chapas
				</li>
				<li onClick={() => selectAba(ABA_DELEGADOS)} className={`${aba === ABA_DELEGADOS ? ' selected' : ''}`}>
					Delegados
				</li>
				<li onClick={() => selectAba(ABA_ENTIDADES)} className={`${aba === ABA_ENTIDADES ? ' selected' : ''}`}>
					Entidades
				</li>
			</Permissao>
			{!isExternal && (
				<>
					<Permissao role={ROLES.ADMIN}>
						<li onClick={() => selectAba(ABA_RELATORIOS)} className={`${aba === ABA_RELATORIOS ? ' selected' : ''}`}>
							Relatórios
						</li>
						{(isDebug || isPosPeriodoRecursos) && (
							<li onClick={() => selectAba(ABA_ANALISE)} className={`${aba === ABA_ANALISE ? ' selected' : ''}`}>
								Impugnações
							</li>
						)}
					</Permissao>
					<Permissao role={ROLES.OPERADOR}>
						{(isDebug || isPosPublicacaoEleicao) && (
							<li onClick={() => selectAba(ABA_VOTACAO)} className={`${aba === ABA_VOTACAO ? ' selected' : ''}`}>
								Votação
							</li>
						)}
					</Permissao>
					<Permissao role={ROLES.ADMIN}>
						{(isDebug || isPosPublicacaoEleicao) && (
							<li onClick={() => selectAba(ABA_APURACAO)} className={`${aba === ABA_APURACAO ? ' selected' : ''}`}>
								Apuração
							</li>
						)}
					</Permissao>
				</>
			)}
		</nav>
	);
}
Navigation.displayName = 'Navigation';
Navigation.propTypes = {
	onNavigate: PropTypes.func
};

export default Navigation;
