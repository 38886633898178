import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { useQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';

import gql from 'graphql-tag';
import moment from 'moment';

import ErrorMessages from '../../components/ErrorMessages';
import Loader from '../../components/Loader';
import PaginationV2 from '../../components/PaginationV2';
import SearchBar from '../../components/SearchBar';
import useCalendario from '../../customHooks/useCalendario';
import { setCount, setPage, setSearchTerm } from '../../Redux/chapas/chapasSlice';
import { history } from '../../Redux/store';
import { isDebug, isExternal } from '../../utils/tools';

const PAGE_SIZE = 10;

function ChapasComponent() {
	/* REDUX */
	const dispatch = useDispatch();
	const searchTerm = useSelector(state => state.chapas?.searchTerm);
	const page = useSelector(state => state.chapas?.page);
	const count = useSelector(state => state.chapas?.count);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const searchRef = useRef();
	const [chapas, setChapas] = useState(null);
	const [inicial, setInicial] = useState(0);
	const [final, setFinal] = useState(0);
	const [termo, setTermo] = useState(
		JSON.stringify({
			term: isExternal ? 'NINGUÉM TEM' : '',
			sort: { numero: 1 }
		})
	);
	const email = useRef();

	const { data: dataChapas, loading: loadingChapas, errors: errorChapas } = useQuery(
		gql`
			query deepSearch($term: String, $skip: Int, $limit: Int) {
				list: ECmduaChapaDeepSearch(term: $term, skip: $skip, limit: $limit) {
					id
					numero
					regiao {
						id
						nome
					}
				}
				count: ECmduaChapaDeepSearchCount(term: $term)
			}
		`,
		{
			variables: {
				skip: page * PAGE_SIZE,
				limit: PAGE_SIZE,
				term: termo
			},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const prepareTermo = useCallback(
		searchTerm => {
			const termoAux = {
				term: searchTerm,
				usuarioCriador: isExternal ? email.current : undefined,
				calendario: calendario?.calendario,
				sort: { numero: 1 }
			};
			setTermo(JSON.stringify(termoAux));
		},
		[calendario]
	);

	useEffect(() => {
		if (isExternal && userProfile && !email.current) {
			email.current = userProfile.email;
			prepareTermo(searchTerm);
		}
	}, [prepareTermo, searchTerm, userProfile]);

	// useEffect(() => {
	// 	let timer = null;
	// 	if (searchTerm !== termo) {
	// 		if (timer) {
	// 			clearTimeout(timer);
	// 		}
	// 		timer = setTimeout(() => {
	// 			prepareTermo();
	// 		}, 500);
	// 	}

	// 	return () => {
	// 		if (timer) {
	// 			clearTimeout(timer);
	// 		}
	// 	};
	// }, [prepareTermo, searchTerm, termo]);

	useEffect(() => {
		if (dataChapas) {
			setChapas(dataChapas.list);
			dispatch(setCount(dataChapas.count));
			if (searchRef.current) {
				searchRef.current.focus();
			}
		}
	}, [dispatch, dataChapas]);

	useEffect(() => {
		if (count !== null) {
			const inicial = page * PAGE_SIZE + 1;
			setInicial(inicial > count ? count : inicial);
			const final = page + 1 * PAGE_SIZE;
			setFinal(final > count ? count : final);
		}
	}, [page, count]);

	const setDispatchPage = useCallback(
		page => {
			dispatch(setPage(page));
		},
		[dispatch]
	);

	const isForaPeriodo = useMemo(
		() =>
			!isDebug &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimInscricoesChapasDelegados')),
		[calendario, isAfter]
	);

	return loadingChapas || loadingCalendario ? (
		<Loader msg="Carregando chapas..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendário']} />
	) : errorChapas ? (
		<ErrorMessages errorList={['Problemas ao carregar as chapas']} />
	) : (
		<div>
			<div className="titulo-pagina">
				<h1>Chapas</h1>
				{!isForaPeriodo && (
					<div className="buttons">
						<button type="button" className="btn btn-primary" onClick={() => history.push('/chapa/new')}>
							<i className="fa fa-plus" aria-hidden="true"></i>Nova Chapa
						</button>
					</div>
				)}
			</div>

			<SearchBar
				searchRef={searchRef}
				placeholder="Pesquisar por nome ou CPF dos integrantes ou por nome da região"
				setSearchTerm={setSearchTerm}
				searchTermSelector="chapas.searchTerm"
				onSubmit={prepareTermo}
			/>
			{count !== null && (
				<p>
					<strong>{count}</strong> chapa{count === 1 ? '' : 's'}
				</p>
			)}
			<ul className="list">
				{chapas?.map((chapa, i) => (
					<li
						key={chapa.id}
						className={`list-items${i % 2 === 0 ? ' even' : ''}`}
						onClick={() => history.push(`/chapa/${chapa.id}`)}
					>
						{<span>{`Chapa ${chapa.numero} para ${chapa.regiao.nome}`}</span>}
					</li>
				))}
			</ul>
			{count !== null && (
				<p>
					<strong>
						{inicial} - {final}
					</strong>{' '}
					de <strong>{count}</strong>
				</p>
			)}
			{count !== null && <PaginationV2 totalItems={count} pageSize={PAGE_SIZE} page={page} setPage={setDispatchPage} />}
		</div>
	);
}
ChapasComponent.displayName = 'ChapasCompoent';
ChapasComponent.propTypes = {};

export default ChapasComponent;
