import React from 'react';

import PropTypes from 'prop-types';

const RadioField = ({ name, value, options, disabled, radioStack, onChange }) => (
	<div className={`radio-field ${radioStack ? 'stack' : ''}`}>
		{options.map((o, i) => {
			const codigo = typeof o === 'object' ? o.codigo : o;
			const descricao = typeof o === 'object' ? o.descricao : o;
			return (
				<div className="radio-options" key={i}>
					<label className="radio-label">
						<input
							type="radio"
							value={codigo || ''}
							name={name}
							onClick={onChange}
							disabled={disabled}
							onChange={() => false}
							checked={value === codigo}
							className="radio-input"
						/>{' '}
						{descricao}
					</label>
				</div>
			);
		})}
	</div>
);
RadioField.displayName = 'RadioField';

RadioField.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	containerClass: PropTypes.string,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	options: PropTypes.arrayOf(PropTypes.object),
	radioStack: PropTypes.bool,
	onChange: PropTypes.func
};

export default RadioField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
