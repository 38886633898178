import React from 'react';

import PropTypes from 'prop-types';

import AnaliseParticipanteComponent from './analiseParticipanteComponent';

function AnaliseChapaComponent({ chapa }) {
	return chapa ? (
		<>
			<AnaliseParticipanteComponent participante={chapa.titular} titulo="Titular" />
			<AnaliseParticipanteComponent participante={chapa.suplente1} titulo="Primeiro Suplente" />
			<AnaliseParticipanteComponent participante={chapa.suplente2} titulo="Segundo Suplente" />
			{chapa.situacao && chapa.motivoImpugnacao && (
				<div className="col-12">
					<label htmlFor="">Motivo da Impugnação</label>
					<p>{chapa?.motivoImpugnacao}</p>
				</div>
			)}
		</>
	) : null;
}
AnaliseChapaComponent.displayName = 'AnaliseChapaComponent';
AnaliseChapaComponent.propTypes = {
	chapa: PropTypes.object
};

export default AnaliseChapaComponent;
