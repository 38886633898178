import { takeLatest, call, put } from 'redux-saga/effects';

import { send } from '../../utils/tools';
import { history } from '../store';
import {
	saveDelegado,
	removeDelegado,
	setMessage,
	setUpdating,
	limpaStore,
	setValidationMessages,
	setSituacaoAnalise
} from './delegadoSlice';

const getAction = (type, payload) => ({
	type,
	payload
});

function* saveDelegadoSaga(action) {
	yield put(setUpdating(true));
	try {
		const delegado = { ...action.payload };
		const isAnalise = delegado.analise;
		const origem = delegado.origem;
		delete delegado.origem;
		delete delegado.analise;

		// convert objects to id's to save as references
		delegado.regiao = delegado.regiao?.id;
		delegado.delegado = delegado.delegado?.id;

		const response = yield call(send, getAction('delegado/salvar', { delegado }), '');
		const { ok, output, errors } = response.data;
		console.debug('output: ', output);
		if (ok === 1) {
			yield put(
				setMessage(
					{
						message: `${origem === 'edicao' ? 'Edição' : 'Inscrição'} de Delegado realizada com sucesso!`,
						type: 'success',
						origin: origem
					},
					20
				)
			);
			if (isAnalise) {
				yield put(setSituacaoAnalise(delegado.situacao || 'removida'));
			}
			yield put(setValidationMessages([]));
		} else {
			if (isAnalise) {
				yield put(setSituacaoAnalise('erro'));
			}
			yield put(
				setMessage(
					{
						message: `Erro ao ${origem === 'edicao' ? 'editar' : 'inscrever'} o delegado!`,
						type: 'error',
						origin: origem
					},
					20
				)
			);
			yield put(setValidationMessages(errors));
		}
	} catch (err) {
		console.error('err: ', err);
		yield put(setSituacaoAnalise('erro'));
		yield put(setMessage({ message: 'Ocorreu um erro no saga ao salvar o delegado!', type: 'error' }, 20));
	}
	yield put(setUpdating(false));
}

function* removeDelegadoSaga(action) {
	yield put(setUpdating(true));
	try {
		const response = yield call(send, getAction('delegado/remover', { id: action.payload }), '');
		const { ok, output } = response.data;
		console.debug('output: ', output);
		if (ok === 1) {
			yield put(limpaStore());
			yield put(setMessage('Delegado removida com sucesso!'));
			history.go(-1);
		} else {
			yield put(setMessage('Erro ao remover delegado!'));
		}
	} catch (err) {
		yield put(setMessage('Ocorreu um erro no saga ao remover delegado!'));
	}
	yield put(setUpdating(false));
}

function* watcher() {
	yield takeLatest(saveDelegado.type, saveDelegadoSaga);
	yield takeLatest(removeDelegado.type, removeDelegadoSaga);
}

export default watcher;
