import { takeLatest, call, put } from 'redux-saga/effects';

import { send } from '../../utils/tools';
import { history } from '../store';
import {
	saveParticipante,
	setMessage,
	setUpdating,
	limpaStore,
	removeParticipante,
	setErrorMessage
} from './participanteSlice';

const getAction = (type, payload) => ({
	type,
	payload
});

function* saveParticipanteSaga(action) {
	yield put(setUpdating(true));
	try {
		const participante = { ...action.payload };
		const origem = participante.origem;
		delete participante.origem;

		// convert objects to id's to save as references
		participante.regiao = participante.regiao?.id;

		const response = yield call(send, getAction('participante/salvar', { participante }), '');
		const { ok, error } = response.data;
		if (ok === 1) {
			yield put(
				setMessage(
					{
						message: `${origem === 'edicao' ? 'Edição' : 'Inscrição'} do Participante realizada com sucesso!`,
						type: 'success',
						origin: origem
					},
					20
				)
			);
		} else {
			if (error) {
				yield put(
					setMessage(
						{
							message: `Erro ao ${origem === 'edicao' ? 'editar' : 'inscrever'} o participante! ${error}`,
							type: 'error',
							origin: origem
						},
						20
					)
				);
			} else {
				yield put(
					setMessage(
						{
							message: `Erro ao ${origem === 'edicao' ? 'editar' : 'inscrever'} o participante!`,
							type: 'error',
							origin: origem
						},
						20
					)
				);
			}
		}
	} catch (err) {
		console.error('err: ', err);
		yield put(setMessage({ message: 'Ocorreu um erro no saga ao salvar o participante!', type: 'error' }, 20));
	}
	yield put(setUpdating(false));
}

function* removeParticipanteSaga(action) {
	yield put(setUpdating(true));
	try {
		const response = yield call(send, getAction('participante/remover', { id: action.payload }), '');
		const { ok, error } = response.data;
		if (ok === 1) {
			yield put(limpaStore());
			yield put(setMessage('Participante removido com sucesso!'));
			history.go(-1);
		} else {
			if (error) {
				yield put(setErrorMessage(error));
			} else {
				yield put(setErrorMessage('Erro ao remover participante!'));
			}
		}
	} catch (err) {
		yield put(setErrorMessage('Ocorreu um erro no saga ao remover participante!'));
	}
	yield put(setUpdating(false));
}

function* watcher() {
	yield takeLatest(saveParticipante.type, saveParticipanteSaga);
	yield takeLatest(removeParticipante.type, removeParticipanteSaga);
}

export default watcher;
