import React from 'react';

import useLocalStorage from '../customHooks/useLocalStorage';
import { isDebug } from '../utils/tools';

export default function SetDebugComponent() {
	const [debug, setDebug] = useLocalStorage('debug', isDebug);
	return (
		<div className="checkbox-input p-0 pb-4">
			<input
				type="checkbox"
				id="debug"
				name="debug"
				value={debug || false}
				checked={debug || false}
				onChange={() => null}
				onClick={() => setDebug(old => !old)}
			/>
			<label className="required" onClick={() => setDebug(old => !old)}>
				Marcar para ativar Debug &nbsp;
				<span className="text-small">(F5 para verificar modificações)</span>
			</label>
		</div>
	);
}
SetDebugComponent.displayName = 'SetDebugComponent';
SetDebugComponent.propTypes = {};
