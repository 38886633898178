import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	current: null,
	updating: false,
	message: null
};

export const entidadeSlice = createSlice({
	name: 'entidade',
	initialState: INITIAL_STATE,
	reducers: {
		// saga actions
		saveEntidade: () => {},
		removeEntidade: () => {},
		// reducer actions
		setCurrent: (state, action) => {
			state.current = action.payload;
		},
		setUpdating: (state, action) => {
			state.updating = action.payload;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		limpaStore: state => {
			state = INITIAL_STATE;
			return state;
		}
	}
});

const { actions, reducer: entidadeReducer } = entidadeSlice;

export const { saveEntidade, removeEntidade, setCurrent, setUpdating, setMessage, limpaStore } = actions;

export default entidadeReducer;
