import React from 'react';

import PropTypes from 'prop-types';

import { Route } from 'react-router';

import { get } from 'lodash';

import { keycloakObject } from '../index';
import AccessDenied from './403Page';

const PrivateRoute = ({ component: Component, unauthorizedComponent: UnauthorizedComponent, children, ...props }) => {
	const isAllowed = roles => {
		let allow = true;
		if (roles) {
			allow = roles.reduce(
				(acc, role) => acc && (keycloakObject.hasResourceRole(role) || keycloakObject.hasRealmRole(role)),
				true
			);
		}
		return allow;
	};
	if (children && (Component || UnauthorizedComponent)) {
		// eslint-disable-next-line no-console
		console.warn('PrivateRoute: You cannot specify both a component and children. Component will be ignored.');
	}
	if (get(keycloakObject, 'authenticated')) {
		keycloakObject.updateToken(5);
		return isAllowed(props.roles) ? (
			children ? (
				<Route {...props}>{children}</Route>
			) : (
				<Route {...props} render={innerProps => <Component {...innerProps} />} />
			)
		) : props.unauthorizedComponent ? (
			<Route {...props} render={innerProps => <UnauthorizedComponent {...innerProps} />} />
		) : (
			<Route {...props} component={AccessDenied} />
		);
	} else {
		keycloakObject.login();
		return null;
	}
};
PrivateRoute.displayName = 'PrivateRoute';
PrivateRoute.contextTypes = {
	store: PropTypes.object
};

PrivateRoute.propTypes = {
	component: PropTypes.func,
	roles: PropTypes.arrayOf(PropTypes.string),
	unauthorizedComponent: PropTypes.func,
	children: PropTypes.node
};

export default PrivateRoute;
