import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	userProfile: null
};

export const keycloakSlice = createSlice({
	name: 'keycloak',
	initialState: INITIAL_STATE,
	reducers: {
		// reducer actions
		setUserProfile: (state, action) => {
			state.userProfile = action.payload;
		}
	}
});

const { actions, reducer: keycloakReducer } = keycloakSlice;

export const { setUserProfile } = actions;

export default keycloakReducer;
