import React from 'react';

const AccessDenied = () => (
	<div className="error-screen">
		<h1 className="error-header">
			403
			<span className="error-label" style={{ fontSize: '24px' }}>
				Você ainda não tem permissão para usar esse serviço.
			</span>
		</h1>
	</div>
);
AccessDenied.displayName = 'AccessDenied';
export default AccessDenied;
