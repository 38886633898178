import React from 'react';

import PropTypes from 'prop-types';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { map } from 'lodash';

import { LayoutContext } from './LayoutContext';

const MessageItem = ({ msg, dismiss }) => (
	<li className={`message-item${msg.type === 'error' ? ' error' : ''}`}>
		<span>{msg.text}</span>
		<button type="button" className="btn btn-icon" onClick={() => dismiss(msg.id)}>
			<i className="fa fa-times" aria-hidden="true" />
		</button>
	</li>
);
MessageItem.displayName = 'MessageItem';

const MessageDock = () => (
	<section className="message-dock">
		<LayoutContext.Consumer>
			{({ messageList }) => (
				<TransitionGroup className="container">
					{map(messageList, msg => (
						<CSSTransition key={msg.id} timeout={300} classNames="fade">
							<MessageItem msg={msg} dismiss={msg.dismiss} />
						</CSSTransition>
					))}
				</TransitionGroup>
			)}
		</LayoutContext.Consumer>
	</section>
);
MessageDock.displayName = 'MessageDock';

MessageDock.propTypes = {
	messageList: PropTypes.array
};
MessageItem.propTypes = {
	msg: PropTypes.object,
	dismiss: PropTypes.func
};

export default MessageDock;
