import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';

import { size } from 'lodash';

import ErrorMessages from '../../components/ErrorMessages';
import Loader from '../../components/Loader';
import Navigation from '../../components/Navigation';
import useCalendario from '../../customHooks/useCalendario';
import useMessages from '../../customHooks/useMessages';
import useUserProfile from '../../customHooks/useUserProfile';
import { history } from '../../Redux/store';
import { accessApi, isEmail } from '../../utils/tools';

function Email({ isEmbedded, onCancel = () => (isEmbedded ? null : history.push('/publica')) }) {
	/* CUSTOM HOOKS */
	const { userProfile, authenticated } = useUserProfile();
	const { createMessage } = useMessages();
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const [nome, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [telefone, setTelefone] = useState('');
	const [mensagem, setMensagem] = useState('');
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (authenticated && userProfile) {
			setEmail(userProfile.email);
		}
	}, [authenticated, userProfile]);

	const submeteForm = useCallback(() => {
		let errors = {};
		if (size(nome) === 0) {
			errors.nome = ['Informe o nome'];
		} else {
			if (/[^A-Za-záâäàãéêëèẽíîïìĩóôöòõúûüùũ\s]/.test(nome)) {
				errors.nome = ['Nome só pode possuir letras'];
			} else {
				const naoTemEspaco = !/\s/.test(nome.trim());
				if (naoTemEspaco) {
					errors.nome = ['Informar nome completo'];
				}
			}
		}
		if (size(email) === 0) {
			errors.email = ['Informe o email'];
		} else if (!isEmail(email)) {
			errors.email = ['E-mail inválido'];
		}
		if (size(mensagem) === 0) {
			errors.mensagem = ['Informe o texto da mensagem'];
		}
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			const from = `${nome} <${email}>`;
			const message = `${mensagem}\n\n${from}${size(telefone) > 0 ? `\nTelefone: ${telefone}` : ''}`.replace(
				/\n/g,
				'<br>'
			);
			accessApi('/api/mensagem-publica-comissao-eleitoral', false, {
				method: 'post',
				data: { from, message: message.replace('\n', '<br />') }
			});
			createMessage('email enviado', 2);
			onCancel();
		}
	}, [nome, email, mensagem, telefone, createMessage, onCancel]);

	return loadingCalendario ? (
		<Loader msg="Carregando dados do calendário..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendario']} />
	) : authenticated && userProfile ? (
		<div className={isEmbedded ? '' : 'container'}>
			{!isEmbedded && calendario && !isAfter('fimInscricoesChapasDelegados') && <Navigation />}
			<form>
				<h3>Mensagem para a Comissão Eleitoral</h3>
				<div className="row">
					<div className="form-group col-12">
						<label className="required">Nome</label>
						<input
							type="text"
							className={`form-control${errors.nome ? ' form-control-error' : ''}`}
							value={nome}
							onChange={e => setNome(e.target.value)}
						/>
						{errors.nome && <ErrorMessages errorList={errors.nome} />}
					</div>
				</div>
				<div className="row">
					<div className="form-group col-md-8">
						<label className="required">E-mail</label>
						<input
							type="text"
							className={`form-control${errors.email ? ' form-control-error' : ''}`}
							value={userProfile.email}
							disabled
						/>
						{errors.email && <ErrorMessages errorList={errors.email} />}
					</div>
					<div className="form-group col-md-4">
						<label className="">Telefone</label>
						<InputMask
							className={`form-control${errors.telefone ? ' form-control-error' : ''}`}
							name="fone"
							value={telefone || ''}
							onChange={e => setTelefone(e.target.value)}
							autoComplete="off"
							mask={size(telefone) >= 6 && telefone.split('')[5] === '9' ? '(99) 99999-9999' : '(99) 9999-9999'}
							alwaysShowMask={false}
						/>
						{errors.telefone && <ErrorMessages errorList={errors.telefone} />}
					</div>
				</div>
				<div className="row">
					<div className="form-group col-12">
						<label className="required">Mensagem</label>
						<textarea
							className={`form-control${errors.mensagem ? ' form-control-error' : ''}`}
							value={mensagem}
							rows="12"
							onChange={e => setMensagem(e.target.value)}
						/>
						{errors.mensagem && <ErrorMessages errorList={errors.mensagem} />}
					</div>
				</div>
				<div className="buttons">
					<button type="button" className="btn btn-outline-danger" onClick={onCancel}>
						<i className="fa fa-times" aria-hidden="true"></i>Cancelar
					</button>
					<button type="button" className="btn btn-primary" onClick={submeteForm}>
						<i className="fa fa-save" aria-hidden="true"></i>Enviar Mensagem
					</button>
				</div>
			</form>
		</div>
	) : (
		<>
			<div className="container">
				<div>
					Carregando informações do usuário ...&nbsp;
					<i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>&nbsp;
					<br></br>
				</div>
				<div className="alert alert-warning">Vocês precisa estar logado para encaminhar um e-mail</div>
				<div className="buttons">
					<button type="button" className="btn btn-outline-danger" onClick={() => history.push('/publica')}>
						<i className="fa fa-times" aria-hidden="true"></i>Cancelar
					</button>
				</div>
			</div>
		</>
	);
}
Email.displayName = 'Email';
Email.propTypes = {
	isEmbedded: PropTypes.bool,
	onCancel: PropTypes.func
};

export default Email;
