import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { useQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';

import gql from 'graphql-tag';
import moment from 'moment';

import ErrorMessages from '../../components/ErrorMessages';
import Loader from '../../components/Loader';
import PaginationV2 from '../../components/PaginationV2';
import SearchBar from '../../components/SearchBar';
import useCalendario from '../../customHooks/useCalendario';
import { setCount, setPage, setSearchTerm } from '../../Redux/delegados/delegadosSlice';
import { history } from '../../Redux/store';
import { isDebug, isExternal } from '../../utils/tools';

const PAGE_SIZE = 10;

function DelegadosComponent() {
	/* REDUX */
	const dispatch = useDispatch();
	const searchTerm = useSelector(state => state.delegados?.searchTerm);
	const page = useSelector(state => state.delegados?.page);
	const count = useSelector(state => state.delegados?.count);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const searchRef = useRef();
	const [delegados, setDelegados] = useState(null);
	const [inicial, setInicial] = useState(0);
	const [final, setFinal] = useState(0);
	const [termo, setTermo] = useState(
		JSON.stringify({
			term: isExternal ? 'NINGUÉM TEM' : '',
			sort: { numero: 1 }
		})
	);
	const email = useRef();

	const {
		data: dataDelegados,
		loading: loadingDelegados,
		errors: errorDelegados,
		refetch: refetchDelegados
	} = useQuery(
		gql`
			query deepSearch($term: String, $skip: Int, $limit: Int) {
				list: ECmduaDelegadoDeepSearch(term: $term, skip: $skip, limit: $limit) {
					id
					regiao {
						id
						nome
					}
					delegado {
						id
						nome
						dataNascimento
						regiao {
							id
							nome
						}
					}
					calendario
					situacao
					usuarioCriador
				}
				count: ECmduaDelegadoDeepSearchCount(term: $term)
			}
		`,
		{
			variables: {
				skip: page * PAGE_SIZE,
				limit: PAGE_SIZE,
				term: termo
			},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const prepareTermo = useCallback(
		searchTerm => {
			const termoAux = {
				term: searchTerm,
				usuarioCriador: isExternal ? email.current : undefined,
				calendario: calendario?.calendario,
				sort: { 'delegado.nome': 1 }
			};
			setTermo(JSON.stringify(termoAux));
		},
		[calendario]
	);

	useEffect(() => {
		if (userProfile && !email.current) {
			email.current = userProfile.email;
			prepareTermo(searchTerm);
			refetchDelegados();
		}
	}, [prepareTermo, refetchDelegados, userProfile, searchTerm]);

	useEffect(() => {
		if (dataDelegados) {
			setDelegados(dataDelegados.list);
			dispatch(setCount(dataDelegados.count));
			if (searchRef.current) {
				searchRef.current.focus();
			}
		}
	}, [dispatch, dataDelegados]);

	useEffect(() => {
		if (count !== null) {
			const inicial = page * PAGE_SIZE + 1;
			setInicial(inicial > count ? count : inicial);
			const final = page + 1 * PAGE_SIZE;
			setFinal(final > count ? count : final);
		}
	}, [page, count]);

	const setDispatchPage = useCallback(
		page => {
			dispatch(setPage(page));
		},
		[dispatch]
	);

	const isForaPeriodo = useMemo(
		() =>
			!isDebug &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimInscricoesChapasDelegados')),
		[calendario, isAfter]
	);

	return loadingDelegados || loadingCalendario ? (
		<Loader msg="Carregando delegados..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendário']} />
	) : errorDelegados ? (
		<ErrorMessages errorList={['Problemas ao carregar as delegados']} />
	) : (
		<div>
			<div className="titulo-pagina">
				<h1>Delegados</h1>
				{!isForaPeriodo && (
					<div className="buttons">
						<button type="button" className="btn btn-primary" onClick={() => history.push('/delegado/new')}>
							<i className="fa fa-plus" aria-hidden="true"></i>Novo Delegado
						</button>
					</div>
				)}
			</div>
			<SearchBar
				searchRef={searchRef}
				placeholder="Pesquisar por nome ou CPF dos concorrente ou por nome da região"
				setSearchTerm={setSearchTerm}
				searchTermSelector="delegados.searchTerm"
				onSubmit={prepareTermo}
			/>

			{count !== null && (
				<p>
					<strong>{count}</strong> delegado{count === 1 ? '' : 's'}
				</p>
			)}
			<ul className="list">
				{delegados?.map((delegado, i) => (
					<li
						key={delegado.id}
						className={`list-items${i % 2 === 0 ? ' even' : ''}`}
						onClick={() => history.push(`/delegado/${delegado.id}`)}
					>
						{delegado.delegado?.nome}
					</li>
				))}
			</ul>
			{count !== null && (
				<p>
					<strong>
						{inicial} - {final}
					</strong>{' '}
					de <strong>{count}</strong>
				</p>
			)}
			{count !== null && <PaginationV2 totalItems={count} pageSize={PAGE_SIZE} page={page} setPage={setDispatchPage} />}
		</div>
	);
}
DelegadosComponent.displayName = 'DelegadosCompoent';
DelegadosComponent.propTypes = {};

export default DelegadosComponent;
