import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	current: null,
	updating: false,
	message: null,
	validationMessages: [],
	situacaoAnalise: null
};

export const delegadoSlice = createSlice({
	name: 'delegado',
	initialState: INITIAL_STATE,
	reducers: {
		// saga actions
		saveDelegado: () => {},
		removeDelegado: () => {},
		// reducer actions
		setCurrent: (state, action) => {
			state.current = action.payload;
		},
		setUpdating: (state, action) => {
			state.updating = action.payload;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		setValidationMessages: (state, action) => {
			state.validationMessages = action.payload;
		},
		setSituacaoAnalise: (state, action) => {
			state.situacaoAnalise = action.payload;
		},
		limpaStore: state => {
			state = INITIAL_STATE;
			return state;
		}
	}
});

const { actions, reducer: delegadoReducer } = delegadoSlice;

export const {
	saveDelegado,
	removeDelegado,
	setCurrent,
	setUpdating,
	setMessage,
	setValidationMessages,
	setSituacaoAnalise,
	limpaStore
} = actions;

export default delegadoReducer;
