import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from './ErrorMessages';

const ModalMessage = ({ showPopupMessage, hidePopupMessage, validationMessages = [] }) => (
	<div
		className="modal"
		style={{
			backgroundColor: 'rgba(0,0,0,.5)',
			display: 'flex',
			flexFlow: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		}}
		role="dialog"
	>
		<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
			<div className="modal-content">
				<div className="modal-header">
					<h1 className="h3">
						{showPopupMessage.type === 'error' ? 'Atenção!' : 'Tudo certo!'}
						<i
							className={`fa modal-title-icon ${
								showPopupMessage.type === 'error'
									? 'modal-title-error fa-times-circle '
									: 'modal-title-success fa-check-circle '
							}`}
							aria-hidden="true"
						></i>
					</h1>
					<button
						type="button"
						className="close"
						onClick={hidePopupMessage}
						aria-label="Fechar"
						style={{
							float: 'right',
							fontSize: '1.5rem',
							fontWeight: '700',
							lineHeight: '1',
							color: '#000',
							textShadow: '0 1px 0 #fff',
							opacity: '.5',
							backgroundColor: 'transparent',
							border: 0,
							appearance: 'none'
						}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					{size(showPopupMessage.messages) > 0 && Array.isArray(showPopupMessage.messages) ? (
						<>
							{showPopupMessage.messages.map((msg, i) => (
								<div key={`modal-msg-array-${i}`}>{size(msg.message) > 0 ? msg.message : msg}</div>
							))}
						</>
					) : (
						<div>{size(showPopupMessage.message) > 0 ? showPopupMessage.message : ''}</div>
					)}

					{size(validationMessages) > 0 && <ErrorMessages errorList={validationMessages} />}
				</div>
				<div className="modal-footer">
					<div className="buttons">
						<button
							type="button"
							className={`btn ${showPopupMessage.type === 'error' ? 'btn-danger' : 'btn-success'}`}
							onClick={hidePopupMessage}
						>
							Ok
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
);

ModalMessage.displayName = 'ModalMessage';

ModalMessage.propTypes = {
	showPopupMessage: PropTypes.object,
	hidePopupMessage: PropTypes.func,
	validationMessages: PropTypes.array
};

export default ModalMessage;
