import { takeLatest, call, put } from 'redux-saga/effects';

import { send } from '../../utils/tools';
import { history } from '../store';
import { saveEntidade, removeEntidade, setMessage, setUpdating, limpaStore } from './entidadeSlice';

const getAction = (type, payload) => ({
	type,
	payload
});

function* saveEntidadeSaga(action) {
	yield put(setUpdating(true));
	try {
		const entidade = { ...action.payload };
		const origem = entidade.origem;
		delete entidade.origem;

		// convert objects to id's to save as references
		entidade.categoria = entidade.categoria?.id;
		entidade.titular = entidade.titular?.id;
		entidade.suplente1 = entidade.suplente1?.id;
		entidade.suplente2 = entidade.suplente2?.id;

		const response = yield call(send, getAction('entidade/salvar', { entidade }), '');
		const { ok, output } = response.data;
		console.debug('output: ', output);
		if (ok === 1) {
			yield put(
				setMessage(
					{
						message: `${origem === 'edicao' ? 'Edição' : 'Inscrição'} da Entidade realizada com sucesso!`,
						type: 'success',
						origin: origem
					},
					20
				)
			);
		} else {
			yield put(
				setMessage(
					{
						message: `Erro ao ${origem === 'edicao' ? 'editar' : 'inscrever'} a entidade!`,
						type: 'error',
						origin: origem
					},
					20
				)
			);
		}
	} catch (err) {
		console.error('err: ', err);
		yield put(setMessage({ message: 'Ocorreu um erro no saga ao salvar a entidade!', type: 'error' }, 20));
	}
	yield put(setUpdating(false));
}

function* removeEntidadeSaga(action) {
	yield put(setUpdating(true));
	try {
		const response = yield call(send, getAction('entidade/remover', { id: action.payload }), '');
		const { ok, output } = response.data;
		console.debug('output: ', output);
		if (ok === 1) {
			yield put(limpaStore());
			yield put(setMessage('Entidade removida com sucesso!'));
			history.go(-1);
		} else {
			yield put(setMessage('Erro ao remover entidade!'));
		}
	} catch (err) {
		yield put(setMessage('Ocorreu um erro no saga ao remover entidade!'));
	}
	yield put(setUpdating(false));
}

function* watcher() {
	yield takeLatest(saveEntidade.type, saveEntidadeSaga);
	yield takeLatest(removeEntidade.type, removeEntidadeSaga);
}

export default watcher;
