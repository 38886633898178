import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
	name: 'home',
	initialState: {
		aba: null,
		calendario: null
	},
	reducers: {
		setAba: (state, action) => {
			state.aba = action.payload;
		},
		setCalendario: (state, action) => {
			state.calendario = action.payload;
		}
	}
});

const { actions, reducer: homeReducer } = homeSlice;

export const { setAba, setCalendario } = actions;

export default homeReducer;
