import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from '../../../components/ErrorMessages';
import { isDebug } from '../../../utils/tools';

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[APURACAO-REGIAO ${i++}]:`, ...args);

function RegiaoApuracao({ regiao, errors, emAndamento, onReport, toggleChapa, toggleDelegado }) {
	useEffect(() => {
		if (regiao) {
			debugLog('regiao: ', regiao);
		}
	}, [regiao]);

	return (
		<>
			<div className="titulo-pagina">
				<h2 className="h3 mt-3">
					{regiao.nome}&nbsp;&nbsp;
					<small className={`mt-3 ${emAndamento ? 'text-danger' : 'text-success'}`}>
						{emAndamento ? 'em andamento' : 'finalizada'}
					</small>
				</h2>

				{regiao && (size(regiao.chapas) > 0 || size(regiao.delegados) > 0) && (
					<div className="buttons">
						<button type="button" className="btn btn-outline-primary" onClick={onReport}>
							Gerar Relatório da Apuração da Região
						</button>
					</div>
				)}
			</div>
			{size(regiao.chapas) > 0 && (
				<div className="apuracao-chapas">
					<hr />
					<h3>CHAPAS</h3>
					<strong>Quantidade total de votos: {regiao.totalVotosChapas}</strong>
					{[''].map(() => {
						const { chapaEmpate } = regiao;
						return (
							<Fragment key={'unica'}>
								<div className="row list-items apuracao-header">
									<div className="col-md-1">#</div>
									<div className="text-right col-md-2">Chapa</div>
									<div className="text-right col-md-4">{chapaEmpate ? 'Dt.Nasc' : ''}</div>
									<div className="text-right col-md-1">Votos</div>
									<div className="text-right col-md-1">%</div>
									<div className="col-md-2">Resultado</div>
									<div className="col-md-1">Ação</div>
								</div>
								<div className="votados">
									{regiao.chapas.map((chapa, index) => {
										const {
											id,
											votos,
											numero,
											className,
											resultado,
											dataNascimentoTitular,
											numeroExtenso,
											percentual
										} = chapa;
										return (
											<div key={id} className={`row list-items apuracao${index % 2 === 0 ? ' even' : ''}${className}`}>
												<div className="col-md-1">{index + 1}</div>
												<div className="text-right col-md-2">
													{numero} ({numeroExtenso})
												</div>
												<div className="text-right col-md-4">{dataNascimentoTitular}</div>
												<div className="text-right col-md-1">{votos}</div>
												<div className="text-right col-md-1">{percentual}</div>
												<div className="col-md-2">{resultado}</div>
												<div className="col-md-1">
													{className === ' empatada' && (
														<button type="button" className="btn-link" onClick={toggleChapa(chapa)}>
															alternar
														</button>
													)}
												</div>
											</div>
										);
									})}
								</div>
							</Fragment>
						);
					})}
				</div>
			)}
			{size(errors?.chapaEleita) > 0 && <ErrorMessages errorList={errors.chapaEleita} />}
			{size(regiao.delegados) > 0 && (
				<div className="apuracao-delegados">
					<hr />
					<h3>DELEGADOS</h3>
					<strong>Quantidade total de votos: {regiao.totalVotosDelegados}</strong>
					<br />
					<strong>Quantidade de vagas disponíveis: {regiao.totalDelegadosEleitos}</strong>
					{[''].map(() => {
						const { delegadoEmpate, delegadoVagasEmpate, qtdDelegadosAlterados } = regiao;
						return (
							<Fragment key={'única'}>
								{delegadoEmpate && (
									<>
										<strong>Quantidade de vagas disponíveis para os empatados: {delegadoVagasEmpate}</strong> <br />
									</>
								)}
								{delegadoEmpate && <strong>Quantidade de delegados já desempatados: {qtdDelegadosAlterados}</strong>}
								<div className="row list-items apuracao-header" style={{ marginTop: '30px' }}>
									<div className="col-md-1">#</div>
									<div className="col-md-4">Delegado</div>
									<div className="text-right col-md-2">{delegadoEmpate ? 'Dt.Nasc' : ''}</div>
									<div className="text-right col-md-1">Votos</div>
									<div className="text-right col-md-1">%</div>
									<div className="col-md-2">Resultado</div>
									<div className="col-md-1">Ação</div>
								</div>
								<div className="votados">
									{regiao.delegados.map((delegado, index) => {
										const { id, className, percentual, resultado, dataNascimento, votos, nome } = delegado;
										return (
											<div key={id} className={`row list-items apuracao${index % 2 === 0 ? ' even' : ''}${className}`}>
												<div className="col-md-1">{index + 1}</div>
												<div className="col-md-4">{nome}</div>
												<div className="text-right col-md-2">{dataNascimento}</div>
												<div className="text-right col-md-1">{votos}</div>
												<div className="text-right col-md-1">{percentual}</div>
												<div className="col-md-2">{resultado}</div>
												<div className="col-md-1">
													{className === ' empatada' && (
														<button type="button" className="btn-link" onClick={toggleDelegado(delegado)}>
															alternar
														</button>
													)}
												</div>
											</div>
										);
									})}
								</div>
							</Fragment>
						);
					})}
				</div>
			)}
			{size(errors?.delegadosEleitos) > 0 && <ErrorMessages errorList={errors.delegadosEleitos} />}
		</>
	);
}
RegiaoApuracao.displayName = 'RegiaoApuracao';
RegiaoApuracao.propTypes = {
	regiao: PropTypes.object,
	errors: PropTypes.object,
	emAndamento: PropTypes.bool,
	toggleChapa: PropTypes.func,
	toggleDelegado: PropTypes.func,
	onReport: PropTypes.func
};

export default RegiaoApuracao;
