import React from 'react';

const NotFoundPage = () => (
	<div className="error-screen">
		<h1 className="error-header">
			404 <span className="error-label">Página não existente</span>
		</h1>
		<p>O recurso que você procura não está disponível no momento.</p>
	</div>
);
NotFoundPage.displayName = 'NotFoundPage';
export default NotFoundPage;
