import isExternal from '../utils/external';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://e-cmdua-api-des.procempa.com.br';
export const SSO_URL =
	process.env.REACT_APP_SSO_URL ||
	(isExternal ? 'https://acessopoa-hom.procempa.com.br/auth' : 'https://sso-pmpa-des.procempa.com.br/auth');
export const API_URL = `${SERVER_URL}/api`;
export const GRAPHQL_URL = `${SERVER_URL}/graphql`;

console.debug('usando SERVER_URL', SERVER_URL);
console.debug('usando SSO_URL', SSO_URL);
if (
	SERVER_URL.indexOf('10.110') > -1 ||
	SERVER_URL.indexOf('localhost') > -1 ||
	SERVER_URL.indexOf('-des') > -1 ||
	SERVER_URL.indexOf('-hom') > -1
) {
	console.debug('process.env: ', process.env);
}
