import { configureStore } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';

import { isProd } from '../../utils/tools';
import chapaSaga from '../chapa/chapaSaga';
import chapaReducer from '../chapa/chapaSlice';
import chapasReducer from '../chapas/chapasSlice';
import delegadoSaga from '../delegado/delegadoSaga';
import delegadoReducer from '../delegado/delegadoSlice';
import delegadosReducer from '../delegados/delegadosSlice';
import entidadeSaga from '../entidade/entidadeSaga';
import entidadeReducer from '../entidade/entidadeSlice';
import entidadesReducer from '../entidades/entidadesSlice';
import homeReducer from '../Home/homeSlice';
import keycloakReducer from '../keycloak/keycloakSlice';
import participanteSaga from '../participante/participanteSaga';
import participanteReducer from '../participante/participanteSlice';
import participantesReducer from '../participantes/participantesSlice';
import votacaoReducer from '../Votacao/votacaoSlice';

export const history = createBrowserHistory();

const initializeStore = initialState => {
	const sagaMiddleware = createSagaMiddleware();
	const store = configureStore({
		reducer: {
			router: connectRouter(history),
			home: homeReducer,
			chapa: chapaReducer,
			chapas: chapasReducer,
			delegado: delegadoReducer,
			delegados: delegadosReducer,
			entidade: entidadeReducer,
			entidades: entidadesReducer,
			keycloak: keycloakReducer,
			participante: participanteReducer,
			participantes: participantesReducer,
			votacao: votacaoReducer
		},
		middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
		preloadedState: initialState,
		devTools: !isProd
	});

	sagaMiddleware.run(chapaSaga);
	sagaMiddleware.run(delegadoSaga);
	sagaMiddleware.run(entidadeSaga);
	sagaMiddleware.run(participanteSaga);

	return store;
};

export default initializeStore;
