import React from 'react';

import { Switch, Route, Redirect } from 'react-router';

import { ConnectedRouter } from 'connected-react-router/immutable';

import AccessDenied from './components/403Page';
import NotFoundPage from './components/404Page';
import ClientErrorPage from './components/4xxPage';
import ServerUnavailablePage from './components/503Page';
import Footer from './components/Footer';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Chapa from './containers/Chapa/chapa';
import Delegado from './containers/Delegado/delegado';
import Email from './containers/Email/email';
import SolicitacaoImpugnacaoChapa from './containers/Email/solicitacao-impgunacao-chapa';
import SolicitacaoImpugnacaoEntidade from './containers/Email/solicitacao-impgunacao-entidade';
import Entidade from './containers/Entidade/entidade';
import Home from './containers/Home/home';
import Participante from './containers/Participante/participante';
import Publica from './containers/Publica/publica';
import { history } from './Redux/store';
import { isExternal } from './utils/tools';

const AppRouter = () => (
	<ConnectedRouter history={history}>
		<Header />
		<Switch id="switch">
			<PrivateRoute path="/accessdenied" component={AccessDenied} />
			<PrivateRoute path="/chapa/:id" component={Chapa} roles={isExternal ? null : ['urbanismo-cmdua-admin']} />
			<PrivateRoute
				path="/participante/:id"
				component={Participante}
				roles={isExternal ? null : ['urbanismo-cmdua-cad-participante']}
			/>
			<PrivateRoute path="/delegado/:id" component={Delegado} roles={isExternal ? null : ['urbanismo-cmdua-admin']} />
			<PrivateRoute path="/entidade/:id" component={Entidade} roles={isExternal ? null : ['urbanismo-cmdua-admin']} />
			<PrivateRoute exact path="/home" component={Home} />
			<PrivateRoute exact path="/email" component={Email} />
			<PrivateRoute exact path="/solicitacao-impugnacao-chapa" component={SolicitacaoImpugnacaoChapa} />
			<PrivateRoute exact path="/solicitacao-impugnacao-entidade" component={SolicitacaoImpugnacaoEntidade} />
			<Route exact path="/publica" component={Publica} />
			<Route exact path="/" render={() => <Redirect to={isExternal ? '/publica' : '/home'} />} />
			<Route path="/notfound" component={NotFoundPage} />
			<Route path="/serverUnavailable" component={ServerUnavailablePage} />
			<Route path="/clientError" component={ClientErrorPage} />
			<Route component={NotFoundPage} />
		</Switch>
		<Footer />
	</ConnectedRouter>
);

AppRouter.displayName = 'AppRouter';

export default AppRouter;
