import axios from 'axios';
import { fromJS, isImmutable, List, Map } from 'immutable';
import {
	capitalize,
	findIndex,
	get,
	includes,
	isNil,
	join,
	lowerCase,
	map,
	padStart,
	replace,
	size,
	split,
	trim,
	reduce,
	isObject,
	some,
	isArray,
	trimStart
} from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { buffers, END, eventChannel } from 'redux-saga';

import { API_URL } from '../environments';
import { keycloakObject } from '../index';
import { EMAIL_REGEX, REGEX_ADDRESS, REGEX_TELEFONE } from '../utils/constants';
import external from './external';
import { DIACRITICS, DISCARD_WORDS } from './words';

const IGNORE_PROPERTIES_REGEX = [/^(__)/i];

const cleanTypeName = (
	obj // TODO: resolver o caso de arrays
) =>
	isArray(obj)
		? map(obj, o => (typeof o === 'string' || typeof o === 'number' ? o : cleanTypeName(o)))
		: reduce(
				obj,
				(acc, v, k) =>
					// eslint-disable-next-line no-nested-ternary
					isObject(v)
						? { ...acc, [k]: cleanTypeName(v) }
						: some(IGNORE_PROPERTIES_REGEX, r => r.test(k))
						? acc
						: { ...acc, [k]: v },
				{}
		  );
/**
			Duas funções possiveis:
			1 para os métodos do eventHandler, onde o fluxo no
				Node-Red é determinado pela action.type, o action é um objeto como no exemplo:
				{
					type: 'formulario/SERVER_SAVE_FORM_DATA',
					payload: { ... },
					next: 'REFRESH_PAGE'
				},
				true // opcional

			2	para os métodos de API externa, onde o fluxo do Node-Red é determinado pela
				URL do nodo Http-In, dois parametros são necessários, como no exemplo:
				{
					url: 'processo/${this.props.match.params.id}/planilha-unifamiliar',
					payload: { ... }
				},
				false // para indicar que não é método do eventHandler
*/
export const send = async (action, timeout = 0) => {
	let token;
	if (get(keycloakObject, 'authenticated')) {
		const result = await new Promise(resolve =>
			keycloakObject
				.updateToken(5)
				.then(() => resolve(true))
				.catch(() => resolve(false))
		);
		if (result) {
			token = `Bearer ${keycloakObject.token}`;
		} else {
			keycloakObject.logout();
		}
	}

	let url = `${API_URL}/eventhandler`;

	if (action.payload.queryString) {
		const queryStringObj = { ...action.payload.queryString };
		const qs = queryString.stringify(queryStringObj);
		url = `${url}?${qs}`;
		delete action.payload.queryString;
	}

	const options = {
		method: 'post',
		url: url,
		data: cleanTypeName(action),
		headers: token ? { Authorization: token } : {},
		timeout: timeout
	};
	return axios(options);
};

export const sendApi = async action => {
	let token;
	if (get(keycloakObject, 'authenticated')) {
		const result = await new Promise(resolve =>
			keycloakObject
				.updateToken(5)
				.then(() => resolve(true))
				.catch(() => resolve(false))
		);
		if (result) {
			token = `Bearer ${keycloakObject.token}`;
		} else {
			keycloakObject.logout();
		}
	}

	return axios({
		method: 'post',
		url: `${API_URL}/${action.url}`,
		data: action.payload,
		headers: token ? { Authorization: token } : {}
	});
};

export const accessApi = async (endpoint, publicApi = false, options = {}) => {
	// para envio de um corpo na requisição, options.data

	let token;
	if (!publicApi && get(keycloakObject, 'authenticated')) {
		const result = await new Promise(resolve =>
			keycloakObject
				.updateToken(5)
				.then(() => resolve(true))
				.catch(() => resolve(false))
		);
		if (result) {
			token = `Bearer ${keycloakObject.token}`;
		} else {
			keycloakObject.logout();
		}
	}
	let url = null;

	if (endpoint.startsWith('http')) {
		url = endpoint;
	} else {
		url = endpoint;

		if (url.startsWith('/')) {
			url = url.substring(1);
		}

		if (url.startsWith('api/')) {
			url = url.substring(4);
		}
		url = `${API_URL}/${url}`;
	}
	if (!options?.method) {
		options = options || {};
		options.method = 'get';
	}
	return axios({
		url,
		headers: token ? { Authorization: token } : {},
		...options
	});
};

const stringify = o => {
	let cache = [];
	return JSON.stringify(
		o,
		(key, value) => {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value) !== -1) {
					return;
				}
				cache.push(value);
			}
			return value;
		},
		2
	);
};

/*
	Formata um número com uma máscara.
	Exemplo format('2263924006', '000.000000.00.0') => 002.263924.00.6
*/
const format = (numero = '', mascara = '') => {
	const exp = /-|\.|\/|\(|\)|@|%|\$|,| /g;
	const max = mascara.replace(exp, '').length;
	const numeroCompleto = padStart(trimStart((numero || '').toString().replace(exp, ''), '0'), max, '0');
	let size = mascara.length;
	let formatado = '';
	let j = 0;
	for (let i = 0; i < size; i++) {
		if (
			mascara
				.charAt(i)
				.toString()
				.match(exp)
		) {
			formatado += mascara.charAt(i);
			size++;
		} else {
			formatado += numeroCompleto.charAt(j);
			j++;
		}
	}
	return formatado;
};

const titleCase = texto =>
	join(
		map(split(texto, ' '), (w, i) => {
			if ((size(w) > 2 || i === 0) && !includes(DISCARD_WORDS, lowerCase(w))) {
				return capitalize(w) || w;
			} else {
				return (w && w.toLowerCase()) || w;
			}
		}),
		' '
	);

const removeSpaces = texto => {
	let text = trataDigitacaoComEspacos(texto);
	if (text && text.length > 0) {
		text = replace(text, /(\s{2,})/g, ' ');
		text = trim(text);
	}
	return text;
};

const trataDigitacaoComEspacos = texto => {
	let saida = texto;
	const qtdBrancos = saida.split('').reduce((acc, c) => (acc += c === ' ' ? 1 : 0), 0);
	const perc = (qtdBrancos / saida.length) * 100;
	// se mais de 40% dos carteres são espaços
	if (perc >= 40) {
		//remove cada espaço que segue-se a um caracter
		saida = saida.replace(/(\S)\s/g, '$1');
	}
	return saida;
};

const calculaDigitoMod11 = (dado, numDig = 1, limiteMultiplicador = 9, x10 = true) => {
	dado = dado.toString();
	let multiplicador, soma, digito;

	if (!x10) numDig = 1;

	for (let numeroDigito = 1; numeroDigito <= numDig; numeroDigito++) {
		soma = 0;
		multiplicador = 2;
		for (let i = dado.length - 1; i >= 0; i--) {
			soma += multiplicador * parseInt(dado.charAt(i));
			if (++multiplicador > limiteMultiplicador) multiplicador = 2;
		}
		if (x10) {
			digito = ((soma * 10) % 11) % 10;
		} else {
			digito = soma % 11;
			if (digito === 10) digito = 'X';
		}
		dado += digito;
	}
	return dado.substr(dado.length - numDig, numDig);
};

const sortCadastros = (arr = [], property = 'codigo', ordem = 'crescente') => {
	let arrJS = (Map.isMap(arr) ? arr.toJS() : arr) || [];

	let aux = ordem === 'crescente' ? -1 : 1;

	let saida = [];
	if (size(arrJS) > 0) {
		arrJS.forEach(i => saida.push({ ...i }));
	}

	if (saida && saida.length > 1) {
		saida.sort((r1, r2) => {
			if (parseInt(r1[property]) === r1[property] && parseInt(r2[property]) === r2[property]) {
				return parseInt(r1[property]) < parseInt(r2[property]) ? aux : aux * -1;
			}
			if (typeof r1[property] === 'string' && typeof r2[property] === 'string') {
				return r1[property].trim().toLowerCase() < r2[property].trim().toLowerCase() ? aux : aux * -1;
			}
			return r1[property] < r2[property] ? aux : aux * -1;
		});
	}
	return saida;
};

const permiteInput = (value, type, decs, size = 9) => {
	let ok = true;
	if (includes(['process', 'sei', 'processo'], type)) {
		const regex = new RegExp(`^$|^[0-9\\.\\-]{1,${size}}$`);
		ok = regex.test(value);
	} else if (includes(['validnumber'], type)) {
		const regexStr = `^$|^[1-9]{1}[0-9]{0,${size - 1}}$`;
		const regex = new RegExp(regexStr);
		ok = regex.test(value);
	} else if (includes(['number', 'integer', 'int', 'long'], type)) {
		const regexStr = `^$|^[0-9]{1,${size}}$`;
		const regex = new RegExp(regexStr);
		ok = regex.test(value);
	} else if (includes(['float', 'currency'], type)) {
		const regex = new RegExp(`^$|^[1-9]{1}\\d{0,${size - 1}}(,\\d{0,${decs}})?$`);
		ok = regex.test(value);
	} else if (includes(['string'], type)) {
		const regex = new RegExp(`^$|^\\S{1}.{0,${size - 1}}$`);
		ok = regex.test(value);
		ok = ok && !/\s\s/.test(value); // não permite espaços duplos
	} else if (includes(['text'], type)) {
		ok = ok && !/\d/.test(value); // não permite números
		ok = ok && !/^\s/.test(value); // não permite espaços no início
		ok = ok && !/\s\s/.test(value); // não permite espaços duplos
	} else if (includes(['email'], type)) {
		const iniciaComNumero = /^\d/.test(value);
		const caracteresInvalidos = /[^a-z0-9\-_.@]/.test(value);
		ok = ok && !iniciaComNumero;
		ok = ok && !caracteresInvalidos;
	}
	return ok;
};

/* Formata numeros de telefone no formato (00) 00000-0000 ou (00) 0000-0000 */
const formataTelefone = v => {
	var texto = v;

	texto = texto.replace(/[^\d]/g, '');

	if (texto.length > 0) {
		texto = `(${texto}`;

		if (texto.length > 3) {
			texto = [texto.slice(0, 3), ') ', texto.slice(3)].join('');
		}
		if (texto.length > 12) {
			if (texto.length > 13) texto = [texto.slice(0, 10), '-', texto.slice(10)].join('');
			else texto = [texto.slice(0, 9), '-', texto.slice(9)].join('');
		}
		if (texto.length > 15) texto = texto.substr(0, 15);
	}
	return texto;
};

/* Formata valores numericos para CPF (ate 11 digitos) ou para CNPJ (ate 14 digitos) */
const cpfCnpj = v => {
	//Remove tudo o que não é dígito
	v = v.replace(/\D/g, '');

	if (v.length < 14) {
		//CPF

		//Coloca um ponto entre o terceiro e o quarto dígitos
		v = v.replace(/(\d{3})(\d)/, '$1.$2');

		//Coloca um ponto entre o terceiro e o quarto dígitos
		//de novo (para o segundo bloco de números)
		v = v.replace(/(\d{3})(\d)/, '$1.$2');

		//Coloca um hífen entre o terceiro e o quarto dígitos
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	} else {
		//CNPJ

		//Coloca ponto entre o segundo e o terceiro dígitos
		v = v.replace(/^(\d{2})(\d)/, '$1.$2');

		//Coloca ponto entre o quinto e o sexto dígitos
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

		//Coloca uma barra entre o oitavo e o nono dígitos
		v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');

		//Coloca um hífen depois do bloco de quatro dígitos
		v = v.replace(/(\d{4})(\d)/, '$1-$2');
	}

	return v;
};

const isCPF = cpf => {
	if (!cpf) return false;
	let soma;
	let resto;
	soma = 0;
	let strCPF = replace(cpf, /(\.|_|-)/g, '');
	if (strCPF === '00000000000' || strCPF.length !== 11) return false;

	for (let i = 1; i <= 9; i++) {
		soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
	}

	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) {
		resto = 0;
	}
	if (resto !== parseInt(strCPF.substring(9, 10))) {
		return false;
	}
	soma = 0;
	for (let i = 1; i <= 10; i++) {
		soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
	}
	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) resto = 0;
	if (resto !== parseInt(strCPF.substring(10, 11))) return false;
	return true;
};

const isCNPJ = cnpj => {
	if (!cnpj) return false;
	if (cnpj) {
		cnpj = cnpj.replace(/[^\d]+/g, '');

		if (cnpj.length !== 14) {
			return false;
		}

		// Elimina CNPJs invalidos conhecidos
		if (
			cnpj === '00000000000000' ||
			cnpj === '11111111111111' ||
			cnpj === '22222222222222' ||
			cnpj === '33333333333333' ||
			cnpj === '44444444444444' ||
			cnpj === '55555555555555' ||
			cnpj === '66666666666666' ||
			cnpj === '77777777777777' ||
			cnpj === '88888888888888' ||
			cnpj === '99999999999999'
		)
			return false;

		// Valida DVs
		let tamanho = cnpj.length - 2;
		let numeros = cnpj.substring(0, tamanho);
		let digitos = cnpj.substring(tamanho);
		let soma = 0;
		let pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
		if (`${resultado}` !== `${digitos.charAt(0)}`) {
			return false;
		}

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
		if (`${resultado}` !== `${digitos.charAt(1)}`) {
			return false;
		}
	}

	return true;
};

const isEmail = e => EMAIL_REGEX.test(e);
export const isTelefone = e => REGEX_TELEFONE.test(e);

function isDate(str, separator = '/', format = 'DD/MM/YYYY') {
	let day = null;
	let month = null;
	let year = null;
	const partes = format.split(separator);
	partes.forEach(parte => {
		if (parte === 'DD') {
			const index = format.indexOf(parte);
			day = parseInt(str.substring(index, index + 2), 10);
		} else if (parte === 'MM') {
			const index = format.indexOf(parte);
			month = parseInt(str.substring(index, index + 2), 10);
		} else if (parte === 'YYYY') {
			const index = format.indexOf(parte);
			year = parseInt(str.substring(index, index + 4), 10);
		} else if (parte === 'YY') {
			const index = format.indexOf(parte);
			year = parseInt(str.substring(index, index + 2), 10);
		}
	});
	const date = new Date(year, month - 1, day, 0, 0, 0, 0);
	return (
		month === date.getMonth() + 1 &&
		day === date.getDate() &&
		year === (format.length === 8 ? date.getYear() : date.getFullYear())
	);
}

const virgulaPonto = string =>
	typeof string === 'string'
		? string.replace(/,/g, '.')
		: Array.isArray(string)
		? string.map(s => virgulaPonto(s))
		: string;
const pontoVirgula = string =>
	typeof string === 'string'
		? string.replace(/\./g, ',')
		: Array.isArray(string)
		? string.map(s => pontoVirgula(s))
		: string;

const mergePropertyIntoState = (state, path, property, type, decs, tamanho) => {
	const key = Object.keys(property)[0];
	const value = Object.values(property)[0];

	state = stateWithoutError(state, key);
	let property2 = property;
	if (key.indexOf('.') > -1) {
		const parts = key.split('.');
		parts.reduce((acc, part, index) => (index < parts.length - 1 ? acc.push(part) : acc), path);
		const propName = key.substring(key.lastIndexOf('.') + 1);
		property2 = { [propName]: value };
	}

	if (permiteInput(value, type, decs, tamanho)) {
		let obj = state.getIn(path);
		if (obj) {
			state = state.mergeDeepIn(path, property2);
		} else {
			state = state.setIn(path, property2);
		}
	}

	return state;
};

const selectInArray = (array, id, property = 'id') => {
	const arrayJS = List.isList(array) ? array.toJS() : array;
	const index = findIndex(arrayJS, item => item[property] === id);
	return index > -1 ? arrayJS[index] : null;
};

const stateWithoutError = (state, property) => {
	const errors = fromJS(state.get('errors'));
	const errorsJS = errors && isImmutable(errors) ? errors.toJS() : errors;
	if (errorsJS) {
		const partes = property.split('.');
		let novo = partes.reduce((acc, p, i) => (i === partes.length - 1 ? acc : acc[p]), errorsJS);
		if (novo) {
			delete novo[partes[partes.length - 1]];
		}
		if (size(errorsJS) > 0) {
			state = state.set('errors', fromJS(errorsJS));
		} else {
			state = state.delete('errors');
		}
	}
	return state;
};

const normalizedAddress = str => (str || '').replace(REGEX_ADDRESS, '');

const clearObject = obj => {
	let newObj = {};
	for (var p in obj) {
		newObj[p] = obj[p] ? obj[p] : undefined;
	}
	return newObj;
};

const createUploadFileChannel = (endpoint, payload) => {
	const {
		idFormulario,
		idFormData,
		id,
		idDocumento,
		file,
		files,
		token,
		ordem,
		obrigatorio,
		tituloDocumento,
		complementadaEm,
		owner,
		fromSolicitante,
		extensao
	} = payload;

	return eventChannel(emitter => {
		const xhr = new XMLHttpRequest();

		const onProgress = e => {
			if (e.lengthComputable) {
				const progress = e.loaded / e.total;
				emitter({ progress });
			}
		};
		const onFailure = e => {
			emitter({ err: new Error(`Upload failed at ${e}`) });
			emitter(END);
		};
		xhr.upload.addEventListener('progress', onProgress);
		xhr.upload.addEventListener('error', onFailure);
		xhr.upload.addEventListener('abort', onFailure);
		xhr.onreadystatechange = () => {
			const { readyState, status, responseText } = xhr;
			if (readyState === 4) {
				if (status === 200) {
					emitter({ success: true, responseText });
					emitter(END);
				} else {
					onFailure(null);
				}
			}
		};
		let body = new FormData();
		body.set('idFormulario', `${idFormulario}`);
		body.set('idFormData', `${idFormData}`);
		body.set('id', `${id}`);
		body.set('idDocumento', `${idDocumento}`);
		body.set('ordem', `${ordem}`);
		body.set('obrigatorio', `${obrigatorio}`);
		body.set('tituloDocumento', `${tituloDocumento}`);
		body.set('extensao', `${extensao}`);
		if (complementadaEm) {
			body.set('complementadaEm', `${complementadaEm}`);
			body.set('owner', `${owner}`);
		}
		if (fromSolicitante) {
			body.set('fromSolicitante', `${fromSolicitante}`);
		}
		if (file) {
			body.set('file', file);
		}
		if (files) {
			files.forEach((f, i) => {
				body.set(`file${i}`, f);
			});
		}
		// body.set('filename', dadosImaged.filename);
		// body.set('indices', JSON.stringify(dadosImaged.indices));
		xhr.open('POST', endpoint, true);
		xhr.setRequestHeader('Authorization', `Bearer ${token}`);
		xhr.send(body);
		return () => {
			xhr.upload.removeEventListener('progress', onProgress);
			xhr.upload.removeEventListener('error', onFailure);
			xhr.upload.removeEventListener('abort', onFailure);
			xhr.onreadystatechange = null;
			xhr.abort();
		};
	}, buffers.sliding(2));
};

export const getUpdatedToken = () =>
	keycloakObject
		? new Promise(resolve => {
				keycloakObject
					.updateToken(5)
					.then(() => {
						resolve(keycloakObject.token);
					})
					.catch(() => keycloakObject.login());
		  })
		: Promise.reject('Sem token ainda');

const montaURL = (sistema, keepAsLocalhost = false) => {
	let url = null;
	let environment = null;
	const hostname = window.location.hostname;

	if (isLocalhost && keepAsLocalhost) {
		const port = 3000;
		url = `http://${hostname}:${port}`;
	} else {
		if (isLocalhost) {
			environment = '-des';
		} else {
			const app = hostname.replace('.procempa.com.br', '');
			environment = app.endsWith('-des') ? '-des' : app.endsWith('-hom') ? '-hom' : '';
		}

		url = `https://${sistema}${environment}.procempa.com.br`;
	}

	return url;
};

const CASAS_DECIMAIS_AREAS = 2;

const numericValue = original => {
	let numericValue = original;
	if (!isNil(original)) {
		const valueWithPoint = numericValue.replace(/[,]/, '.').replace(/[A-Z a-z]/, '');
		numericValue = size(valueWithPoint) > 0 ? Number(valueWithPoint) : null;
	}
	return numericValue;
};

const stringValue = original => {
	let stringValue = `${original || ''}`.replace(/\./, ',');
	const index = stringValue.indexOf(',');
	if (index > -1 && stringValue.length - index + 1 > 4) {
		stringValue = stringValue.substring(0, index + CASAS_DECIMAIS_AREAS + 1);
	}
	return stringValue;
};

/* formatação de número decimal */
const formatTypedNumber = typedNumber => {
	let formatNumber = null;

	// digitando 0
	if (typedNumber === '0' || typedNumber === '00') {
		formatNumber = '0';
	} else if (typedNumber === '0,') {
		formatNumber = '0,';
	} else if (typedNumber === '0,0') {
		formatNumber = '0,0';
	} else if (typedNumber === '0,00') {
		formatNumber = '0,00';
	}
	// permite que sejam digitados ',' e '0' após vírgula
	else if (typedNumber.charAt(typedNumber.length - 1) === ',') {
		formatNumber = `${stringValue(numericValue(typedNumber))},`;
	} else if (typedNumber.substr(typedNumber.length - 2, typedNumber.length) === ',0') {
		formatNumber = `${stringValue(numericValue(typedNumber))},0`;
	} else if (typedNumber.substr(typedNumber.length - 3, typedNumber.length) === ',00') {
		formatNumber = `${stringValue(numericValue(typedNumber))},00`;
	} else {
		formatNumber = stringValue(numericValue(typedNumber));
	}

	return formatNumber;
};

const ignoreDiacritics = term => {
	const saida = term
		? term
				.split('')
				.map(c => (DIACRITICS[c] ? DIACRITICS[c] : c))
				.join('')
		: term;
	return saida;
};

const removeDiacritics = term => {
	const saida = term
		? term
				.split('')
				.map(c => Object.keys(DIACRITICS).reduce((acc, key) => (DIACRITICS[key].includes(c) ? key : acc), c))
				.join('')
		: term;
	return saida;
};

/**
 * obtem o um moment a partir do valor informado
 * ou o dia de hoje se nao informado nada
 *
 * @param {*} value valor para transformar em moment ou undefined
 * @returns {moment.Moment} moment date
 */
const getMomentDate = value => {
	if (value) {
		if (value instanceof moment) {
			return value;
		} else if (isFinite(value)) {
			return moment(parseInt(value, 10));
		} else if (value instanceof Date) {
			return moment(value);
		}
	}
	return moment().startOf('day');
};

const configureLibraries = () => {
	moment.defineLocale('pt-br', {
		months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
		monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
		weekdays: 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_'),
		weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
		weekdaysMin: 'Do_2ª_3ª_4ª_5ª_6ª_Sá'.split('_'),
		weekdaysParseExact: true,
		longDateFormat: {
			LT: 'HH:mm',
			LTS: 'HH:mm:ss',
			L: 'DD/MM/YYYY',
			LL: 'D [de] MMMM [de] YYYY',
			LLL: 'D [de] MMMM [de] YYYY [às] HH:mm',
			LLLL: 'dddd, D [de] MMMM [de] YYYY [às] HH:mm'
		},
		calendar: {
			sameDay: '[Hoje às] LT',
			nextDay: '[Amanhã às] LT',
			nextWeek: 'dddd [às] LT',
			lastDay: '[Ontem às] LT',
			lastWeek: function() {
				return this.day() === 0 || this.day() === 6
					? '[Último] dddd [às] LT' // Saturday + Sunday
					: '[Última] dddd [às] LT'; // Monday - Friday
			},
			sameElse: 'L'
		},
		relativeTime: {
			future: 'em %s',
			past: 'há %s',
			s: 'poucos segundos',
			ss: '%d segundos',
			m: 'um minuto',
			mm: '%d minutos',
			h: 'uma hora',
			hh: '%d horas',
			d: 'um dia',
			dd: '%d dias',
			M: 'um mês',
			MM: '%d meses',
			y: 'um ano',
			yy: '%d anos'
		},
		dayOfMonthOrdinalParse: /\d{1,2}º/,
		ordinal: '%dº'
	});
	moment.locale('pt-br');
};

const hostname = window.location.hostname;

const isLocalhost = Boolean(
	hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
const isDes = Boolean(hostname.match(/.*-des\.procempa\.com\.br/));
const isHom = Boolean(hostname.match(/.*-hom\.procempa\.com\.br/));
const isPro = Boolean(hostname.match(/.*\.procempa\.com\.br/) && !isDes && !isHom);

let debug = isLocalhost || isDes || isHom;

if (debug && localStorage) {
	if (localStorage.getItem('debug') === null) {
		localStorage.setItem('debug', 'false');
		debug = false;
	} else if (localStorage.getItem('debug') === 'false') {
		debug = false;
	}
}
const isDebug = debug;

const isExternal = external;

const obtemDescricaoOcorrencia = ocorrencia => {
	let str = '';
	str = `${ocorrencia.tipo ? ocorrencia.tipo : ''} - ${ocorrencia.subtipo ? ocorrencia.subtipo : ''} - ${
		ocorrencia.descricao ? ocorrencia.descricao : ''
	}`;
	str = str.replace(/ {2,10}/g, ' ');
	str = str.replace(/( -){2,10}/g, ' -');
	str = str.startsWith(' - ') ? str.substring(3) : str;
	str = str.endsWith(' - ') ? str.substring(0, str.length - 3) : str;

	return str;
};

function deepEqual(obj1, obj2, ignoreDiferentKeys) {
	if ((obj1 === null && obj2 !== null) || (obj1 !== null && obj2 === null)) {
		return false;
	}
	if ((obj1 === undefined && obj2 !== undefined) || (obj1 !== undefined && obj2 === undefined)) {
		return false;
	}
	if (obj1 === obj2)
		// it's just the same object. No need to compare.
		return true;

	if (isPrimitive(obj1) && isPrimitive(obj2))
		// compare primitives
		return obj1 === obj2;

	if (!ignoreDiferentKeys && Object.keys(obj1).length !== Object.keys(obj2).length) {
		return false;
	}

	// compare objects with same number of keys
	for (let key in obj1) {
		if (!(key in obj2)) {
			return ignoreDiferentKeys; //other object doesn't have this prop
		}

		if (!deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
}

//check if value is primitive
function isPrimitive(obj) {
	return obj !== Object(obj);
}

/**
 * soma dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function soma(n1, n2, decimais = 2) {
	let ad1 = parseFloat(n1);
	let ad2 = parseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	return (ad1 + ad2).toFixed(decimais);
}

/**
 * multiplica dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function multiplica(n1, n2, decimais = 2) {
	let ad1 = parseFloat(n1);
	let ad2 = parseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	return (ad1 * ad2).toFixed(decimais);
}

/**
 * divide dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function divide(n1, n2, decimais = 2) {
	let ad1 = parseFloat(n1);
	let ad2 = parseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 1 : ad2 > 0 ? ad2 : 1;
	return (ad1 / ad2).toFixed(decimais);
}

/**
 * faz a diferenca (-) dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function diferenca(n1, n2, decimais = 2) {
	let ad1 = parseFloat(n1);
	let ad2 = parseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	return (ad1 - ad2).toFixed(decimais);
}

/**
 * testa se dois numeros sao iguais
 * @param {number|string} n1 numero 1
 * @param {number|string} n2 numero 2
 * @returns {boolean}
 */
function iguais(n1, n2) {
	return diferenca(n1, n2, 0) === '0';
}

function filterList(list, search) {
	let term = removeDiacritics(search);
	let properties = Array.isArray(list) ? list : [list];

	return properties.filter(l => {
		const json = JSON.stringify(l);
		return (
			removeDiacritics(json)
				.toLowerCase()
				.indexOf(term) > -1
		);
	});
}

const getIdade = dateAsString => {
	const now = moment();
	const date = moment(dateAsString, 'DD/MM/YYYY');
	return !!dateAsString && now.diff(date, 'years');
};

/**
 * testa se o primeiro numero eh maior do que o segundo
 * @param {*} n1
 * @param {*} n2
 * @returns
 */
function maiorQue(n1, n2) {
	let ad1 = parseFloat(n1);
	let ad2 = parseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	return ad1 > ad2;
}

function parseNumero(n1, decimais = 0) {
	let ad1 = parseFloat(n1);
	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	return +ad1.toFixed(decimais);
}

function trocaPontoPorVirgula(texto) {
	return texto && texto.replace && texto.replace('.', ',');
}

function compareStates(state1, state2, idProperties = ['id', '_id']) {
	if (!Array.isArray(idProperties)) {
		idProperties = [idProperties];
	}
	// são diferentes se um é null/undefined e o outro não
	if ((isNil(state1) && !isNil(state2)) || (!isNil(state1) && isNil(state2))) return false;
	// são iguais se ambos são null ou undefined
	if (isNil(state1) && isNil(state2)) return true;
	// são iguais se === retornar true
	if (state1 === state2) return true;
	// são iguais se são primitivos e === retornar true
	if (isPrimitive(state1) && isPrimitive(state2)) return state1 === state2;
	// se um dos dois forem Immutables, rexecuta a function usando os respectivos valores mutáveis
	if (isImmutable(state1) || isImmutable(state2)) {
		return compareStates(state1.toJS?.() || state1, state2.toJS?.() || state2, idProperties);
	}

	// restaram arrays ou objetos
	let equals = size(state1) === size(state2);
	if (equals) {
		if (Array.isArray(state1) && Array.isArray(state2)) {
			equals = state1.reduce((acc, item, index) => acc && compareStates(item, state2[index], idProperties), true);
		} else if (typeof state1 === 'object') {
			equals = idProperties.reduce(
				(acc, idProperty) => acc || get(state1, idProperty) === get(state2, idProperties),
				false
			);
		} else {
			equals = false;
		}
	}
	return equals;
}

export {
	compareStates,
	iguais,
	trocaPontoPorVirgula,
	parseNumero,
	getIdade,
	maiorQue,
	filterList,
	soma,
	multiplica,
	divide,
	diferenca,
	stringify,
	format,
	titleCase,
	removeSpaces,
	calculaDigitoMod11,
	sortCadastros,
	permiteInput,
	virgulaPonto,
	pontoVirgula,
	formataTelefone,
	cpfCnpj,
	isCPF,
	isCNPJ,
	isEmail,
	isDate,
	mergePropertyIntoState,
	selectInArray,
	stateWithoutError,
	normalizedAddress,
	clearObject,
	createUploadFileChannel,
	montaURL,
	stringValue,
	numericValue,
	formatTypedNumber,
	ignoreDiacritics,
	removeDiacritics,
	getMomentDate,
	obtemDescricaoOcorrencia,
	deepEqual,
	configureLibraries,
	isLocalhost,
	isDes,
	isDes as isDev,
	isHom,
	isPro,
	isPro as isProd,
	isDebug,
	isExternal
};
