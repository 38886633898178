import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { size, trim, omit, cloneDeep } from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v4';

import ErrorMessages from '../../components/ErrorMessages';
import Loader from '../../components/Loader';
import ModalMessage from '../../components/ModalMessage';
import { ABA_DELEGADOS } from '../../components/Navigation';
import Navigation from '../../components/Navigation';
import Selecao from '../../components/Selecao';
import useCalendario from '../../customHooks/useCalendario';
import useMessages from '../../customHooks/useMessages';
import { limpaStore, removeDelegado, saveDelegado, setCurrent, setMessage } from '../../Redux/delegado/delegadoSlice';
import { setAba } from '../../Redux/Home/homeSlice';
import { history } from '../../Redux/store';
import { getIdade, isDebug, isExternal } from '../../utils/tools';

const delegadoQuery = loader('./delegadoQuery.gql');

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[DELEGADO ${i++}]:`, ...args);

function Delegado() {
	/* REDUX */
	const dispatch = useDispatch();
	const delegado = useSelector(state => state.delegado.current);
	const updating = useSelector(state => state.delegado.updating);
	const message = useSelector(state => state.delegado.message);
	const validationMessages = useSelector(state => state.delegado.validationMessages);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */
	const { createMessage } = useMessages();
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const { id } = useParams();
	const [errors, setErrors] = useState({});
	const [showPopupRemove, setShowPopupRemove] = useState(false);
	const [showPopupMessage, setShowPopupMessage] = useState(false);
	const [regioes, setRegioes] = useState([]);
	const [regiaoSearchTerm, setRegiaoSearchTerm] = useState('');
	const [participantes, setParticipantes] = useState([]);
	const [participanteSearchTerm, setParticipanteSearchTerm] = useState('');

	const { data: dataDelegado, loading: loadingDelegado, errors: errorDelegado } = useQuery(delegadoQuery, {
		variables: { id },
		fetchPolicy: 'network-only'
	});

	const { data: dataRegiao, loading: loadingRegiao, errors: errorRegiao } = useQuery(
		gql`
			query ListaRegioes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaRegiaoList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					numero
				}
				count: ECmduaRegiaoCount
			}
		`,
		{
			variables: {},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const {
		data: dataParticipantes,
		loading: loadingParticipantes,
		errors: errorParticipantes,
		refetch: refetchParticipantes
	} = useQuery(
		gql`
			query ListaParticipantes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaParticipanteList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					email
					dataNascimento
					regiao {
						id
						nome
					}
				}
			}
		`,
		{
			variables: {
				skip: 0,
				limit: 2000,
				term: isExternal ? 'NINGUÉM TEM' : undefined
			},
			fetchPolicy: 'network-only'
			// nextFetchPolicy: 'cache-first'
		}
	);

	const onNavigateHandler = useCallback(() => {
		dispatch(limpaStore());
	}, [dispatch]);

	useEffect(() => {
		if (size(message) > 0) {
			debugLog('[message]: ', message);
			if (message.message) {
				setShowPopupMessage(message);
			} else {
				createMessage(message, 15);
			}
			dispatch(setMessage(null));
		}
	}, [createMessage, dispatch, message]);

	useEffect(() => {
		if (id === 'new' && !delegado?.id) {
			debugLog('[delegado, id]: ', delegado, id);
			dispatch(setCurrent({ id: uuid() }));
		}
	}, [delegado, dispatch, id]);

	useEffect(() => {
		if (isExternal && userProfile) {
			debugLog('[userProfile]: ', userProfile);
			const variables = {
				skip: 0,
				limit: 2000,
				term: JSON.stringify({
					usuarioCriador: userProfile.email
				})
			};

			refetchParticipantes(variables);
		}
	}, [userProfile, refetchParticipantes]);

	useEffect(() => {
		if (dataDelegado && id !== 'new') {
			debugLog('[dataDelegado, id]: ', dataDelegado, id);
			const delegadoAux = cloneDeep(dataDelegado.item);
			if (delegadoAux.delegado?.dataNascimento) {
				delegadoAux.delegado.idade = getIdade(delegadoAux.delegado?.dataNascimento);
			}
			dispatch(setCurrent(delegadoAux));
		}
	}, [dispatch, id, dataDelegado]);

	useEffect(() => {
		if (dataParticipantes) {
			debugLog('[dataParticipantes, participantes]: ', dataParticipantes.list, participantes);
			if (size(dataParticipantes.list) !== size(participantes)) {
				setParticipantes(
					dataParticipantes.list
						.sort((a, b) => (a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1))
						.map(p => ({ ...p, idade: getIdade(p.dataNascimento) }))
				);
			}
		}
	}, [dataParticipantes, participantes]);

	useEffect(() => {
		if (dataRegiao) {
			debugLog('[dataRegiao]: ', dataRegiao);
			setRegioes(dataRegiao.list);
		}
	}, [dataRegiao]);

	const changeHandler = useCallback(
		e => {
			const target = Array.isArray(e.target) ? e.target : [e.target];
			let errorsToRemove = [];
			let update = { ...delegado };
			target.forEach(t => {
				const { name, value } = t;
				errorsToRemove.push(name);
				update = { ...update, [name]: value };
			});
			setErrors(old => omit(old, errorsToRemove) || {});
			dispatch(setCurrent({ ...update }));
		},
		[dispatch, delegado]
	);

	const valida = useCallback(async () => {
		let errors = {};
		if (size(trim(delegado.regiao)) === 0) {
			errors.regiao = ['Selecione a região '];
		}
		if (size(delegado.delegado) === 0) {
			errors.delegado = ['Selecione o delegado'];
		} else if (delegado.regiao && delegado.delegado.regiao.id !== delegado.regiao.id) {
			errors.delegado = ['O delegado deve pertencer à região selecionada'];
		} else if (delegado.delegado.idade < 18) {
			errors.delegado = ['O delegado deve ter pelo menos 18 anos'];
		}
		if (!calendario || isAfter('fimInscricoesChapasDelegados')) {
			errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
		}
		return errors;
	}, [calendario, delegado, isAfter]);

	const submeteForm = useCallback(async () => {
		const errors = await valida();
		let origem = 'edicao';
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			let delegadoAux = { ...delegado };
			if (isExternal && !delegadoAux.usuarioCriador) {
				delegadoAux.usuarioCriador = userProfile.email;
			}

			if (!delegadoAux.calendario) {
				delegadoAux.calendario = calendario.calendario;
			}

			if (id === 'new') {
				origem = 'cadastro';
				// apenas para transporte... não será gravado no banco
				delegadoAux.enviarEmail = true;
			}
			// apenas para transporte
			delegadoAux.origem = origem;

			dispatch(saveDelegado(delegadoAux));
		}
	}, [valida, delegado, dispatch, userProfile, id, calendario]);

	const cancelar = useCallback(() => {
		dispatch(limpaStore());
		history.go(-1);
	}, [dispatch]);

	const remover = useCallback(() => {
		let errors = {};
		if (calendario && !isAfter('fimInscricoesChapasDelegados')) {
			dispatch(removeDelegado(delegado.id));
		} else {
			errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
			setErrors(errors);
		}
		setShowPopupRemove(false);
	}, [calendario, isAfter, dispatch, delegado]);

	// eslint-disable-next-line
	const hidePopupMessage = useCallback(() => {
		if (showPopupMessage.type === 'success') {
			setShowPopupMessage(false);
			dispatch(limpaStore());
			dispatch(setAba(ABA_DELEGADOS));
			if (window.location.pathname !== '/home') {
				history.push('/home');
			}
		} else {
			setShowPopupMessage(false);
		}
	});

	const isForaPeriodoInscricao = useMemo(
		() =>
			!isDebug &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimInscricoesChapasDelegados')),
		[calendario, isAfter]
	);

	const readOnly = useMemo(() => {
		const usandoAdminCriadoPeloCidadao = !isExternal && !!delegado?.usuarioCriador;
		return usandoAdminCriadoPeloCidadao || isForaPeriodoInscricao;
	}, [delegado, isForaPeriodoInscricao]);

	return loadingDelegado || loadingParticipantes || loadingCalendario ? (
		<Loader msg="Carregando dados do delegado..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendario']} />
	) : errorDelegado ? (
		<ErrorMessages errorList={['Problemas ao carregar delegado']} />
	) : errorParticipantes ? (
		<ErrorMessages errorList={['Problemas ao carregar participantes']} />
	) : errorRegiao ? (
		<ErrorMessages errorList={['Problemas ao carregar regiões']} />
	) : (
		<>
			{updating && <Loader msg="Salvando delegado..." />}
			<div className="container">
				<Navigation onNavigate={onNavigateHandler} />
				<h1>{id === 'new' ? 'Novo' : readOnly ? 'Consulta de' : 'Edição de'} Delegado</h1>
				{readOnly && (
					<ErrorMessages
						errorList={[
							{
								msg: ` edição desabilitada ${
									isForaPeriodoInscricao
										? 'pois o período de inscrições está encerrado'
										: `pois este delegado foi criado no portal externo por ${delegado?.usuarioCriador}`
								}`,
								type: 'warning'
							}
						]}
					/>
				)}
				{delegado && (
					<form autoComplete="off">
						<fieldset>
							<legend>Região</legend>
							<div className="row">
								<div className="form-group col-md-12">
									<label className="required">Região</label>
									<Selecao
										className={`form-control${errors.regiao ? ' form-control-error' : ''}`}
										selected={delegado.regiao}
										label={'região'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										detailDescricao={'nome'}
										autoShowList={false}
										searchTerm={regiaoSearchTerm}
										searchList={regioes.filter(r => r.nome.toLowerCase().includes(regiaoSearchTerm.toLowerCase()))}
										searchTermMinLength={0}
										errorList={errors.regiao}
										onChangeSearchTerm={e => {
											setRegiaoSearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'regiao', value: item } })}
										onUnselect={() => () => {
											setParticipantes([]);
											changeHandler({ target: { name: 'regiao', value: null } });
										}}
										noResetList={true}
										loading={loadingRegiao}
										placeholder={'Selecione uma região'}
										readOnly={readOnly}
									/>
								</div>
							</div>
						</fieldset>
						<fieldset>
							<div className="titulo-pagina">
								<legend>Identificação do Delegado</legend>
								{calendario && !isAfter('fimInscricoesChapasDelegados') && !readOnly && (
									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={() => history.push('/participante/new')}
									>
										<i className="fa fa-plus" aria-hidden="true"></i>Novo Participante
									</button>
								)}
							</div>
							<div className="row">
								<div className="form-group col-12">
									<label className="required" htmlFor="">
										Delegado
									</label>
									<Selecao
										className={`form-control${errors.delegado ? ' form-control-error' : ''}`}
										selected={delegado.delegado}
										label={'delegado'}
										detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
										detailCodigo={''}
										templateDescricao={
											delegado?.delegado?.dataNascimento
												? '{{nome}} de {{idade}} anos, morador da região {{regiao.nome}}'
												: '{{nome}}, morador da região {{regiao.nome}}'
										}
										detailModifier={text => text}
										maxDescricaoLength={80}
										autoShowList={false}
										searchTerm={participanteSearchTerm}
										searchList={participantes
											.filter(p => ![delegado.suplente1?.id, delegado.suplente2?.id].includes(p.id))
											.filter(p => p.nome.toLowerCase().indexOf(participanteSearchTerm.toLowerCase()) > -1)}
										searchTermMinLength={0}
										errorList={errors.delegado}
										onChangeSearchTerm={e => {
											setParticipanteSearchTerm(e.target.value);
										}}
										onBlurSearchTerm={() => false}
										onSelectItem={item => () => changeHandler({ target: { name: 'delegado', value: item } })}
										onUnselect={() => () => changeHandler({ target: [{ name: 'delegado', value: null }] })}
										noResetList={true}
										loading={loadingRegiao}
										placeholder={'Selecione o delegado'}
										readOnly={readOnly}
									/>
								</div>
							</div>
						</fieldset>
					</form>
				)}
				{size(validationMessages) > 0 && <ErrorMessages errorList={validationMessages} />}
				{errors.prazo && <ErrorMessages errorList={errors.prazo} />}
				<div className="buttons">
					<button type="button" className="btn btn-outline-danger" onClick={cancelar}>
						<i className="fa fa-times" aria-hidden="true"></i>Cancelar
					</button>
					{!readOnly && (
						<>
							{id !== 'new' && (
								<button type="button" className="btn btn-danger" onClick={() => setShowPopupRemove(true)}>
									<i className="fa fa-trash-o" aria-hidden="true"></i>Remover
								</button>
							)}
							<button type="button" className="btn btn-primary" onClick={submeteForm}>
								<i className="fa fa-save" aria-hidden="true"></i>Salvar
							</button>
						</>
					)}
				</div>
				{showPopupRemove && (
					<div
						className="modal"
						style={{
							backgroundColor: 'rgba(0,0,0,.5)',
							display: 'flex',
							flexFlow: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						role="dialog"
					>
						<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
							<div className="modal-content">
								<div className="modal-header">
									<h1 className="h3">Remover Delegado</h1>
								</div>
								<div className="modal-body">
									<span>Remover o delegado e manter participantes cadastrados?</span>
								</div>
								<div className="modal-footer">
									<div className="buttons">
										<button type="button" className="btn btn-outline-danger" onClick={() => setShowPopupRemove(false)}>
											<i className="fa fa-times" aria-hidden="true"></i>Cancelar
										</button>
										<button type="button" className="btn btn-danger" onClick={remover}>
											Confirmar Remoção
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{size(showPopupMessage) > 0 && (
					<ModalMessage
						showPopupMessage={showPopupMessage}
						hidePopupMessage={hidePopupMessage}
						validationMessages={validationMessages}
					/>
				)}
			</div>
		</>
	);
}
Delegado.displayName = 'Delegado';
Delegado.propTypes = {};

export default Delegado;
