import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useLazyQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';

import gql from 'graphql-tag';

import CopyButton from '../../components/CopyButton';
import ErrorMessages from '../../components/ErrorMessages';
import Loader from '../../components/Loader';
import PaginationV2 from '../../components/PaginationV2';
import SearchBar from '../../components/SearchBar';
import { setCount, setPage, setSearchTerm } from '../../Redux/participantes/participantesSlice';
import { history } from '../../Redux/store';
import { getIdade, isExternal } from '../../utils/tools';

const PAGE_SIZE = 10;

function ParticipantesComponent() {
	/* REDUX */
	const dispatch = useDispatch();
	const searchTerm = useSelector(state => state.participantes?.searchTerm);
	const page = useSelector(state => state.participantes?.page);
	const count = useSelector(state => state.participantes?.count);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */

	/* ESTADOS */
	const searchRef = useRef();
	const [participantes, setParticipantes] = useState(null);
	const [loading, setLoading] = useState(true);
	const [inicial, setInicial] = useState(0);
	const [final, setFinal] = useState(0);
	const [termo, setTermo] = useState(
		JSON.stringify({
			term: isExternal ? 'NINGUÉM TEM' : '',
			sort: { numero: 1 }
		})
	);
	const email = useRef();

	const participanteQuery = gql`
		query deepSearch($term: String, $skip: Int, $limit: Int) {
			list: ECmduaParticipanteDeepSearch(term: $term, skip: $skip, limit: $limit) {
				id
				nome
				cpf
				dataNascimento
				regiao {
					id
					nome
				}
			}
			count: ECmduaParticipanteDeepSearchCount(term: $term)
		}
	`;

	let [
		getParticipantes,
		{ data: dataParticipantes, loading: loadingDataParticipantes, errors: errorData }
	] = useLazyQuery(participanteQuery);

	const prepareTermo = useCallback(
		searchTerm => {
			const usuarioCriador = isExternal ? email.current : undefined;
			const termoAux = {
				term: searchTerm,
				$or: [{ usuarioCriador }, { email: userProfile.email }],
				sort: { nome: 1 }
			};
			setTermo(JSON.stringify(termoAux));
		},
		[userProfile]
	);

	useEffect(() => {
		if (userProfile) {
			if (!email.current) {
				email.current = userProfile.email;
			}
			prepareTermo(searchTerm);
			getParticipantes({
				variables: {
					skip: page * PAGE_SIZE,
					limit: PAGE_SIZE,
					term: termo
				},
				ssr: true,
				fetchPolicy: 'network-only'
			});
			setLoading(false);
		}
	}, [prepareTermo, getParticipantes, searchTerm, userProfile, page, termo]);

	useEffect(() => {
		if (dataParticipantes) {
			setParticipantes(dataParticipantes.list.map(p => ({ ...p, idade: getIdade(p.dataNascimento) })));
			dispatch(setCount(dataParticipantes.count));
			if (searchRef.current) {
				searchRef.current.focus();
			}
		}
	}, [dispatch, dataParticipantes]);

	useEffect(() => {
		if (count !== null) {
			const inicial = page * PAGE_SIZE + 1;
			setInicial(inicial > count ? count : inicial);
			const final = page + 1 * PAGE_SIZE;
			setFinal(final > count ? count : final);
		}
	}, [page, count]);

	const setDispatchPage = useCallback(
		page => {
			dispatch(setPage(page));
		},
		[dispatch]
	);

	const isLoading = loadingDataParticipantes || loading;

	return isLoading ? (
		<Loader msg="Carregando participantes..." />
	) : errorData ? (
		<ErrorMessages errorList={['Problemas ao carregar as participantes']} />
	) : (
		<div>
			<div className="titulo-pagina">
				<h1>Participantes</h1>
				{!isExternal && (
					<div className="buttons">
						<button type="button" className="btn btn-primary" onClick={() => history.push('/participante/new')}>
							<i className="fa fa-plus" aria-hidden="true"></i>Novo Participante
						</button>
					</div>
				)}
			</div>

			<SearchBar
				searchRef={searchRef}
				placeholder="Pesquisar por nome ou CPF dos participantes ou por nome da região"
				setSearchTerm={setSearchTerm}
				searchTermSelector="participantes.searchTerm"
				onSubmit={prepareTermo}
			/>
			{count !== null && (
				<p>
					<strong>{count}</strong> participante{count === 1 ? '' : 's'}
				</p>
			)}
			{participantes?.map((participante, index) => (
				<div
					key={index}
					className={`row list-items${index % 2 === 0 ? ' even' : ''}`}
					onClick={() => history.push(`/participante/${participante.id}`)}
				>
					<div className="col-md-4">{participante.nome}</div>
					<div className="col-md-3">
						{participante.cpf} <CopyButton texto={participante.cpf} formato="999.999.999-99" title="CPF" />
					</div>
					<div className="col-md-2">{participante.dataNascimento ? `${participante.idade} anos ` : ' '}</div>
					<div className="col-md-3">{participante.regiao.nome}</div>
				</div>
			))}
			{count !== null && (
				<p className="contador-paginacao">
					<strong>
						{inicial} - {final}
					</strong>{' '}
					de <strong>{count}</strong>
				</p>
			)}
			{count !== null && <PaginationV2 totalItems={count} pageSize={PAGE_SIZE} page={page} setPage={setDispatchPage} />}
		</div>
	);
}
ParticipantesComponent.displayName = 'ParticipantesCompoent';
ParticipantesComponent.propTypes = {};

export default ParticipantesComponent;
