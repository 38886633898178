import React from 'react';

import SetDebugComponent from '../../components/SetDebugComponent';
import useCalendario from '../../customHooks/useCalendario';
import { isDebug } from '../../utils/tools';
import { isProd } from '../../utils/tools';
const debugLog = (...args) => isDebug && console.debug('', ...args);

function Publica() {
	/* CUSTOM HOOKS */
	const { calendario, loading, error, isAfter, isBetween } = useCalendario();

	if (!isAfter('iniInscricoesChapasDelegados')) {
		return (
			<div
				style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '95vw', height: '80vh' }}
			>
				{loading ? (
					'carregando calendário'
				) : error ? (
					'erro ao obter dados do calendário'
				) : (
					<h2>Aguarde abertura do processo em breve{/*calendario?.iniInscricoesChapasDelegados*/}</h2>
				)}
			</div>
		);
	}

	return (
		<>
			<div className="container">
				{isBetween('iniInscricoesChapasDelegados', 'fimInscricoesChapasDelegados') && (
					<>
						{/*iniInscricoesChapasDelegados */}
						<h2>Inscrições abertas para chapas, delegados e entidades</h2>
						<p>
							<a href="/home">Faça sua inscrição</a>.<br />
							<small>É preciso fazer login via AcessoPOA para fazer inscrição.</small>
						</p>
					</>
				)}
				{isBetween('fimInscricoesChapasDelegados', 'publicacaoInscricaoChapasDelegados') && (
					<>
						{/*fimInscricoesChapasDelegados */}
						<h2>Inscrições encerradas para chapas, delegados e entidades</h2>
						<p>Aguarde a publicação dos resultados no dia {calendario?.publicacaoInscricaoChapasDelegados}.</p>
					</>
				)}
				{isAfter('publicacaoInscricaoChapasDelegados') && (
					<>
						{isBetween('publicacaoInscricaoChapasDelegados', 'fimImpugnacaoChapasDelegados') && (
							<>
								{/*publicacaoInscricaoChapasDelegados */}
								<h2>Resultado das inscrições para chapas e delegados</h2>
								<p>
									Acompanhe a liberação dos resultados no &nbsp;
									<a href="https://www2.portoalegre.rs.gov.br/dopa/" target="_blank" rel="noopener noreferrer">
										Diário Oficial de Porto Alegre.
									</a>
								</p>
							</>
						)}
						{debugLog('isDebug', isDebug)}
						{isBetween('iniImpugnacaoChapasDelegados', 'fimImpugnacaoChapasDelegados') ? (
							<>
								{/* iniImpugnacaoChapasDelegados */}
								<h2>Início do processo de impugnação de chapas e delegados</h2>
								<p>
									Caso deseje solicitar uma impugnação, acesse o{' '}
									<a href="/solicitacao-impugnacao-chapa" target="_blank" rel="noopener noreferrer">
										formulário de solicitação de impugnação
									</a>
									.
								</p>
							</>
						) : null}
						{isBetween('fimImpugnacaoChapasDelegados', 'fimRecursosImpugnacaoChapasDelegados') && (
							<>
								{/*"fimImpugnacaoChapasDelegados*/}
								<h2>Encerrado o Período de Pedidos de Impugnação das Chapas</h2>
								<p>
									Veja o resultado das Impugnações no Site: &nbsp;
									<a href="https://prefeitura.poa.br/cmdua" target="_blank" rel="noopener noreferrer">
										prefeitura.poa.br/cmdua
									</a>
								</p>
							</>
						)}
						{isBetween('iniRecursosImpugnacaoChapasDelegados', 'fimRecursosImpugnacaoChapasDelegados') && (
							<>
								{/* iniRecursosImpugnacaoChapasDelegados */}
								<h2>Início do processo de recurso de impugnação de chapas e delegados</h2>
								<p>
									As chapas e delegados afetados podem encaminhar recurso até o dia{' '}
									{calendario?.fimRecursosImpugnacaoChapasDelegados}.
								</p>
							</>
						)}
						{isBetween('fimRecursosImpugnacaoChapasDelegados', 'publicacaoImpugnacoesAposRecursos') && (
							<>
								{/* fimRecursosImpugnacaoChapasDelegados */}
								<h2>Encerrado período de recurso de impugnação de chapas e delegados</h2>
								<p>
									Acompanhe a liberação dos resultados no &nbsp;
									<a href="https://www2.portoalegre.rs.gov.br/dopa/" target="_blank" rel="noopener noreferrer">
										Diário Oficial de Porto Alegre.
									</a>
								</p>
							</>
						)}
						{isBetween('publicacaoImpugnacoesAposRecursos', 'iniImpugnacaoEntidades') && (
							<>
								{/* publicacaoImpugnacoesAposRecursos */}
								<h2>Resultado das impugnações de chapas e delegados</h2>
								<p>
									Acompanhe a liberação dos resultados após o julgamento dos recursos no &nbsp;
									<a href="https://www2.portoalegre.rs.gov.br/dopa/" target="_blank" rel="noopener noreferrer">
										Diário Oficial de Porto Alegre.
									</a>
								</p>
							</>
						)}
						{(isDebug || isBetween('iniImpugnacaoEntidades', 'fimImpugnacaoEntidades')) && (
							<>
								{/* iniImpugnacaoEntidades */}
								<h2>Início do processo de impugnação de entidades</h2>
								<p>
									Caso deseje solicitar uma impugnação, acesse o{' '}
									<a href="/solicitacao-impugnacao-entidade" rel="noopener noreferrer">
										formulário de solicitação de impugnação de entidade
									</a>
									.
								</p>
							</>
						)}
						{isBetween('fimImpugnacaoEntidades', 'iniRecursosImpugnacaoEntidades') && (
							<>
								{/*"fimImpugnacaoEntidades*/}
								<h2>Encerrado o Período de Pedidos de Impugnação de Entidades</h2>
								<p>
									Veja o resultado das Impugnações no Site: &nbsp;
									<a href="https://prefeitura.poa.br/cmdua" target="_blank" rel="noopener noreferrer">
										prefeitura.poa.br/cmdua
									</a>
								</p>
							</>
						)}
						{isBetween('iniRecursosImpugnacaoEntidades', 'fimRecursosImpugnacaoEntidades') && (
							<>
								{/* iniRecursosImpugnacaoEntidades */}
								<h2>Início do processo de recurso de impugnação de entidades</h2>
								<p>
									As entidades afetadas podem encaminhar recurso até o dia {calendario?.fimRecursosImpugnacaoEntidades}.
								</p>
							</>
						)}
						{isBetween('fimRecursosImpugnacaoEntidades', 'publicacaoImpugnacoesEntidadesAposRecursos') && (
							<>
								{/* fimRecursosImpugnacaoEntidades */}
								<h2>Resultado das impugnações de entidades</h2>
								<p>
									Acompanhe a liberação dos resultados após o julgamento dos recursos no &nbsp;
									<a href="https://www2.portoalegre.rs.gov.br/dopa/" target="_blank" rel="noopener noreferrer">
										Diário Oficial de Porto Alegre.
									</a>
								</p>
							</>
						)}
						{isBetween('publicacaoEleicao', 'fimPeriodoEleitoral') && (
							<>
								{/* publicacaoEleicao */}
								<h2>Locais das Eleições</h2>
								<p>
									Veja a publicação dos locais das Eleições nas Regiões de Gestão do Planejamento no &nbsp;
									<a href="https://www2.portoalegre.rs.gov.br/dopa/" target="_blank" rel="noopener noreferrer">
										Diário Oficial de Porto Alegre.
									</a>
								</p>
								<table className="table" cellSpacing="0" cellPadding="0">
									<thead>
										<tr>
											<th className="column-headers-background">Data</th>
											<th className="column-headers-background">Região</th>
											<th className="column-headers-background">Local</th>
											<th className="column-headers-background">Endereço</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="s0" dir="ltr">
												09/01/2024<br></br>Terça-feira<br></br>das 17h às 20h
											</td>
											<td className="s1" dir="ltr">
												RGP1
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Câmara Municipal<br></br>de Porto Alegre <br></br>Salas das Comissões
												</div>
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Av. Loureiro da Silva, 255 <br></br>Centro Histórico, Porto Alegre/RS, 90013-901
												</div>
											</td>
										</tr>
										<tr>
											<td className="s0" dir="ltr">
												11/01/2024<br></br>Quinta-feira<br></br>das 17h às 20h
											</td>
											<td className="s1" dir="ltr">
												RGP2
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													EMEF Antonio Giudice <br></br>Escola Municipal de<br></br>Ensino Fundamental
												</div>
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Rua Dr. Caio Brandão de Mello, 1 <br></br>Humaitá, Porto Alegre/RS, 90250-110
												</div>
											</td>
										</tr>
										<tr>
											<td className="s0" dir="ltr">
												16/01/2024<br></br>Terça-feira<br></br>das 17h às 20h
											</td>
											<td className="s0" dir="ltr">
												RGP3
											</td>
											<td className="s1" dir="ltr">
												FGTAS <br></br>Vida Centro<br></br>Humanístico
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Av. Baltazar de Oliveira Garcia, 2132 <br></br>Porto Alegre/RS, 91150-000
												</div>
											</td>
										</tr>
										<tr>
											<td className="s0" dir="ltr">
												18/01/2024<br></br>Quinta-feira<br></br>das 17h às 20h
											</td>
											<td className="s1" dir="ltr">
												RGP4
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Centro Social Marista<br></br>de Porto Alegre <br></br>Cesmar
												</div>
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Estr. Antônio Severino, 1493 <br></br>Rubem Berta, Porto Alegre/RS, 91250-330
												</div>
											</td>
										</tr>
										<tr>
											<td className="s0" dir="ltr">
												23/01/2024<br></br>Terça-feira<br></br>das 17h às 20h
											</td>
											<td className="s1" dir="ltr">
												RGP5
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">Postão da Cruzeiro</div>
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Av. Moab Caldas, 400 <br></br>Santa Tereza, Porto Alegre/RS, 90880-310
												</div>
											</td>
										</tr>
										<tr>
											<td className="s0" dir="ltr">
												25/01/2024<br></br>Quinta-feira<br></br>das 17h às 20h
											</td>
											<td className="s1" dir="ltr">
												RGP6
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													CECOPAM <br></br>Centro da Comunidade<br></br>Parque Madepinho
												</div>
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Rua Arroio Grande, 50 <br></br>Cavalhada, Porto Alegre/RS, 91740-180
												</div>
											</td>
										</tr>
										<tr>
											<td className="s0" dir="ltr">
												30/01/2024<br></br>Terça-feira<br></br>das 17h às 20h
											</td>
											<td className="s1" dir="ltr">
												RGP7
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													C.P.C.A <br></br>Centro de Promoção da<br></br>Criança e do Adolescente
												</div>
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Estr. João de Oliveira Remião, 4444 <br></br>Lomba do Pinheiro, Porto Alegre/RS, 91550-060
												</div>
											</td>
										</tr>
										<tr>
											<td className="s0" dir="ltr">
												06/02/2024<br></br>Terça-feira<br></br>(NOVA DATA)<br></br>das 17h às 20h
											</td>
											<td className="s1" dir="ltr">
												RGP8
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													C.P.I.J <br></br>Centro de Promoção da<br></br>Infância e Juventude
												</div>
											</td>
											<td className="s2 softmerge" dir="ltr">
												<div className="softmerge-inner">
													Rua Mississipi, 130 <br></br>Restinga, Porto Alegre/RS, 91790-430
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</>
						)}
						{isBetween('iniPeriodoEleitoral', 'fimPeriodoEleitoral') && (
							<>
								{/* iniPeriodoEleitoral */}
								<h2>Início do período eleitoral</h2>
								<p>Vote até o dia {calendario?.fimPeriodoEleitoral}.</p>
							</>
						)}
						{isBetween('fimPeriodoEleitoral', 'publicacaoResultados') && (
							<>
								{/* fimPeriodoEleitoral */}
								<h2>Fim do período eleitoral</h2>
								<p>Aguarde a publicação dos resultados no dia {calendario?.publicacaoResultados}.</p>
							</>
						)}
						{isAfter('publicacaoResultados') && (
							<>
								{/* publicacaoResultados */}
								<h2>Resultado das eleições</h2>
								<p>
									Acompanhe a liberação dos resultados no &nbsp;
									<a href="https://www2.portoalegre.rs.gov.br/dopa/" target="_blank" rel="noopener noreferrer">
										Diário Oficial de Porto Alegre.
									</a>
								</p>
							</>
						)}
					</>
				)}
				<div className="flex">
					<p>
						Acompanhe o{' '}
						<a
							href="https://prefeitura.poa.br/cmdua/regioes-de-gestao-de-planejamento-calendario-eleitoral"
							target="_blank"
							rel="noopener nofollow noreferrer"
						>
							calendário eleitoral
						</a>{' '}
						no site do CMDUA. <br />
					</p>

					<a href="/email" className="btn btn-link" rel="noopener noreferrer">
						Enviar email para comissão eleitoral
					</a>
				</div>
			</div>

			{!isProd && (
				<div className="container">
					<SetDebugComponent />
				</div>
			)}
		</>
	);
}
Publica.displayName = 'Public';

export default Publica;
