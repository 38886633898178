import { join } from 'lodash';

import { ADDRESS_IGNORED_WORDS } from './words';

const joinedStopWords = join(ADDRESS_IGNORED_WORDS, '|');
const regexAddressPattern = `\\b(?:${joinedStopWords}\\b\\s*)`;
export const REGEX_ADDRESS = new RegExp(regexAddressPattern, 'ig');
export const REGEX_NUMBERS_ONLY = new RegExp('^[0-9]*$');

// Valida numeros de telefone no formato (00) 00000-0000 ou (00) 0000-0000
export const REGEX_TELEFONE = new RegExp('^\\([1-9]{2}\\) [2-9][0-9]{3,4}\\-[0-9]{4}$');

export const EMAIL_REGEX = new RegExp(
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

export const ROLES = {
	ADMIN: 1,
	OPERADOR: 2,
	CAD_PARTICIPANTE: 3,
	LOGADO: 99,
	properties: {
		1: { key: '1', value: 'urbanismo-cmdua-admin' },
		2: { key: '2', value: 'urbanismo-cmdua-operador' },
		3: { key: '3', value: 'urbanismo-cmdua-cad-participante' },
		99: { key: '99', value: 'USUARIO_LOGADO_NO_SISTEMA' },
		9999: { key: '9999', value: 'NINGUEM_TEM' }
	}
};
export const getRole = roleId => ROLES.properties[roleId]?.value || ROLES.properties[9999].value;
