import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';

import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { cloneDeep, size } from 'lodash';

import ErrorMessages from '../../../components/ErrorMessages';
import Loader from '../../../components/Loader';
import ModalMessage from '../../../components/ModalMessage';
import { ABA_ANALISE } from '../../../components/Navigation';
import Selecao from '../../../components/Selecao';
import useMessages from '../../../customHooks/useMessages';
import {
	limpaStore,
	saveChapa,
	setMessages,
	setSituacaoAnalise as setSituacaoAnaliseChapa
} from '../../../Redux/chapa/chapaSlice';
import {
	saveDelegado,
	setMessage,
	setSituacaoAnalise as setSituacaoAnaliseDelegado
} from '../../../Redux/delegado/delegadoSlice';
import { setAba } from '../../../Redux/Home/homeSlice';
import { history } from '../../../Redux/store';
import { getIdade, isDebug } from '../../../utils/tools';
import AnaliseChapaComponent from './analiseChapaComponent';
import AnaliseDelegadoComponent from './analiseDelegadoComponent';

const chapasQuery = loader('./chapasQuery.graphql');
const delegadosQuery = loader('./delegadosQuery.graphql');

const LIBERADA_IMPUGNACAO_DELEGADO = false;

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[ANALISE ${i++}]:`, ...args);

function AnaliseComponent() {
	/* REDUX */
	const dispatch = useDispatch();
	const situacaoAnaliseChapa = useSelector(state => state.chapa.situacaoAnalise);
	const situacaoAnaliseDelegado = useSelector(state => state.delegado.situacaoAnalise);
	const messagesChapa = useSelector(state => state.chapa.messages);
	const messageDelegado = useSelector(state => state.delegado.message);
	const validationMessages = useSelector(state => state.chapa.validationMessages);

	/* CUSTOM HOOKS */
	const { createMessage } = useMessages();

	/* ESTADOS */
	const [regiao, setRegiao] = useState(null);
	const [regioes, setRegioes] = useState([]);
	const [regiaoSearchTerm, setRegiaoSearchTerm] = useState('');
	const [chapas, setChapas] = useState([]);
	const [delegados, setDelegados] = useState([]);
	const [chapa, setChapa] = useState(null);
	const [delegado, setDelegado] = useState(null);
	const [motivoImpugnacao, setMotivoImpugnacao] = useState(null);
	const [impugnar, setImpugnar] = useState(false);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [showPopupMessage, setShowPopupMessage] = useState(false);

	const { data: dataRegiao, loading: loadingRegiao, errors: errorRegiao } = useQuery(
		gql`
			query ListaRegioes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaRegiaoList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					numero
				}
				count: ECmduaRegiaoCount
			}
		`,
		{
			variables: {},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const [getChapas, { data: dataChapas, loading: loadingChapas, errors: errorChapas }] = useLazyQuery(chapasQuery, {
		ssr: true,
		fetchPolicy: 'network-only'
	});

	const [getDelegados, { data: dataDelegados, loading: loadingDelegados, errors: errorDelegados }] = useLazyQuery(
		delegadosQuery,
		{
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		if (dataRegiao) {
			setRegioes(dataRegiao.list);
		}
	}, [dataRegiao]);

	useEffect(() => {
		if (regiao) {
			getChapas({
				variables: {
					term: JSON.stringify({
						regiao: regiao.id,
						sort: { numero: 1 }
					}),
					skip: 0,
					limit: 10000
				}
			});
		}
	}, [getChapas, regiao]);

	useEffect(() => {
		if (regiao) {
			getDelegados({
				variables: {
					term: JSON.stringify({
						regiao: regiao.id
					}),
					skip: 0,
					limit: 10000
				}
			});
		}
	}, [getDelegados, regiao]);

	useEffect(() => {
		if (dataChapas) {
			debugLog('[dataChapas]: ', dataChapas);
			const chapasAux = dataChapas.list
				.map(chapaAux => cloneDeep(chapaAux))
				.map(chapaAux => {
					if (chapaAux.titular?.dataNascimento) {
						chapaAux.titular.idade = getIdade(chapaAux.titular?.dataNascimento);
					}
					if (chapaAux.suplente1?.dataNascimento) {
						chapaAux.suplente1.idade = getIdade(chapaAux.suplente1?.dataNascimento);
					}
					if (chapaAux.suplente2?.dataNascimento) {
						chapaAux.suplente2.idade = getIdade(chapaAux.suplente2?.dataNascimento);
					}
					return chapaAux;
				});
			setChapas(chapasAux);
		}
	}, [dataChapas]);

	useEffect(() => {
		if (dataDelegados) {
			debugLog('[dataDelegados]: ', dataDelegados);
			const delegadosAux = dataDelegados.list
				.map(delegadoAux => cloneDeep(delegadoAux))
				.map(delegadoAux => {
					if (delegadoAux.delegado?.dataNascimento) {
						delegadoAux.delegado.idade = getIdade(delegadoAux.delegado?.dataNascimento);
					}
					return delegadoAux;
				});
			setDelegados(delegadosAux);
		}
	}, [dataDelegados]);

	useEffect(() => {
		if (chapa) {
			debugLog('chapa: ', chapa);
		}
	}, [chapa]);

	useEffect(() => {
		if (delegado) {
			debugLog('delegado: ', delegado);
		}
	}, [delegado]);

	useEffect(() => {
		if (size(messagesChapa) > 0) {
			debugLog('[messagesChapa]: ', messagesChapa);
			if (size(messagesChapa.messages) > 0) {
				setShowPopupMessage(messagesChapa);
			} else {
				for (const message of messagesChapa) {
					createMessage(message, 10);
				}
			}
			dispatch(setMessages(null));
		}
	}, [createMessage, dispatch, messagesChapa]);

	useEffect(() => {
		if (messageDelegado) {
			debugLog('[messageDelegado]: ', messageDelegado);
			if (size(messageDelegado.message) > 0) {
				createMessage(messageDelegado.message, 10);
			} else {
				createMessage(messageDelegado, 5);
			}
			dispatch(setMessage(null));
		}
	}, [createMessage, dispatch, messageDelegado]);

	const salvarChapa = useCallback(
		situacao => {
			let errors = {};
			if (situacao === 'impugnada' && size(motivoImpugnacao) === 0) {
				errors.motivoImpugnacao = ['Você deve informar o motivo da impugnação'];
			}
			if (size(errors) > 0) {
				setErrors(errors);
			} else {
				setLoading(true);
				dispatch(saveChapa({ ...chapa, situacao, motivoImpugnacao, analise: true }));
			}
		},
		[chapa, dispatch, motivoImpugnacao]
	);

	useEffect(() => {
		if (situacaoAnaliseChapa && chapa) {
			if (situacaoAnaliseChapa !== 'erro') {
				setChapas(old =>
					old.map(c =>
						c.id === chapa.id
							? { ...c, situacao: situacaoAnaliseChapa === 'removida' ? null : situacaoAnaliseChapa, motivoImpugnacao }
							: c
					)
				);
				setMotivoImpugnacao(null);
				setImpugnar(false);
				setChapa(null);
				setLoading(false);
			}
			dispatch(setSituacaoAnaliseChapa(null));
		}
	}, [chapa, dispatch, motivoImpugnacao, situacaoAnaliseChapa]);

	const salvarDelegado = useCallback(
		situacao => {
			let errors = {};
			if (situacao === 'impugnada' && size(motivoImpugnacao) === 0) {
				errors.motivoImpugnacao = ['Você deve informar o motivo da impugnação'];
			}
			if (size(errors) > 0) {
				setErrors(errors);
			} else {
				setLoading(true);
				dispatch(saveDelegado({ ...delegado, situacao, motivoImpugnacao, analise: true }));
			}
		},
		[delegado, dispatch, motivoImpugnacao]
	);

	useEffect(() => {
		if (situacaoAnaliseDelegado && delegado) {
			if (situacaoAnaliseDelegado !== 'erro') {
				setDelegados(old =>
					old.map(c =>
						c.id === delegado.id
							? {
									...c,
									situacao: situacaoAnaliseDelegado === 'removida' ? null : situacaoAnaliseDelegado,
									motivoImpugnacao
							  }
							: c
					)
				);
				setMotivoImpugnacao(null);
				setImpugnar(false);
				setDelegado(null);
				setLoading(false);
			}
			dispatch(setSituacaoAnaliseDelegado(null));
		}
	}, [delegado, dispatch, motivoImpugnacao, situacaoAnaliseDelegado]);

	// eslint-disable-next-line
	const hidePopupMessage = useCallback(() => {
		if (showPopupMessage.type === 'success') {
			setShowPopupMessage(false);
			dispatch(limpaStore());
			dispatch(setAba(ABA_ANALISE));
			if (window.location.pathname !== '/home') {
				history.push('/home');
			}
		} else {
			setShowPopupMessage(false);
		}
	});

	const selecionado = useMemo(() => chapa || delegado, [chapa, delegado]);

	return loadingRegiao ? (
		<Loader msg="Carregando dados das regiões..." />
	) : errorRegiao ? (
		<ErrorMessages errorList={['Problemas ao carregar regiões']} />
	) : (
		<>
			<div>
				<div className="titulo-pagina">
					<h1>Análise de Chapas{LIBERADA_IMPUGNACAO_DELEGADO && ' e Delegados'}</h1>
				</div>
				<div className="row">
					<div className="form-group col-12">
						<label className="control-label required">Região</label>
						<Selecao
							className="form-control"
							selected={regiao}
							label={'região'}
							detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
							detailCodigo={''}
							detailDescricao={'nome'}
							detailModifier={text => text}
							autoShowList={false}
							searchTerm={regiaoSearchTerm}
							searchList={regioes.filter(r => r.nome.toLowerCase().includes(regiaoSearchTerm.toLowerCase()))}
							searchTermMinLength={0}
							errorList={[]}
							onChangeSearchTerm={e => {
								setRegiaoSearchTerm(e.target.value);
							}}
							onBlurSearchTerm={() => false}
							onSelectItem={item => () => setRegiao(item)}
							onUnselect={() => () => setRegiao(null)}
							noResetList={true}
							loading={loadingRegiao}
							placeholder={'Selecione uma região'}
							maxDescricaoLength={35}
						/>
					</div>
				</div>
				{regiao &&
					(loadingChapas || loadingDelegados ? (
						<Loader msg="Carregando dados da região..." />
					) : errorChapas || errorDelegados ? (
						<ErrorMessages errorList={['Problemas ao carregar chapas e/ou delegados da região']} />
					) : (
						<>
							{LIBERADA_IMPUGNACAO_DELEGADO && <h2>Chapas</h2>}
							<ul className="list">
								{size(chapas) === 0 ? (
									<p className="h3">Ainda não há chapas inscritas para esta região</p>
								) : (
									chapas.map((chapa, i) => (
										<li
											key={chapa.id}
											className={`analise-item list-items ${i % 2 === 0 ? 'even' : 'odd'}`}
											onClick={() => setChapa(chapa)}
										>
											<div className="analise-component">Chapa {chapa.numero}</div>
											<div className="analise-buttons">
												{chapa.situacao && (
													<div className={`analise-badge ${chapa.situacao}`}>
														inscrição {chapa.situacao?.toLowerCase()}
													</div>
												)}
											</div>
										</li>
									))
								)}
							</ul>
							{LIBERADA_IMPUGNACAO_DELEGADO && (
								<>
									<h2>Delegados</h2>
									<ul className="list">
										{size(delegados) === 0 ? (
											<p className="h3">Ainda não há delegados cadastrados para esta região</p>
										) : (
											delegados.map((delegado, i) => (
												<li
													key={delegado.id}
													className={`analise-item list-items ${i % 2 === 0 ? 'even' : 'odd'}`}
													onClick={() => setDelegado(delegado)}
												>
													<div className="analise-component">{delegado.delegado.nome}</div>
													<div className="analise-buttons">
														{delegado.situacao && (
															<div className={`analise-badge ${delegado.situacao}`}>
																inscrição {delegado.situacao?.toLowerCase()}
															</div>
														)}
													</div>
												</li>
											))
										)}
									</ul>
								</>
							)}
						</>
					))}
				{(chapa || delegado) && (
					<div
						className="modal"
						style={{
							backgroundColor: 'rgba(0,0,0,.5)',
							display: 'flex',
							flexFlow: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						role="dialog"
					>
						<div className="modal-dialog" role="document" style={{ width: '800px', maxWidth: '90vw' }}>
							<div className="modal-content">
								<div className="modal-header">
									<h1 className="h3 modal-title">{`${chapa ? `Chapa ${chapa.numero}` : 'Delegado'} para região ${
										selecionado.regiao.nome
									}`}</h1>
									<button
										type="button"
										className="close"
										aria-label="Fechar janela"
										onClick={() => {
											setChapa(null);
											setDelegado(null);
										}}
									>
										<i className="fa fa-times"></i>
									</button>
								</div>

								<div className="modal-body">
									<div className="row">{chapa && <AnaliseChapaComponent chapa={chapa} />}</div>
									{delegado && <AnaliseDelegadoComponent delegado={delegado} />}
								</div>
								{size(validationMessages) > 0 && <ErrorMessages errorList={validationMessages} />}
								{!impugnar && (
									<div className="modal-footer">
										<div className="buttons">
											{selecionado.situacao ? (
												<button
													type="button"
													className="btn btn-outline-danger"
													onClick={() => {
														setMotivoImpugnacao(null);
														setImpugnar(false);
														setErrors({});
														if (chapa) {
															salvarChapa(null);
														} else {
															salvarDelegado(null);
														}
													}}
												>
													<i className="fa fa-times" aria-hidden="true"></i>
													{`Cancelar ${selecionado.situacao === 'impugnada' ? 'Impugnação' : 'Confirmação'}`}
												</button>
											) : (
												<>
													<button type="button" className="btn btn-danger" onClick={() => setImpugnar(true)}>
														Impugnar...
													</button>
													<button
														type="button"
														className="btn btn-primary"
														onClick={() => {
															// setMotivoImpugnacao(null);
															// setImpugnar(false);
															setErrors({});
															if (chapa) {
																salvarChapa('confirmada');
															} else {
																salvarDelegado('confirmada');
															}
														}}
													>
														Confirmar Inscrição
													</button>
												</>
											)}
										</div>
									</div>
								)}
								{impugnar && (
									<>
										<div className="modal-body">
											<div className="form-group">
												<label className="required">Motivo da Impugnação</label>
												<textarea
													className={`form-control${errors.motivoImpugnacao ? ' form-control-error' : ''}`}
													value={motivoImpugnacao || ''}
													rows="3"
													onChange={e => setMotivoImpugnacao(e.target.value)}
												/>
												{errors.motivoImpugnacao && <ErrorMessages errorList={errors.motivoImpugnacao} />}
											</div>

											<div className="buttons">
												<button
													type="button"
													className="btn btn-outline-danger"
													onClick={() => {
														setMotivoImpugnacao(null);
														setErrors({});
														setImpugnar(false);
													}}
												>
													<i className="fa fa-times" aria-hidden="true"></i>Cancelar
												</button>
												<button
													type="button"
													className="btn btn-danger"
													onClick={() => {
														// setMotivoImpugnacao(null);
														// setImpugnar(false);
														setErrors({});
														if (chapa) {
															salvarChapa('impugnada');
														} else {
															salvarDelegado('impugnada');
														}
													}}
												>
													Impugnar Inscrição
												</button>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
						{loading && <Loader msg="Salvando..." />}
					</div>
				)}
				{size(showPopupMessage) > 0 && (
					<ModalMessage
						showPopupMessage={showPopupMessage}
						hidePopupMessage={hidePopupMessage}
						validationMessages={validationMessages}
					/>
				)}
			</div>
		</>
	);
}
AnaliseComponent.displayName = 'AnaliseComponent';

export default AnaliseComponent;
