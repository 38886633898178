import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from 'react-apollo';

import extenso from 'extenso';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { cloneDeep, size } from 'lodash';
import moment from 'moment';

import ErrorMessages from '../../../components/ErrorMessages';
import Loader from '../../../components/Loader';
import Selecao from '../../../components/Selecao';
import useCalendario from '../../../customHooks/useCalendario';
import useMessages from '../../../customHooks/useMessages';
import apolloClient from '../../../utils/graphql';
import { accessApi, isDebug } from '../../../utils/tools';
import RegiaoApuracao from './regiaoApuracao';

const regiaoQuery = loader('./regiao.gql');
const chapasQuery = loader('./chapas.gql');
const delegadosQuery = loader('./delegados.gql');
const updateQuery = loader('./updateRegiaoComEleitos.gql');

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[APURACAO ${i++}]:`, ...args);

function ApuracaoComponent() {
	/* CUSTOM HOOKS */
	const { calendario, loading: loadingCalendario, error: errorCalendario } = useCalendario();
	const { createMessage } = useMessages();

	/* ESTADOS */
	const [regiaoApurada, setRegiaoApurada] = useState();
	const [loadingMessages, setLoadingMessages] = useState([]);
	const [regiao, setRegiao] = useState(null);
	const [regioesSelecao, setRegioesSelecao] = useState();
	const [regiaoSearchTerm, setRegiaoSearchTerm] = useState('');
	const [relatorio, setRelatorio] = useState(null);
	const [errors, setErrors] = useState({});

	const { data: dataRegiao, loading: loadingRegiao, errors: errorRegiao } = useQuery(regiaoQuery, {
		variables: {},
		ssr: true,
		fetchPolicy: 'network-only'
	});

	const [updateRegiaoComEleitos, { data: dataEleitos, error: errorEleitos }] = useMutation(updateQuery);

	const obtemChapas = useCallback(async (regiaoId, calendario) => {
		const variables = {
			term: JSON.stringify({
				calendario,
				regiao: regiaoId,
				situacao: 'confirmada'
			}),
			limit: 100000,
			skip: 0
		};
		const {
			data: { list }
		} = await apolloClient.query({ query: chapasQuery, variables });
		return list;
	}, []);

	const obtemDelegados = useCallback(async (regiaoId, calendario) => {
		const variables = {
			term: JSON.stringify({
				calendario,
				regiao: regiaoId
				// situacao: 'confirmada'
			}),
			limit: 100000,
			skip: 0
		};
		const {
			data: { list }
		} = await apolloClient.query({ query: delegadosQuery, variables });
		return list;
	}, []);

	const contaVotos = useCallback(async (calendario, regiaoId, chapaId, delegadoId) => {
		const {
			data: { count }
		} = await apolloClient.query({
			query: gql`
				query ECmduaVotoCount($term: String!) {
					count: ECmduaVotoCount(term: $term)
				}
			`,
			variables: {
				term: JSON.stringify({
					calendario,
					regiao: regiaoId,
					chapa: chapaId || { $exists: false },
					delegado: delegadoId || { $exists: false }
				})
			},
			ssr: true,
			fetchPolicy: 'network-only'
		});
		return count;
	}, []);

	const empateChapa = useCallback(regiao => {
		let chapaEmpate = false;
		const chapaQtdUltEleito = regiao.chapas[0]?.votos || 0;
		const chapaDtNascUltEleito = regiao.chapas[0]?.titular?.dataNascimento;
		const temEleitos = regiao.chapas.some(chapa => chapa.votos > 0);
		regiao.chapas.forEach((chapa, index) => {
			const eleito = index === 0 && chapa.votos > 0;
			if (!eleito && temEleitos && chapa.titular.dataNascimento) {
				if (chapaQtdUltEleito === chapa.votos && chapaDtNascUltEleito === chapa.titular.dataNascimento) {
					chapaEmpate = true;
				}
			}
		});
		return [chapaEmpate, chapaQtdUltEleito, chapaDtNascUltEleito];
	}, []);

	const empateDelegado = useCallback(regiao => {
		let delegadoEmpate = false;
		let delegadoQtdUltEleito = 1000000;
		let delegadoDtNascUltEleito = null;
		const temEleitos = regiao.delegados.some(delegado => delegado.votos > 0);
		regiao.delegados.forEach((delegado, index) => {
			const eleito = index < regiao.totalDelegadosEleitos && delegado.votos > 0;
			if (eleito && delegadoQtdUltEleito >= delegado.votos) {
				delegadoQtdUltEleito = delegado.votos;
				delegadoDtNascUltEleito = delegado.delegado.dataNascimento;
			}
			if (!eleito && temEleitos && delegado.delegado.dataNascimento) {
				if (delegadoQtdUltEleito === delegado.votos && delegadoDtNascUltEleito === delegado.delegado.dataNascimento) {
					delegadoEmpate = true;
				}
			}
		});
		let delegadoVagasEmpate = 0;
		regiao.delegados.forEach((delegado, index) => {
			const eleito = index < regiao.totalDelegadosEleitos && delegado.votos > 0;
			const isEmpatado =
				delegadoEmpate &&
				delegado.votos === delegadoQtdUltEleito &&
				delegado.delegado.dataNascimento === delegadoDtNascUltEleito;
			delegadoVagasEmpate += eleito && isEmpatado ? 1 : 0;
		});
		return [delegadoEmpate, delegadoQtdUltEleito, delegadoDtNascUltEleito, delegadoVagasEmpate];
	}, []);

	const assembleRegiao = useCallback(
		async (calendario, regiao) => {
			let regiaoApuradaAux = cloneDeep(regiao);

			setLoadingMessages(old => [...old, `Contando votos das chapas da regiao ${regiaoApuradaAux.nome}`]);
			let total = 0;
			regiaoApuradaAux.chapas = [];
			const chapas = await obtemChapas(regiaoApuradaAux.id, calendario);
			for (const chapa of chapas) {
				const votos = await contaVotos(calendario, regiaoApuradaAux.id, chapa.id, null);
				total += votos;
				debugLog('votos na chapa: ', chapa.numero, 'da', regiaoApuradaAux.nome, ':', votos);
				regiaoApuradaAux.chapas.push({ ...chapa, votos });
			}
			regiaoApuradaAux.totalVotosChapas = total;
			regiaoApuradaAux.chapas.sort((a, b) => {
				let saida = a.votos < b.votos ? 1 : a.votos > b.votos ? -1 : 0;
				if (saida === 0) {
					const momentB = moment(b.titular.dataNascimento, 'DD/MM/YYYY');
					const momentA = moment(a.titular.dataNascimento, 'DD/MM/YYYY');
					const isBefore = momentB.isBefore(momentA);
					const isAfter = momentB.isAfter(momentA);
					saida = isBefore ? 1 : isAfter ? -1 : 0; // ordem crescente
					if (saida === 0) {
						const aEleito = regiaoApuradaAux?.chapaEleita?.id === a.id;
						const bEleito = regiaoApuradaAux?.chapaEleita?.id === b.id;
						saida = aEleito ? -1 : bEleito ? 1 : 0;
					}
				}
				return saida;
			});

			setLoadingMessages(old => [...old, `Contando votos dos delegados da regiao ${regiaoApuradaAux.nome}`]);
			total = 0;
			regiaoApuradaAux.delegados = [];
			const delegados = await obtemDelegados(regiaoApuradaAux.id, calendario);
			for (const delegado of delegados) {
				const votos = await contaVotos(calendario, regiaoApuradaAux.id, null, delegado.id);
				total += votos;
				debugLog('votos no delegado', delegado.delegado.nome, 'da', regiaoApuradaAux.nome, ':', votos);
				regiaoApuradaAux.delegados.push({ ...delegado, votos });
			}
			regiaoApuradaAux.totalVotosDelegados = total;
			const umQuarto = Math.ceil(total / 4);
			regiaoApuradaAux.totalDelegadosEleitos = size(delegados) > umQuarto ? umQuarto : size(delegados);
			regiaoApuradaAux.delegados.sort((a, b) => {
				let saida = a.votos < b.votos ? 1 : a.votos > b.votos ? -1 : 0;
				if (saida === 0) {
					const momentB = moment(b.delegado.dataNascimento, 'DD/MM/YYYY');
					const momentA = moment(a.delegado.dataNascimento, 'DD/MM/YYYY');
					const isBefore = momentB.isBefore(momentA);
					const isAfter = momentB.isAfter(momentA);
					saida = isBefore ? 1 : isAfter ? -1 : 0; // ordem crescente
					if (saida === 0) {
						const aEleito = (regiaoApuradaAux.delegadosEleitos || []).find(d => d.id === a.id);
						const bEleito = (regiaoApuradaAux.delegadosEleitos || []).find(d => d.id === b.id);
						saida = aEleito ? -1 : bEleito ? 1 : 0;
					}
				}
				return saida;
			});

			const [chapaEmpate, chapaQtdUltEleito, chapaDtNascUltEleito] = regiaoApuradaAux.chapaEleita
				? [false, 0, null]
				: empateChapa(regiaoApuradaAux);

			regiaoApuradaAux.chapaEmpate = chapaEmpate;
			regiaoApuradaAux.chapaQtdUltEleito = chapaQtdUltEleito;
			regiaoApuradaAux.chapaDtNascUltEleito = chapaDtNascUltEleito;
			regiaoApuradaAux.qtdChapasAlteradas = 0;

			const [delegadoEmpate, delegadoQtdUltEleito, delegadoDtNascUltEleito, vagasEmpate] =
				size(regiaoApuradaAux.delegadosEleitos) > 0 ? [false, 0, null, 0] : empateDelegado(regiaoApuradaAux);

			regiaoApuradaAux.delegadoEmpate = delegadoEmpate;
			regiaoApuradaAux.delegadoQtdUltEleito = delegadoQtdUltEleito;
			regiaoApuradaAux.delegadoDtNascUltEleito = delegadoDtNascUltEleito;
			regiaoApuradaAux.delegadoVagasEmpate = vagasEmpate;
			regiaoApuradaAux.qtdDelegadosAlterados = 0;

			const teveVotosEmChapas = regiaoApuradaAux.chapas.some(chapa => chapa.votos > 0);
			regiaoApuradaAux.chapas = regiaoApuradaAux.chapas.map((c, index) => {
				const isEleita = index === 0 && teveVotosEmChapas;
				const isEmpatada =
					chapaEmpate && c.votos === chapaQtdUltEleito && c.titular.dataNascimento === chapaDtNascUltEleito;
				c.isEleita = isEleita;
				c.isEmpatada = isEmpatada;
				c.className = isEleita ? (isEmpatada ? ' empatada' : ' eleita') : isEmpatada ? ' empatada' : '';
				c.dataNascimentoTitular = isEmpatada ? c.titular.dataNascimento : '';
				c.numeroExtenso = extenso(c.numero);
				c.percentual = (((c.votos || 0) / regiaoApuradaAux.totalVotosChapas) * 100).toFixed(2).replace('.', ',');
				c.resultado = isEleita ? (isEmpatada ? 'empatada' : 'eleita') : isEmpatada ? 'empatada' : 'não eleita';
				return c;
			});

			const teveVotosEmDelegados = regiaoApuradaAux.delegados.some(delegado => delegado.votos > 0);
			regiaoApuradaAux.delegados.map((d, index) => {
				const isEleito = index < regiaoApuradaAux.totalDelegadosEleitos && teveVotosEmDelegados;
				const isEmpatado =
					delegadoEmpate && d.votos === delegadoQtdUltEleito && d.delegado.dataNascimento === delegadoDtNascUltEleito;
				d.isEleito = isEleito;
				d.isEmpatado = isEmpatado;
				d.className = isEleito ? (isEmpatado ? ' empatada' : ' eleita') : isEmpatado ? ' empatada' : '';
				d.dataNascimento = isEmpatado ? d.delegado.dataNascimento : '';
				d.nome = d.delegado.nome;
				d.percentual = (((d.votos || 0) / regiaoApuradaAux.totalVotosDelegados) * 100).toFixed(2).replace('.', ',');
				d.resultado = isEleito ? (isEmpatado ? 'empatado' : 'eleito') : isEmpatado ? 'empatado' : 'não eleito';
				return d;
			});

			return regiaoApuradaAux;
		},
		[contaVotos, empateChapa, empateDelegado, obtemChapas, obtemDelegados]
	);

	const preparaRegiao = useCallback(
		async (calendario, regiao) => {
			let regiaoApuradaAux = await assembleRegiao(calendario, regiao);
			setLoadingMessages([]);
			setRegiaoApurada(regiaoApuradaAux);
		},
		[assembleRegiao]
	);

	useEffect(() => {
		if (dataRegiao?.list && !regioesSelecao) {
			setRegioesSelecao(dataRegiao.list);
		}
	}, [dataRegiao, regioesSelecao]);

	useEffect(() => {
		if (regiao && calendario) {
			debugLog('[regiao, calendario]: ', regiao, calendario);
			preparaRegiao(calendario.calendario, regiao);
		}
	}, [calendario, preparaRegiao, regiao]);

	useEffect(() => {
		debugLog('[regiao]: ', regiao);
	}, [regiao]);

	const geraRelatorioFinal = useCallback(async () => {
		const regioes = [];
		for (const r of regioesSelecao) {
			let regiaoApuradaAux = await assembleRegiao(calendario.calendario, r);
			regioes.push(regiaoApuradaAux);
		}
		const data = {
			regioes,
			calendario: calendario.calendario
		};
		debugLog('regioes apuradas: ', data);
		setLoadingMessages(old => [...old, 'Gerando relatório final']);
		const response = await accessApi('/relatorios/apuracao?pdf=true&base64=true', true, { method: 'post', data });
		setRelatorio(response.data);
		setLoadingMessages([]);
	}, [regioesSelecao, assembleRegiao, calendario]);

	const geraRelatorioFinalRegiao = useCallback(async () => {
		const regioes = [regiaoApurada];
		const data = {
			regioes,
			calendario: calendario.calendario
		};
		debugLog('regioes apuradas: ', data);
		setLoadingMessages(old => [...old, 'Gerando relatório final']);
		const response = await accessApi('/relatorios/apuracao?pdf=true&base64=true', true, { method: 'post', data });
		setRelatorio(response.data);
		setLoadingMessages([]);
	}, [regiaoApurada, calendario]);

	useEffect(() => {
		if (relatorio) {
			const link = document.createElement('a');
			link.href = relatorio.link;
			link.download = relatorio.docName;
			link.target = '_blank';
			link.click();
		}
	}, [calendario, relatorio]);

	const toggleChapa = chapa => () => {
		const alterada = chapa.resultado === 'empatada';
		const podeAlterar = regiaoApurada.qtdChapasAlteradas === 0 || chapa.resultado === 'eleita';
		if (podeAlterar) {
			const regiaoApuradaAux = {
				...regiaoApurada,
				chapas: regiaoApurada.chapas.map(c =>
					c.id === chapa.id
						? {
								...c,
								alterada: alterada,
								resultado: c.resultado === 'eleita' ? 'empatada' : 'eleita'
						  }
						: c
				)
			};
			const qtdChapasAlteradas = regiaoApuradaAux.chapas.reduce((acc, c) => acc + (c.alterada ? 1 : 0), 0);
			regiaoApuradaAux.qtdChapasAlteradas = qtdChapasAlteradas;
			setRegiaoApurada(regiaoApuradaAux);
		} else {
			createMessage('Apenas uma chapa pode ser eleita.', 2);
		}
	};

	const toggleDelegado = delegado => () => {
		const alterado = delegado.resultado === 'empatado';
		const podeAlterar =
			regiaoApurada.qtdDelegadosAlterados < regiaoApurada.delegadoVagasEmpate || delegado.resultado === 'eleito';
		if (podeAlterar) {
			const regiaoApuradaAux = {
				...regiaoApurada,
				delegados: regiaoApurada.delegados.map(d =>
					d.id === delegado.id
						? {
								...d,
								alterado: alterado,
								resultado: d.resultado === 'eleito' ? 'empatado' : 'eleito'
						  }
						: d
				)
			};
			const qtdDelegadosAlterados = regiaoApuradaAux.delegados.reduce((acc, d) => acc + (d.alterado ? 1 : 0), 0);
			regiaoApuradaAux.qtdDelegadosAlterados = qtdDelegadosAlterados;
			setRegiaoApurada(regiaoApuradaAux);
		} else {
			createMessage('Não há mais vagas disponíveis para delegados.', 2);
		}
	};

	const persisteRegiao = async () => {
		const { chapas = [], delegados = [] } = regiaoApurada || {};
		const cEleita = chapas.filter(c => c.resultado === 'eleita')?.[0];
		const dEleitos = delegados.filter(d => d.resultado === 'eleito').map(d => ({ id: d.id }));
		let errors = {};
		if (regiaoApurada.chapaEmpate && regiaoApurada.totalVotosChapas > 0 && !cEleita) {
			errors.chapaEleita = ['Você deve resolver o empate entre chapas antes de salvar.'];
		}
		if (
			regiaoApurada.delegadoEmpate &&
			regiaoApurada.totalVotosDelegados > 0 &&
			size(dEleitos) < regiaoApurada.totalDelegadosEleitos
		) {
			errors.delegadosEleitos = [
				'Você deve resolver os empates entre chapas e preencher todas as vagas disponíveis antes de finalizar a apuração.'
			];
		}
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			const variables = {
				id: regiaoApurada.id,
				delegadosEleitos: size(dEleitos) ? dEleitos : null,
				chapaEleita: cEleita ? { id: cEleita.id } : null
			};
			setLoadingMessages(['Atualizando regiões com eleitos']);
			updateRegiaoComEleitos({ variables });
		}
	};

	useEffect(() => {
		if (dataEleitos?.item) {
			setRegiao(dataEleitos.item);
		}
	}, [calendario, dataEleitos, preparaRegiao, regiao]);

	useEffect(() => {
		if (errorEleitos) {
			setLoadingMessages([]);
			createMessage('Houve um problema ao atualizar a região com eleitos. Tente novamente', 5);
		}
	}, [createMessage, errorEleitos]);

	const apuracaoEmAndamento = useMemo(() => {
		const { chapaEleita, totalVotosChapas, delegadosEleitos, totalVotosDelegados, totalDelegadosEleitos } =
			regiaoApurada || {};
		const cEmAndamento = !chapaEleita && totalVotosChapas > 0;
		const dEmAndamento = size(delegadosEleitos) < totalDelegadosEleitos && totalVotosDelegados > 0;
		return cEmAndamento || dEmAndamento;
	}, [regiaoApurada]);

	return loadingCalendario || loadingRegiao ? (
		<Loader msg="Inicializando apuração" />
	) : size(loadingMessages) > 0 ? (
		<Loader msg={loadingMessages} />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar o calendário']} />
	) : errorRegiao ? (
		<ErrorMessages errorList={['Problemas ao carregar as regiões']} />
	) : (
		<div>
			<div className="titulo-pagina">
				<h1>Apuração de votos</h1>
				<div className="buttons">
					<button type="button" className="btn btn-primary" onClick={geraRelatorioFinal}>
						Gerar Relatório da Apuração
					</button>
				</div>
			</div>
			<div className="row">
				<div className="form-group col-12">
					<label className="control-label required">Região</label>
					<Selecao
						className="form-control"
						selected={null}
						label={'região'}
						detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
						detailCodigo={''}
						detailDescricao={'nome'}
						detailModifier={text => text}
						autoShowList={false}
						searchTerm={regiaoSearchTerm}
						searchList={(regioesSelecao || []).filter(r =>
							r.nome.toLowerCase().includes(regiaoSearchTerm.toLowerCase())
						)}
						searchTermMinLength={0}
						errorList={[]}
						onChangeSearchTerm={e => {
							setRegiaoSearchTerm(e.target.value);
						}}
						onBlurSearchTerm={() => false}
						onSelectItem={item => () => {
							setRegiao(null);
							setTimeout(() => {
								setRegiao(item);
							}, 100);
						}}
						onUnselect={() => () => setRegiao(null)}
						noResetList={true}
						loading={loadingRegiao}
						placeholder={'Selecione uma região'}
						maxDescricaoLength={35}
					/>
				</div>
			</div>
			{regiaoApurada && (
				<RegiaoApuracao
					regiao={regiaoApurada}
					errors={errors}
					onReport={geraRelatorioFinalRegiao}
					toggleChapa={toggleChapa}
					toggleDelegado={toggleDelegado}
					emAndamento={apuracaoEmAndamento}
				/>
			)}
			{size(errors) > 0 && <ErrorMessages errorList={['Há erros acima. Corrija e tente novamente.']} />}
			{apuracaoEmAndamento && (
				<div className="buttons">
					<button type="button" className="btn btn-primary" onClick={persisteRegiao}>
						Finalizar Apuração
					</button>
				</div>
			)}
		</div>
	);
}
ApuracaoComponent.displayName = 'ApuracaoComponent';
ApuracaoComponent.propTypes = {};

export default ApuracaoComponent;
