import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-apollo';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { size, trim, omit } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import uuid from 'uuid/v4';

import CdlField from '../../components/CdlField';
import ErrorMessages from '../../components/ErrorMessages';
import FileUpload from '../../components/file-upload/file-upload.component';
import Loader from '../../components/Loader';
import ModalMessage from '../../components/ModalMessage';
import { ABA_PARTICIPANTES } from '../../components/Navigation';
import Navigation from '../../components/Navigation';
import Selecao from '../../components/Selecao';
import useCalendario from '../../customHooks/useCalendario';
import useMessages from '../../customHooks/useMessages';
import { setAba } from '../../Redux/Home/homeSlice';
import {
	removeParticipante,
	saveParticipante,
	setCurrent,
	setMessage,
	limpaStore,
	setErrorMessage
} from '../../Redux/participante/participanteSlice';
import { history } from '../../Redux/store';
import { isCPF, isDebug, isEmail, send, isDate, isExternal, permiteInput } from '../../utils/tools';

const participanteQuery = loader('./participanteQuery.gql');

const DOCUMENTO_PROPS = ['comprovanteCpf', 'comprovanteResidencia', 'declaracaoTitularConta'];

let i = 0;

const debugLog = (...args) => isDebug && console.debug(`[PARTICIPANTE ${i++}]:`, ...args);

function Participante() {
	/* REDUX */
	const dispatch = useDispatch();
	const participante = useSelector(state => state.participante.current);
	const updating = useSelector(state => state.participante.updating);
	const message = useSelector(state => state.participante.message);
	const errorMessage = useSelector(state => state.participante.errorMessage);
	const userProfile = useSelector(state => state.keycloak.userProfile);

	/* CUSTOM HOOKS */
	const { createMessage } = useMessages();
	const { calendario, loading: loadingCalendario, error: errorCalendario, isAfter } = useCalendario();

	/* ESTADOS */
	const { id } = useParams();
	const [errors, setErrors] = useState({});
	const [showPopupRemove, setShowPopupRemove] = useState(false);
	const [showPopupMessage, setShowPopupMessage] = useState(false);
	const [regioes, setRegioes] = useState([]);
	const [regiaoSearchTerm, setRegiaoSearchTerm] = useState('');

	const {
		data: dataParticipante,
		loading: loadingParticipante,
		errors: errorParticipante
	} = useQuery(participanteQuery, { variables: { id }, fetchPolicy: 'network-only' });

	const { data: dataChapas, loading: loadingChapas, errors: errorChapas, refetch: refetchChapas } = useQuery(
		gql`
			query count($term: String) {
				count: ECmduaChapaCount(term: $term)
			}
		`,
		{
			variables: {
				term: 'ninguém tem'
			},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		const getChapaTermo = () => {
			const termoAux = {
				$or: [
					{ titular: dataParticipante?.item?.id },
					{ suplente1: dataParticipante?.item?.id },
					{ suplente2: dataParticipante?.item?.id }
				]
			};
			return JSON.stringify(termoAux);
		};

		if (dataParticipante?.item) {
			refetchChapas({ term: getChapaTermo() });
		}
	}, [dataParticipante, refetchChapas]);

	const {
		data: dataDelegados,
		loading: loadingDelegados,
		errors: errorDelegados,
		refetch: refetchDelegados
	} = useQuery(
		gql`
			query count($term: String) {
				count: ECmduaDelegadoCount(term: $term)
			}
		`,
		{
			variables: {
				term: 'ninguém tem'
			},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		const getDelegadoTermo = () => {
			const termoAux = {
				delegado: dataParticipante?.item?.id
			};
			return JSON.stringify(termoAux);
		};

		if (dataParticipante?.item) {
			refetchDelegados({ term: getDelegadoTermo() });
		}
	}, [dataParticipante, refetchDelegados]);

	const isCandidatoChapa = useMemo(() => dataChapas?.count > 0, [dataChapas]);
	const isCandidatoDelegado = useMemo(() => dataDelegados?.count > 0, [dataDelegados]);

	const { data: dataRegiao, loading: loadingRegiao, errors: errorRegiao } = useQuery(
		gql`
			query ListaRegioes($term: String, $skip: Int, $limit: Int) {
				list: ECmduaRegiaoList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					numero
					bairros
				}
				count: ECmduaRegiaoCount
			}
		`,
		{
			variables: {},
			ssr: true,
			fetchPolicy: 'network-only'
		}
	);

	const onNavigateHandler = useCallback(() => {
		dispatch(limpaStore());
	}, [dispatch]);

	useEffect(() => {
		if (size(message) > 0) {
			debugLog('[message]: ', message);
			if (message.message) {
				setShowPopupMessage(message);
			} else {
				createMessage(message, 15);
			}
			dispatch(setMessage(null));
		}
	}, [createMessage, dispatch, message]);

	useEffect(() => {
		if (id === 'new' && !participante) {
			debugLog('[id]: ', id);
			dispatch(setCurrent({ id: uuid() }));
		}
	}, [dispatch, id, participante]);

	useEffect(() => {
		if (errorMessage) {
			debugLog('[errorMessage]: ', errorMessage);
			const newMessage = { message: errorMessage, type: 'error', timeout: 5 };
			createMessage(newMessage);
			dispatch(setErrorMessage(null));
		}
	}, [createMessage, dispatch, errorMessage]);

	useEffect(() => {
		if (dataParticipante && id !== 'new') {
			debugLog('[id, queryData]: ', id, dataParticipante);
			let pAux = cloneDeep(dataParticipante.item);

			DOCUMENTO_PROPS.filter(prop => pAux[prop]).forEach(prop => {
				pAux[prop].docName = `${prop}-${pAux.id}`;
			});

			dispatch(setCurrent(pAux));
		}
	}, [dataParticipante, dispatch, id]);

	useEffect(() => {
		if (dataRegiao) {
			debugLog('[queryRegiao]: ', dataRegiao);
			setRegioes(dataRegiao.list);
		}
	}, [dataRegiao]);

	useEffect(() => {
		if (participante) {
			if (participante.regiao && !participante.endereco && isExternal) {
				debugLog('[participante]: ', participante);
				dispatch(setCurrent({ ...participante, regiao: null }));
			}
		}
	}, [dispatch, participante]);

	const regioesDisponiveis = useMemo(() => {
		let output = [];
		if (participante?.endereco && participante?.endereco.numero) {
			const nomeBairro = participante?.endereco?.nomeBairro;

			output = (regioes || []).filter(r => r.bairros.includes(nomeBairro?.toLowerCase()));

			if (size(output) === 0) {
				console.error('Bairro não encontrado em nenhuma região:', nomeBairro);
				output = regioes;
			}
			if (size(output) === 1) {
				if (participante.regiao?.id !== output[0].id) {
					dispatch(setCurrent({ ...participante, regiao: output[0] }));
				}
			}
			output = output.filter(r => r.nome.toLowerCase().includes(regiaoSearchTerm.toLowerCase()));
		} else {
			if (!isExternal) {
				output = regioes;
			}
		}
		return output;
	}, [dispatch, participante, regiaoSearchTerm, regioes]);

	const changeHandler = useCallback(
		participante => e => {
			let { name, value } = e.target;
			let allow = true;
			if (name === 'nome') {
				allow = permiteInput(value, 'text', 0, 100);
			} else if (name === 'email') {
				allow = permiteInput(value, 'email', 0, 100);
			} else if (name === 'tituloEleitor') {
				allow = permiteInput(value, 'number', 0, 12);
			}
			if (allow) {
				setErrors(old => omit(old, [name]) || {});
				dispatch(setCurrent({ ...participante, [name]: value }));
			}
		},
		[dispatch]
	);

	const valida = useCallback(async () => {
		let errors = {};
		if (size(trim(participante.nome)) === 0) {
			errors.nome = ['Informe o nome do participante'];
		} else {
			if (/[^A-Za-záâäàãéêëèẽíîïìĩóôöòõúûüùũ\s]/.test(participante.nome)) {
				errors.nome = ['Nome só pode possuir letras'];
			} else {
				const naoTemEspaco = !/\s/.test(participante.nome.trim());
				if (naoTemEspaco) {
					errors.nome = ['Informar nome completo'];
				}
			}
		}
		if (size(trim(participante.email)) === 0) {
			if (isExternal) {
				errors.email = ['Informe o e-mail do participante'];
			}
		} else if (!isEmail(participante.email)) {
			errors.email = ['E-mail inválido'];
		}
		if (size(trim(participante.telefone)) > 0) {
			const telefone = participante.telefone.replace(/\D/g, '');
			if (/^\d\d9/.test(telefone) && size(telefone) < 11) {
				errors.telefone = ['Telefone celular deve ser composto por DDD + 9 dígitos'];
			} else if (size(telefone) < 10) {
				errors.telefone = ['Telefone convencional deve ser composto por DDD + 8 dígitos'];
			}
		}
		if (size(trim(participante.dataNascimento)) === 0) {
			errors.dataNascimento = ['Informe a data de nascimento do participante'];
		} else if (size(participante.dataNascimento.replace(/\D/g, '')) !== 8) {
			errors.dataNascimento = ['Data de nascimento inválida'];
		} else if (!isDate(participante.dataNascimento)) {
			errors.dataNascimento = ['Data de nascimento inválida'];
		} else if (!isAtLeast(participante.dataNascimento, 16)) {
			errors.dataNascimento = ['A idade deve ser pelo menos 16 anos para votar e pelo menos 18 anos para concorrer'];
		}
		if (size(trim(participante.endereco)) === 0) {
			if (isExternal) {
				errors.endereco = ['Informe o endereço do participante'];
			}
		}
		if (size(trim(participante.regiao)) === 0) {
			errors.regiao = ['Informe a região'];
		}
		if (size(trim(participante.cpf)) === 0) {
			errors.cpf = ['Informe o CPF do participante'];
		} else if (!isCPF(participante.cpf)) {
			errors.cpf = ['CPF inválido'];
		} else {
			const response = await send({
				type: 'participante/checkCpf',
				payload: { cpf: participante.cpf, id: participante.id }
			});
			const { ok, nome } = response.data;
			if (ok === 77) {
				errors.cpf = [`CPF já usado para outro participante (${nome})`];
			}
		}
		if (isExternal) {
			if (size(trim(participante.comprovanteCpf)) === 0) {
				errors.comprovanteCpf = ['Comprovante de CPF deve ser fornecido'];
			}
			if (size(trim(participante.comprovanteResidencia)) === 0) {
				errors.comprovanteResidencia = ['Comprovante de residência deve ser fornecido'];
			}
			// if (id === 'new') {
			// 	if ((!calendario || isAfter('fimInscricoesChapasDelegados')) && !isDebug) {
			// 		errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
			// 	}
			// }
		}
		if (!participante.permissaoUsoDados) {
			errors.permissaoUsoDados = ['É necessário fazer esta declaração para se inscrever no processo'];
		}
		return errors;
	}, [participante]);

	const submeteForm = useCallback(async () => {
		const errors = await valida();
		let origem = 'edicao';
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			let participanteAux = cloneDeep(participante);

			DOCUMENTO_PROPS.filter(prop => participante[prop] && !participante[prop].docName).forEach(prop => {
				participanteAux[prop].docName = `${prop}-${participanteAux.id}`;
			});

			if (isExternal && !participanteAux.usuarioCriador) {
				participanteAux.usuarioCriador = userProfile.email;
			}

			if (id === 'new') {
				// apenas para transporte... não será gravado no banco
				origem = 'cadastro';
				participanteAux.enviarEmail = true;
			}
			// apenas para transporte
			participanteAux.origem = origem;

			dispatch(saveParticipante(participanteAux));
		}
	}, [dispatch, participante, userProfile, valida, id]);

	const cancelar = useCallback(() => {
		dispatch(limpaStore());
		history.go(-1);
	}, [dispatch]);

	const remover = useCallback(() => {
		let errors = {};
		if (calendario && !isAfter('fimInscricoesChapasDelegados')) {
			dispatch(removeParticipante(participante.id));
		} else {
			errors.prazo = ['Inscrições encerradas para chapas, delegados e entidades'];
			setErrors(errors);
		}
		setShowPopupRemove(false);
	}, [dispatch, participante, calendario, isAfter]);

	// eslint-disable-next-line
	const hidePopupMessage = useCallback(() => {
		if (showPopupMessage.type === 'success') {
			setShowPopupMessage(false);
			dispatch(limpaStore());
			if (id === 'new') {
				history.go(-1);
			} else {
				dispatch(setAba(ABA_PARTICIPANTES));
				if (window.location.pathname !== '/home') {
					history.push('/home');
				}
			}
		} else {
			setShowPopupMessage(false);
		}
	});

	const isForaPeriodoInscricao = useMemo(
		() =>
			!isDebug &&
			id !== 'new' &&
			(!calendario ||
				moment().isBefore(calendario.iniInscricoesChapasDelegadosMoment) ||
				isAfter('fimInscricoesChapasDelegados')),
		[calendario, id, isAfter]
	);

	const readOnly = useMemo(() => isCandidatoChapa || isCandidatoDelegado || (isExternal && isForaPeriodoInscricao), [
		isForaPeriodoInscricao,
		isCandidatoChapa,
		isCandidatoDelegado
	]);

	return loadingCalendario || loadingParticipante || loadingChapas || loadingDelegados ? (
		<Loader msg="Carregando participante..." />
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendário']} />
	) : errorParticipante ? (
		<ErrorMessages errorList={['Problemas ao carregar participante']} />
	) : errorRegiao ? (
		<ErrorMessages errorList={['Problemas ao carregar regiões']} />
	) : errorChapas ? (
		<ErrorMessages errorList={['Problemas ao verificar se é candidato à alguma chapa']} />
	) : errorDelegados ? (
		<ErrorMessages errorList={['Problemas ao verificar se é candidato a delegado']} />
	) : (
		<>
			{updating && <Loader msg="Salvando participante..." />}
			<div className="container">
				{((isExternal && !isAfter('fimInscricoesChapasDelegados')) || !isExternal) && (
					<Navigation onNavigate={onNavigateHandler} />
				)}
				<h1 className="titulo-pagina">{id === 'new' ? 'Novo' : readOnly ? 'Consulta de' : 'Edição de'} Participante</h1>
				{readOnly && (
					<ErrorMessages
						errorList={[
							{
								msg: ` ${id === 'new' && isExternal ? 'cadastro desabilitado' : 'edição desabilitada'}  ${
									isCandidatoChapa
										? 'pois o participante é candidao por alguma chapa'
										: isCandidatoDelegado
										? 'pois o participante é candidao a delegado'
										: isForaPeriodoInscricao
										? 'pois o período de inscrições está encerrado'
										: `pois este participante foi criado no portal externo por ${participante?.usuarioCriador}`
								}`,
								type: 'warning'
							}
						]}
					/>
				)}
				{participante && (
					<>
						<form autoComplete="off">
							{isExternal || readOnly ? (
								<>
									<fieldset>
										<legend>Dados Pessoais</legend>
										<div className="row">
											<div className="form-group col-lg-6">
												<label className="required">Nome</label>
												<input
													type="text"
													className={`form-control${errors.nome ? ' form-control-error' : ''}`}
													name="nome"
													value={participante.nome || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													disabled={readOnly}
												/>
												{errors.nome && <ErrorMessages errorList={errors.nome} />}
											</div>

											<div className="form-group col-lg-6">
												<label className={`${isExternal ? 'required' : ''}`}>E-mail</label>
												<input
													type="text"
													className={`form-control${errors.email ? ' form-control-error' : ''}`}
													name="email"
													value={participante.email || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													disabled={readOnly}
												/>
												{errors.email && <ErrorMessages errorList={errors.email} />}
											</div>
											<div className="form-group col-sm-6">
												<label>Telefone</label>
												<InputMask
													className={`form-control${errors.telefone ? ' form-control-error' : ''}`}
													name="telefone"
													value={participante.telefone || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													mask={
														size(participante.telefone) >= 6 && participante.telefone.split('')[5] === '9'
															? '(99) 99999-9999'
															: '(99) 9999-9999'
													}
													alwaysShowMask={false}
													disabled={readOnly}
												/>
												{errors.telefone && <ErrorMessages errorList={errors.telefone} />}
											</div>
											<div className="form-group col-sm-6">
												<label className="required">Data de nascimento</label>
												<InputMask
													className={`form-control${errors.dataNascimento ? ' form-control-error' : ''}`}
													name="dataNascimento"
													value={participante.dataNascimento || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													mask="99/99/9999"
													disabled={readOnly}
												/>
												{errors.dataNascimento && <ErrorMessages errorList={errors.dataNascimento} />}
											</div>
										</div>
									</fieldset>
									<fieldset>
										<legend>Localização</legend>
										<CdlField
											title="Endereço"
											label={['Logradouro', 'Número']}
											required={true}
											name="endereco"
											value={participante.endereco}
											errorList={errors.endereco}
											onChangeHandler={changeHandler(participante)}
											disabled={readOnly}
										/>
										<div className="row">
											<div className="form-group col-md-5 col-lg-4">
												<label>Complemento</label>
												<input
													type="text"
													className={`form-control${errors.complemento ? ' form-control-error' : ''}`}
													name="complemento"
													value={participante.complemento || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													disabled={readOnly}
												/>
												{errors.complemento && <ErrorMessages errorList={errors.complemento} />}
											</div>

											<div className="form-group col-md-7 col-lg-8">
												<label className="control-label required">Região</label>
												<Selecao
													className={`form-control${errors.regiao ? ' form-control-error' : ''}`}
													selected={participante.regiao}
													label={'Região'}
													detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
													detailCodigo={''}
													detailDescricao={'nome'}
													autoShowList={false}
													searchTerm={regiaoSearchTerm}
													searchList={regioesDisponiveis}
													searchTermMinLength={0}
													errorList={errors.regiao}
													onChangeSearchTerm={e => {
														setRegiaoSearchTerm(e.target.value);
													}}
													onBlurSearchTerm={() => false}
													onSelectItem={item => () =>
														changeHandler(participante)({ target: { name: 'regiao', value: item } })}
													onUnselect={() => () =>
														changeHandler(participante)({ target: { name: 'regiao', value: null } })}
													noResetList={true}
													loading={loadingRegiao}
													readOnly={readOnly || !participante.endereco}
													maxDescricaoLength={80}
												/>
												{!participante?.endereco && (
													<small>{'Não é possível alterar a região, pois o endereço não foi informado'}</small>
												)}
											</div>
										</div>
									</fieldset>
									<fieldset>
										<legend>Documentos</legend>

										<div className="row">
											<div className="form-group col-md-4">
												<label className="required">CPF</label>
												<InputMask
													className={`form-control${errors.cpf ? ' form-control-error' : ''}`}
													name="cpf"
													value={participante.cpf || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													mask="999.999.999-99"
													disabled={readOnly}
												/>
												{errors.cpf && <ErrorMessages errorList={errors.cpf} />}
											</div>

											<div className="form-group col-md-4">
												<label>Título de eleitor</label>
												<input
													type="text"
													className={`form-control${errors.tituloEleitor ? ' form-control-error' : ''}`}
													name="tituloEleitor"
													value={participante.tituloEleitor || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													disabled={readOnly}
												/>
												{errors.tituloEleitor && <ErrorMessages errorList={errors.tituloEleitor} />}
											</div>

											<div className="form-group col-md-4">
												<label>RG</label>
												<input
													type="text"
													className={`form-control${errors.rg ? ' form-control-error' : ''}`}
													name="rg"
													value={participante.rg || ''}
													onChange={changeHandler(participante)}
													onKeyDown={e => e.key === 'Enter' && submeteForm()}
													autoComplete="off"
													disabled={readOnly}
												/>
												{errors.rg && <ErrorMessages errorList={errors.rg} />}
											</div>
										</div>
										<fieldset>
											<legend>Comprovantes necessários</legend>
											<div className="row">
												<div className="form-group col-md-4">
													<label className="required">Comprovante de CPF</label>
													<FileUpload
														value={participante.comprovanteCpf ? [participante.comprovanteCpf] : null}
														updateFilesCb={files =>
															changeHandler(participante)({
																target: {
																	name: 'comprovanteCpf',
																	value: files[0] || null
																}
															})
														}
														disabled={isForaPeriodoInscricao}
													/>
													<small>RG com CPF, CPF, CNH, etc</small>
													{errors.comprovanteCpf && <ErrorMessages errorList={errors.comprovanteCpf} />}
												</div>
												<div className="form-group col-md-4">
													<label className="required">Comprovante de Residência</label>
													<FileUpload
														value={participante.comprovanteResidencia ? [participante.comprovanteResidencia] : null}
														updateFilesCb={files =>
															changeHandler(participante)({
																target: {
																	name: 'comprovanteResidencia',
																	value: files[0] || null
																}
															})
														}
														disabled={isForaPeriodoInscricao}
													/>
													<small>Contas de telefone, luz, condomínio, IPTU, etc</small>
													{errors.comprovanteResidencia && <ErrorMessages errorList={errors.comprovanteResidencia} />}
												</div>
												<div className="form-group col-md-4">
													<label className="required">Declaração do titular da conta</label>
													<FileUpload
														value={participante.declaracaoTitularConta ? [participante.declaracaoTitularConta] : null}
														updateFilesCb={files =>
															changeHandler(participante)({
																target: {
																	name: 'declaracaoTitularConta',
																	value: files[0] || null
																}
															})
														}
														disabled={isForaPeriodoInscricao}
													/>
													<small>
														Apenas se o comprovante de residência estiver no nome de outra pessoa, confirmando que a
														pessoa reside neste endereço
													</small>
													{errors.declaracaoTitularConta && <ErrorMessages errorList={errors.declaracaoTitularConta} />}
												</div>
											</div>
										</fieldset>
									</fieldset>
									<div>
										<div className="checkbox-input p-0 pb-4">
											<input
												type="checkbox"
												id="permissaoUsoDados"
												name="permissaoUsoDados"
												value={participante.permissaoUsoDados || false}
												checked={participante.permissaoUsoDados || false}
												onChange={() => null}
												onClick={() =>
													changeHandler(participante)({
														target: { name: 'permissaoUsoDados', value: !participante.permissaoUsoDados }
													})
												}
												disabled={id !== 'new' || readOnly}
											/>
											<label
												className="required"
												onClick={() =>
													id !== 'new' || readOnly
														? null
														: changeHandler(participante)({
																target: { name: 'permissaoUsoDados', value: !participante.permissaoUsoDados }
														  })
												}
											>
												Em observância à Lei nº. 13.709/18 – Lei Geral de Proteção de Dados Pessoais e demais normativas
												aplicáveis sobre proteção de Dados Pessoais, manifesto-me de forma informada, livre, expressa e
												consciente, no sentido de autorizar a PMPA a realizar o tratamento de meus Dados Pessoais para
												as finalidades e de acordo com as condições aqui estabelecidas.
											</label>
										</div>
										{errors.permissaoUsoDados && <ErrorMessages errorList={errors.permissaoUsoDados} />}
									</div>
								</>
							) : (
								<>
									{!participante.permissaoUsoDados && (
										<div>
											<div className="checkbox-input p-0 pb-4">
												<label className="required">
													Em observância à Lei nº. 13.709/18 – Lei Geral de Proteção de Dados Pessoais e demais
													normativas aplicáveis sobre proteção de Dados Pessoais, manifesto-me de forma informada,
													livre, expressa e consciente, no sentido de autorizar a PMPA a realizar o tratamento de meus
													Dados Pessoais para as finalidades e de acordo com as condições aqui estabelecidas.
												</label>
											</div>
											{errors.permissaoUsoDados && <ErrorMessages errorList={errors.permissaoUsoDados} />}
										</div>
									)}
									{participante.permissaoUsoDados && (
										<>
											<div className="row">
												<div className="form-group col-md-12">
													<label className="required">Nome</label>
													<input
														type="text"
														className={`form-control${errors.nome ? ' form-control-error' : ''}`}
														name="nome"
														value={participante.nome || ''}
														onChange={changeHandler(participante)}
														onKeyDown={e => e.key === 'Enter' && submeteForm()}
														autoComplete="off"
														disabled={readOnly}
													/>
													{errors.nome && <ErrorMessages errorList={errors.nome} />}
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label className="required">CPF</label>
													<InputMask
														className={`form-control${errors.cpf ? ' form-control-error' : ''}`}
														name="cpf"
														value={participante.cpf || ''}
														onChange={changeHandler(participante)}
														onKeyDown={e => e.key === 'Enter' && submeteForm()}
														autoComplete="off"
														mask="999.999.999-99"
														disabled={readOnly}
													/>
													{errors.cpf && <ErrorMessages errorList={errors.cpf} />}
												</div>
												<div className="form-group col-sm-6">
													<label className="required">Data de nascimento</label>
													<InputMask
														className={`form-control${errors.dataNascimento ? ' form-control-error' : ''}`}
														name="dataNascimento"
														value={participante.dataNascimento || ''}
														onChange={changeHandler(participante)}
														onKeyDown={e => e.key === 'Enter' && submeteForm()}
														autoComplete="off"
														mask="99/99/9999"
														disabled={readOnly}
													/>
													{errors.dataNascimento && <ErrorMessages errorList={errors.dataNascimento} />}
												</div>{' '}
											</div>
											<fieldset>
												<legend>Localização</legend>
												<CdlField
													title="Endereço"
													label={['Logradouro', 'Número']}
													required={false}
													name="endereco"
													value={participante.endereco}
													errorList={errors.endereco}
													onChangeHandler={changeHandler(participante)}
													disabled={readOnly}
												/>
												<div className="row">
													<div className="form-group col-md-5 col-lg-4">
														<label>Complemento</label>
														<input
															type="text"
															className={`form-control${errors.complemento ? ' form-control-error' : ''}`}
															name="complemento"
															value={participante.complemento || ''}
															onChange={changeHandler(participante)}
															onKeyDown={e => e.key === 'Enter' && submeteForm()}
															autoComplete="off"
															disabled={readOnly}
														/>
														{errors.complemento && <ErrorMessages errorList={errors.complemento} />}
													</div>

													<div className="form-group col-md-7 col-lg-8">
														<label className="control-label required">Região</label>
														<Selecao
															className={`form-control${errors.regiao ? ' form-control-error' : ''}`}
															selected={participante.regiao}
															label={'Região'}
															detailInnerClassName={'form-control inner-list-item inner-list-item-input'}
															detailCodigo={''}
															detailDescricao={'nome'}
															autoShowList={false}
															searchTerm={regiaoSearchTerm}
															searchList={regioesDisponiveis}
															searchTermMinLength={0}
															errorList={errors.regiao}
															onChangeSearchTerm={e => {
																setRegiaoSearchTerm(e.target.value);
															}}
															onBlurSearchTerm={() => false}
															onSelectItem={item => () =>
																changeHandler(participante)({ target: { name: 'regiao', value: item } })}
															onUnselect={() => () =>
																changeHandler(participante)({ target: { name: 'regiao', value: null } })}
															noResetList={true}
															loading={loadingRegiao}
															readOnly={readOnly}
															maxDescricaoLength={80}
														/>
													</div>
												</div>
											</fieldset>{' '}
										</>
									)}
								</>
							)}
						</form>
						{size(errors) > 0 && (
							<>
								<ErrorMessages
									errorList={['Há erro(s) no preenchimento do formulário, corrija-o(s) antes de salvar.']}
								/>
								{/* <ErrorMessages errorList={[Object.keys(errors).join(', ')]} /> */}
							</>
						)}
						<div className="buttons">
							<button type="button" className="btn btn-outline-danger" onClick={cancelar}>
								<i className="fa fa-times" aria-hidden="true"></i>Cancelar
							</button>
							{!readOnly && !isExternal && !participante.permissaoUsoDados && id === 'new' && (
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => changeHandler(participante)({ target: { name: 'permissaoUsoDados', value: true } })}
								>
									<i className="fa fa-check" aria-hidden="true"></i>Concordo com os termos e condições
								</button>
							)}
							{!readOnly && (isExternal || participante.permissaoUsoDados) && (
								<>
									{id !== 'new' && (
										<button type="button" className="btn btn-danger" onClick={() => setShowPopupRemove(true)}>
											<i className="fa fa-trash-o" aria-hidden="true"></i>Remover
										</button>
									)}
									<button type="button" className="btn btn-primary" onClick={submeteForm}>
										<i className="fa fa-save" aria-hidden="true"></i>Salvar
									</button>
								</>
							)}
						</div>
					</>
				)}
				{showPopupRemove && (
					<div
						className="modal"
						style={{
							backgroundColor: 'rgba(0,0,0,.5)',
							display: 'flex',
							flexFlow: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						role="dialog"
					>
						<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
							<div className="modal-content">
								<div className="modal-header">
									<h1 className="h3">Exclusão de Participante</h1>
								</div>
								<div className="modal-body">
									<span>Remover o participante?</span>
								</div>
								<div className="modal-footer">
									<div className="buttons">
										<button type="button" className="btn btn-outline-danger" onClick={() => setShowPopupRemove(false)}>
											Cancelar
										</button>
										<button type="button" className="btn btn-danger" onClick={remover}>
											Confirmar Remoção
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{size(showPopupMessage) > 0 && (
					<ModalMessage showPopupMessage={showPopupMessage} hidePopupMessage={hidePopupMessage} />
				)}
			</div>
		</>
	);
}
Participante.displayName = 'Participante';
Participante.propTypes = {};

export default Participante;

export const isAtLeast = (dateAsString, least) => {
	const now = moment();
	const date = moment(dateAsString, 'DD/MM/YYYY');
	const age = now.diff(date, 'years');
	return age >= least;
};
