import { size } from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';

import { send } from '../../utils/tools';
import { history } from '../store';
import {
	saveChapa,
	removeChapa,
	setMessage,
	setUpdating,
	limpaStore,
	setValidationMessages,
	setMessages,
	setSituacaoAnalise
} from './chapaSlice';

const getAction = (type, payload) => ({
	type,
	payload
});

function* saveChapaSaga(action) {
	yield put(setUpdating(true));
	try {
		const chapa = { ...action.payload };

		const origem = chapa.origem;
		delete chapa.origem;

		const isAnalise = chapa.analise;
		delete chapa.analise;

		const nomeRegiao = chapa.regiao.nome;

		// convert objects to id's to save as references
		chapa.regiao = chapa.regiao?.id;
		chapa.titular = chapa.titular?.id;
		chapa.suplente1 = chapa.suplente1?.id;
		chapa.suplente2 = chapa.suplente2?.id;
		chapa.representanteComissaoEleitoral = chapa.representanteComissaoEleitoral?.id;

		const response = yield call(send, getAction('chapa/salvar', { chapa }), '');
		const { ok, numeroNovaChapa, errors, cadastrados } = response.data;

		if (ok === 1) {
			let messages = [];
			if (numeroNovaChapa) {
				messages.push(`Chapa ${numeroNovaChapa} criada para região ${nomeRegiao}!`);
			} else {
				messages.push(
					`Chapa salva com sucesso! ${isAnalise && size(chapa.situacao) > 0 ? `A Chapa foi ${chapa.situacao}!` : ''}`
				);
			}
			messages = messages.concat(
				(cadastrados || []).map(key => `${action.payload[key]?.nome} cadastrado como delegado!`)
			);
			yield put(
				setMessages(
					{
						messages: messages,
						type: 'success',
						origin: origem
					},
					20
				)
			);
			if (isAnalise) {
				yield put(setSituacaoAnalise(chapa.situacao || 'removida'));
			}
		} else {
			if (isAnalise) {
				yield put(setSituacaoAnalise('erro'));
			}
			yield put(
				setMessages(
					{
						messages: [`Erro ao ${origem === 'edicao' ? 'editar' : 'inscrever'} a chapa!`],
						type: 'error',
						origin: origem
					},
					20
				)
			);
			yield put(setValidationMessages(errors));
		}
	} catch (err) {
		console.error('err: ', err);
		yield put(setSituacaoAnalise('erro'));
		yield put(setMessage({ message: 'Ocorreu um erro no saga ao salvar a chapa!', type: 'error' }, 20));
	}
	yield put(setUpdating(false));
}

function* removeChapaSaga(action) {
	yield put(setUpdating(true));
	try {
		const response = yield call(send, getAction('chapa/remover', { id: action.payload }), '');
		const { ok, output } = response.data;
		console.debug('output: ', output);
		if (ok === 1) {
			yield put(limpaStore());
			yield put(setMessage('Chapa removida com sucesso!'));
			history.go(-1);
		} else {
			yield put(setMessage('Erro ao remover chapa!'));
		}
	} catch (err) {
		yield put(setMessage('Ocorreu um erro no saga ao remover chapa!'));
	}
	yield put(setUpdating(false));
}

function* watcher() {
	yield takeLatest(saveChapa.type, saveChapaSaga);
	yield takeLatest(removeChapa.type, removeChapaSaga);
}

export default watcher;
