import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

// import { CSSTransition } from 'react-transition-group';

import useCalendario from '../customHooks/useCalendario';
import useUserProfile from '../customHooks/useUserProfile';
import { history } from '../Redux/store';
import { isProd } from '../utils/tools';
import ErrorMessages from './ErrorMessages';

function SideMenu() {
	/* CUSTOM HOOKS */
	const { authenticated } = useUserProfile();
	const { calendario, loading: loadingCalendario, error: errorCalendario } = useCalendario();

	/* ESTADOS */
	const [showMenu, setShowMenu] = useState(false);
	const wrapperRef = useRef();
	const btnRef = useRef();

	const handleClickOutside = useCallback(event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			if (btnRef.current) {
				if (!btnRef.current.contains(event.target)) {
					setShowMenu(old => !old);
				}
			} else {
				setShowMenu(old => !old);
			}
		}
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	const menuJSON = [
		{
			key: 0,
			label: 'Comunicação',
			list: isProd
				? [
						{ key: 0, label: 'E-mail pra comissão Eleitoral', route: '/email' },
						{ key: 1, label: 'Solicitação de Impgunação de Chapa', route: '/solicitacao-impugnacao-chapa' }
				  ]
				: [
						{ key: 0, label: 'E-mail pra comissão Eleitoral', route: '/email' },
						{ key: 1, label: 'Solicitação de Impgunação de Chapa', route: '/solicitacao-impugnacao-chapa' },
						{ key: 2, label: 'Solicitação de Impgunação de Entidade', route: '/solicitacao-impugnacao-entidade' }
				  ]
		}
	];

	return loadingCalendario ? (
		<span>Preparando Menu...</span>
	) : errorCalendario ? (
		<ErrorMessages errorList={['Problemas ao carregar calendário']} />
	) : authenticated ? (
		<>
			<button
				type="button"
				className="btn-link"
				onClick={() => setShowMenu(old => !old)}
				aria-label="configurações"
				ref={btnRef}
			>
				Opções <i className="fa fa-caret-down" aria-hidden="true" />
			</button>
			{/* <CSSTransition in={showMenu} timeout={0} classNames="fly-down" unmountOnExit> */}
			{showMenu && (
				<div className="side-menu-wrapper" ref={wrapperRef}>
					<div className="side-menu">
						{menuJSON &&
							calendario &&
							menuJSON.map(menu => (
								<ul className="menu-section" key={menu.key}>
									<li className="list-title">{menu.label}</li>
									{(menu.list || [])
										.sort((e1, e2) => e1.label.localeCompare(e2.label))
										.map(menuItem => (
											<Fragment key={`frag-${menuItem.key}`}>
												<li key={menuItem.key}>
													<button
														className="btn"
														onClick={() => {
															setShowMenu(false);
															history.push(menuItem.route);
														}}
														type="button"
													>
														<i className="fa fa-list" aria-hidden="true" />
														<span className="title">{menuItem.label}</span>
													</button>
												</li>
											</Fragment>
										))}
								</ul>
							))}
					</div>
				</div>
			)}
			{/* </CSSTransition> */}
		</>
	) : null;
}
SideMenu.displayName = 'Sidemenu';
export default SideMenu;
