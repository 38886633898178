import { createSlice } from '@reduxjs/toolkit';

export const votacaoSlice = createSlice({
	name: 'votacao',
	initialState: {
		chapas: {},
		delegados: {}
	},
	reducers: {
		guardaChapas: (state, action) => {
			const chapas = action.payload;
			state.chapas = {};
			chapas.forEach(chapa => {
				const regiao = chapa.regiao.nome.split(' - ')[0];
				if (!state.chapas[regiao]) {
					state.chapas[regiao] = [];
				}
				if (chapa.situacao === 'confirmada') {
					state.chapas[regiao].push(chapa);
				}
			});
		},
		guardaDelegados: (state, action) => {
			const delegados = action.payload;
			state.delegados = {};
			delegados.forEach(delegado => {
				const regiao = delegado.regiao.nome.split(' - ')[0];
				if (!state.delegados[regiao]) {
					state.delegados[regiao] = [];
				}
				state.delegados[regiao].push(delegado);
			});
		}
	}
});

const { actions, reducer: homeReducer } = votacaoSlice;

export const { guardaChapas, guardaDelegados } = actions;

export default homeReducer;
